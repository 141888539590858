import React, { useEffect, useState } from 'react';
import { styled, Box, Typography, Button, Card, CardContent, IconButton, Alert, Collapse } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Appbar, BackButton } from '../../components';
import { ArrowForwardIos as ForwardIcon, Close as CloseIcon } from '@mui/icons-material';
import { createTheme, ThemeProvider } from '@mui/material';
import { usePriceFormatter } from '../../hooks/usePriceFormatter';
import myApi, { FirebaseApiResponse } from '../../../backend/firebase/FirebaseApi';
import { base } from '../../../backend/utils/BaseUrl';
import { useSelector } from 'react-redux';
import { RootState } from '../../../backend/redux/store';
import { DateTime } from 'luxon';

const font = createTheme({
  typography: {
    fontFamily: 'Oswald, sans-serif',
  },
});

// for converting what clients have paid to what trainer will receive per session
function getTrainerResIncome(sessionCost: string): string {
  switch (sessionCost) {
    case '25':
      return '21';
    case '48':
      return '22';
    case '69':
      return '23';
    case '88':
      return '24';
    case '21':
      return '14.5';
    case '40':
      return '15.5';
    case '57':
      return '16.5';
    case '72':
      return '17.5';
    case '17':
      return '9';
    case '32':
      return '9.5';
    case '45':
      return '10.5';
    case '56':
      return '11';
    case '12':
      return '7.5';
    case '22':
      return '8';
    case '30':
      return '8.5';
    case '36':
      return '9';
    default:
      throw new Error('Invalid session cost provided.');
  }
}

export const TrainerReservationRequestPage: React.FC = () => {
  const navigate = useNavigate();
  const { formatPrice } = usePriceFormatter();
  const [requestData, setRequestData] = useState<
    {
      id: string;
      clientName: string;
      clientID: string;
      sessionLength: number;
      sessionType: string;
      acceptTimeLeft: string;
      income: string;
      profilePhoto: string;
      cost: string;
      requestedSessions: [];
    }[]
  >([]);
  const uid = useSelector((state: RootState) => state.user.uid);
  const [showAlert, setShowAlert] = useState<string | null>(null);
  const [error, setError] = useState('');

  const handleBackClick = () => {
    navigate(-1);
  };

  const calculateAcceptTimeLeft = (createdAt: string) => {
    if (!createdAt) return 'Unknown';

    const createdTime = DateTime.fromISO(createdAt, { zone: 'utc' });
    const now = DateTime.now();

    const minutesElapsed = now.diff(createdTime, 'minutes').minutes;
    const minutesLeft = Math.max(0, 48 * 60 - minutesElapsed);

    if (minutesLeft < 60) {
      return `${Math.floor(minutesLeft)} Minutes`;
    } else {
      const hoursLeft = Math.floor(minutesLeft / 60);
      const remainingMinutes = Math.floor(minutesLeft % 60);
      return `${hoursLeft} Hours ${remainingMinutes} Minutes`;
    }
  };

  useEffect(() => {
    const fetchReservationRequests = async () => {
      if (!uid) {
        console.warn('No user id found');
        setShowAlert('Trainer ID is missing');
        return;
      }
      try {
        const response = await myApi.getSessionRequestsByTrainerID(base, uid);
        console.log('Fetched Requests: ', response);

        if ((response as any).error === 'No pending session requests found for the given trainer ID') {
          setError('No Pending Request');
          return;
        }

        if (!Array.isArray(response)) {
          setShowAlert('Unexpected response format');
          return;
        }

        // fetch client info
        const requestsWithClientNames = await Promise.all(
          response.map(async (request: any) => {
            const clientResponse: any = await myApi.readSingleClient(base, request.clientID);
            const clientName = clientResponse?.firstName
              ? `${clientResponse.firstName} ${clientResponse.lastName?.charAt(0) || ''}.`
              : 'Unknown Client';
            const profilePhoto =
              clientResponse?.profilePhoto ||
              clientResponse.firstName.charAt(0).toUpperCase() + clientResponse.lastName.charAt(0).toUpperCase();

            const sessionArray = request.requestedSessions || request.sessionInfo || [];
            const sessionLength = sessionArray.length;

            return {
              id: request.id,
              clientID: request.clientID,
              clientName,
              profilePhoto,
              sessionLength: sessionLength,
              sessionType: request.sessionType || '',
              acceptTimeLeft: calculateAcceptTimeLeft(request.createdAt || ''),
              income: getTrainerResIncome(request.cost || 0) || 0,
              cost: request.cost,
              requestedSessions: sessionArray,
            };
          })
        );

        // sort by time left
        requestsWithClientNames.sort((a, b) => {
          const timeA = a.acceptTimeLeft.split(' ');
          const timeB = b.acceptTimeLeft.split(' ');

          const minutesA =
            timeA[1] === 'Hours' ? parseInt(timeA[0]) * 60 + (parseInt(timeA[2]) || 0) : parseInt(timeA[0]);
          const minutesB =
            timeB[1] === 'Hours' ? parseInt(timeB[0]) * 60 + (parseInt(timeB[2]) || 0) : parseInt(timeB[0]);
          return minutesA - minutesB;
        });

        setRequestData(
          requestsWithClientNames.map((request) => ({
            id: request.id.toString(),
            clientName: request.clientName,
            clientID: request.clientID,
            profilePhoto: request.profilePhoto.toString(),
            sessionLength: Number(request.sessionLength),
            sessionType: request.sessionType.toString(),
            acceptTimeLeft: request.acceptTimeLeft.toString(),
            income: request.income.toString(),
            cost: request.cost.toString(),
            requestedSessions: request.requestedSessions || [],
          }))
        );
        setError('');
      } catch (error) {
        console.error('Error fetching reservation requests: ', error);
        setShowAlert('Error fetching requests');
      }
    };
    fetchReservationRequests();
  }, [uid]);

  return (
    <>
      <Appbar showMenu={true} showCloseIcon={true} />
      <StyledBackButton onClick={handleBackClick} />
      <Box width='100%'>
        <ThemeProvider theme={font}>
          <Title>Review Reservation Requests</Title>
          <Description>
            Review reservation requests from clients and accept them within 48 hours. Make sure to check the total pay,
            date, and time of the requested session. Note that the total amount is before any potential tips from
            clients.
          </Description>
        </ThemeProvider>
        <Collapse in={!!showAlert}>
          <Alert
            severity={'error'}
            action={
              <IconButton aria-label='close' color='inherit' size='small' onClick={() => setShowAlert('')}>
                <CloseIcon fontSize='inherit' />
              </IconButton>
            }
            sx={{
              mb: 2,
              mx: 2,
              '@media (min-width:1024px)': {
                mx: '15%',
              },
            }}
          >
            {showAlert}
          </Alert>
        </Collapse>

        {error ? (
          <Box sx={{ textAlign: 'center', marginTop: '30px' }}>
            <Typography variant='h6' color='textSecondary'>
              {error}
            </Typography>
          </Box>
        ) : (
          requestData.map((request) => (
            <Card
              key={request.id}
              sx={{
                mb: 4,
                borderRadius: '15px',
                backgroundColor: '#DEDEDE',
                width: '95%',
                marginLeft: '2.5%',
              }}
            >
              <CardContent sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ width: '60%', borderLeft: 2, pl: 1 }}>
                  <Typography variant='body1' fontWeight='bold' mb={0.5}>
                    Sessions with {request.clientName}
                  </Typography>
                  <Typography variant='body2' mb={0.5} color={'#8B8787'}>
                    {request.sessionLength} {request.sessionLength == 1 ? 'Session' : 'Sessions'}, {request.sessionType}
                  </Typography>
                  <Typography variant='body2' mb={0.5} color={'#8B8787'}>
                    {request.acceptTimeLeft} Left to Accept
                  </Typography>
                </Box>
                <Box sx={{ width: '40%' }}>
                  <Typography variant='h4' fontWeight={'500'} textAlign={'right'} mr={1}>
                    {formatPrice(parseFloat(request.income) * request.sessionLength)}
                  </Typography>
                </Box>
              </CardContent>
              <Button
                variant='contained'
                endIcon={<ForwardIcon />}
                sx={{
                  width: '100%',
                  borderRadius: '15px',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  textTransform: 'none',
                  backgroundColor: '#F27C22',
                  '&:hover': {
                    backgroundColor: '#FF7000',
                  },
                  '& .MuiButton-endIcon': {
                    marginLeft: 'auto',
                  },
                }}
                onClick={() =>
                  navigate('/trainer-reservation-details', {
                    state: {
                      request: {
                        id: request.id,
                        clientName: request.clientName,
                        clientID: request.clientID,
                        profilePhoto: request.profilePhoto,
                        sessionLength: request.sessionLength,
                        sessionType: request.sessionType,
                        acceptTimeLeft: request.acceptTimeLeft,
                        income: request.income,
                        cost: request.cost,
                        sessions: request.requestedSessions,
                      },
                    },
                  })
                }
              >
                <span style={{ width: '100%', textAlign: 'center' }}>See Reservation Details</span>
              </Button>
            </Card>
          ))
        )}
      </Box>
    </>
  );
};

const StyledBackButton = styled(BackButton)(({ theme }) => ({
  marginTop: '2%',
  marginLeft: '2.5%',
  marginBottom: '0.5%',
  padding: '0',
  [theme.breakpoints.down('sm')]: {
    marginTop: '5%',
  },
  [theme.breakpoints.between('sm', 'md')]: {
    marginTop: '4%',
  },
  [theme.breakpoints.between('md', 'lg')]: {
    marginTop: '3%',
  },
}));

const Title = styled(Typography)(() => ({
  mt: 1,
  mb: 2,
  textAlign: 'center',
  fontWeight: '500',
  color: '#F27C22',
  font: 'oswald',
  fontSize: 30,
}));

const Description = styled(Typography)(({ theme }) => ({
  color: '#797A79',
  fontSize: 12,
  lineHeight: '20px',
  textAlign: 'center',
  maxWidth: '95%',
  marginLeft: '2.5%',
  marginBottom: '32px',
  [theme.breakpoints.up('sm')]: {
    fontSize: 14,
  },
  [theme.breakpoints.up('md')]: {
    fontSize: 16,
  },
}));
