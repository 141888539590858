import React, { useEffect, useState } from 'react';
import { Appbar, Customcard, GradientButton, Map } from '../../components';
import { Avatar, Box, Typography, Alert, Collapse, IconButton } from '@mui/material';
import { styled } from '@mui/system';
import { Star as StarIcon, Close as CloseIcon } from '@mui/icons-material';
import myApi, { FirebaseApiResponse } from '../../../backend/firebase/FirebaseApi';
import { base } from '../../../backend/utils/BaseUrl';
import { useNavigate } from 'react-router-dom';

export const ClientRatingPage: React.FC = () => {
  const [trainerInfo, setTrainerInfo] = useState({
    firstName: '',
    lastInitial: '',
    rating: 0,
    gender: '',
    age: 0,
    UserPhoto: '',
  });
  const [rating, setRating] = useState<number>(0);
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>('');
  const navigate = useNavigate();

  const handleStarClick = (index: number) => {
    setRating(index + 1);
  };

  // setting rating in database
  const handleSubmit = () => {
    if (rating) {
      setRating(rating);
      localStorage.setItem('rating', rating.toString());
      // let r:number = (rating + trainerInfo.rating) / 2;
      myApi
        .updateSingleTrainer(base, sessionStorage.getItem('trainerID') || '', {
          rating: rating,
        })
        .then((res: FirebaseApiResponse) => {
          if (typeof res === 'string' || (res as any).error) {
            console.error(res);
            setAlertMessage('An error occurred while updating trainer information. Please try again.');
            setAlertOpen(true);
          } else {
            console.log('Client information updated successfully:', res);
            setAlertMessage('Thanks for rating. Your rating has been submitted.');
            setAlertOpen(true);
            navigate('/client-reserve', {
              state: {
                trainerID: sessionStorage.getItem('trainerID') || '',
                trainerName: `${trainerInfo.firstName} ${trainerInfo.lastInitial}`,
                source: 'ratingPage',
              },
            });
            sessionStorage.clear();
          }
        });
    } else {
      setAlertMessage('Please nicely leave a rating for the trainer before submitting.');
      setAlertOpen(true);
    }
  };

  const calculateAge = (birthdate: string): number => {
    const birthDate = new Date(birthdate);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };

  const getLastInitial = (lastName: string): string => {
    return lastName.charAt(0).toUpperCase() + '.';
  };

  const fetchTrainerInfo = (trainerID: string) => {
    myApi.readSingleTrainer(base, trainerID.toString()).then((res: FirebaseApiResponse) => {
      if (typeof res === 'string' || (res as any).error) {
        console.error(res);
        setAlertMessage('An error occurred while fetching trainer information. Please try again.');
        setAlertOpen(true);
      } else {
        let data: any = res;
        setTrainerInfo({
          firstName: data.firstName,
          lastInitial: getLastInitial(data.lastName),
          rating: data.rating,
          gender: data.gender,
          age: calculateAge(data.dob),
          UserPhoto: data.UserPhoto || data.firstName.charAt(0).toUpperCase() + data.lastName.charAt(0).toUpperCase(),
        });
      }
    });
  };

  useEffect(() => {
    let object = sessionStorage.getItem('trainerID');
    if (object) {
      fetchTrainerInfo(object);
    } else {
      setAlertMessage('An error occurred while fetching session information. Please try again.');
      setAlertOpen(true);
    }
  }, []);

  return (
    <>
      <Appbar showMenu={false} userRole='client' />
      <ParentContainer>
        <Customcard>
          <Typography
            variant='h6'
            align='center'
            sx={{ fontFamily: 'Oswald, sans-serif', marginBottom: '9%', marginTop: '5%' }}
          >
            We Hope You Enjoyed Your Session!
          </Typography>
          <Box display='flex' justifyContent='center'>
            <Avatar
              src={trainerInfo.UserPhoto}
              alt={`${trainerInfo.firstName} ${trainerInfo.lastInitial}`}
              sx={{ width: 112, height: 100, marginRight: 2, marginBottom: '5%' }}
            />

            <Box sx={{ marginTop: 1.5 }}>
              <Typography sx={{ fontFamily: 'Oswald, sans-serif' }} variant='h6'>
                {trainerInfo.firstName} {trainerInfo.lastInitial}
              </Typography>
              <Typography sx={{ fontFamily: 'Oswald, sans-serif' }} variant='body1'>
                {trainerInfo.rating.toFixed(2)}{' '}
                <StarIcon style={{ color: 'white', verticalAlign: '-8%', fontSize: '15px' }} />
              </Typography>
              <Typography sx={{ fontFamily: 'Oswald, sans-serif' }} variant='body2'>
                {trainerInfo.gender}, {trainerInfo.age}
              </Typography>
            </Box>
          </Box>
          <Box display='flex' justifyContent='center' mt={'7%'}>
            {[...Array(5)].map((_, index) => (
              <Star key={index} selected={index < rating} onClick={() => handleStarClick(index)} />
            ))}
          </Box>
          <Box display='flex' justifyContent='center' mt={'10%'}>
            <GradientButton text='Submit Rating' handleClick={handleSubmit} showArrowIcon={true}></GradientButton>
          </Box>

          <Collapse in={alertOpen}>
            <Alert
              severity='error'
              action={
                <IconButton aria-label='close' color='inherit' size='small' onClick={() => setAlertOpen(false)}>
                  <CloseIcon fontSize='inherit' />
                </IconButton>
              }
              sx={{ mt: 1 }}
            >
              {alertMessage}
            </Alert>
          </Collapse>
        </Customcard>
        <Map />
      </ParentContainer>
    </>
  );
};

const ParentContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  backgroundColor: 'black',
}));

const Star = styled(StarIcon)(({ theme, selected }: { theme?: any; selected: boolean }) => ({
  fontSize: '2.5rem',
  cursor: 'pointer',
  color: selected ? '#F27C22' : 'white',
}));
