import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../../backend/redux/store';
import { checkSingleRole, UserRole, getUserHomeRoute, isPageAccessible } from '../../../backend/utils/checkUserRole';
import { Alert, Snackbar, CircularProgress, Box } from '@mui/material';

interface ProtectedRouteProps {
  children: React.ReactNode;
  requiredRole?: UserRole;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children, requiredRole }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const uid = useSelector((state: RootState) => state.user.uid);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const validateAccess = async () => {
      if (!uid) {
        const loginPath = location.pathname.includes('trainer') ? '/trainer-login' : '/client-login';
        navigate(loginPath);
        return;
      }

      try {
        // First check if the page is accessible
        const hasAccess = await isPageAccessible(uid, location.pathname);
        if (!hasAccess) {
          // If no access, check the other role to determine where to navigate
          const otherRole = location.pathname.includes('trainer') ? UserRole.CLIENT : UserRole.TRAINER;
          const hasOtherRole = await checkSingleRole(uid, otherRole);

          setError(`Access Denied. You don't have permission to view this page.`);

          if (hasOtherRole) {
            // Navigate to appropriate home page
            navigate(otherRole === UserRole.TRAINER ? '/trainer-home' : '/home-page');
          } else {
            // If no valid role found, navigate to login
            navigate(getUserHomeRoute(location.pathname));
          }
          return;
        }
      } catch (error) {
        console.error('Error validating access:', error);
        setError('Error validating access permissions');
      } finally {
        setIsLoading(false);
      }
    };

    validateAccess();
  }, [uid, navigate, location.pathname]);

  if (isLoading) {
    return (
      <Box display='flex' justifyContent='center' alignItems='center' minHeight='100vh'>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      {children}
      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={() => setError(null)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert severity='error' onClose={() => setError(null)}>
          {error}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ProtectedRoute;
