import React, { useEffect, useState } from 'react';
import { Appbar, Customcard, Map, GradientButton } from '../../components';
import { Avatar, Box, Typography, Alert, Collapse, IconButton, Button } from '@mui/material';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import { Star as StarIcon, Close as CloseIcon } from '@mui/icons-material';
import myApi, { FirebaseApiResponse } from '../../../backend/firebase/FirebaseApi';
import { base } from '../../../backend/utils/BaseUrl';

export const TrainerTipPayout: React.FC = () => {
  const [trainerInfo, setTrainerInfo] = useState({
    firstName: '',
    lastInitial: '',
    rating: 0,
    gender: '',
    age: 0,
    UserPhoto: 'https://example.com/path/to/profile/picture.jpg',
  });

  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>('');
  const navigate = useNavigate();
  const sessionID = sessionStorage.getItem('sessionID') || '';

  const calculateAge = (birthdate: string): number => {
    const birthDate = new Date(birthdate);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };

  const getLastInitial = (lastName: string): string => {
    return lastName.charAt(0).toUpperCase() + '.';
  };

  const fetchTrainerInfo = (trainerID: string) => {
    myApi.readSingleTrainer(base, trainerID.toString()).then((res: FirebaseApiResponse) => {
      if (typeof res === 'string' || (res as any).error) {
        console.error(res);
        setAlertMessage('An error occurred while fetching trainer information. Please try again.');
        setAlertOpen(true);
      } else {
        let data: any = res;
        setTrainerInfo({
          firstName: data.firstName,
          lastInitial: getLastInitial(data.lastName),
          rating: data.rating,
          gender: data.gender,
          age: calculateAge(data.dob),
          UserPhoto: data.UserPhoto,
        });
      }
    });
  };

  useEffect(() => {
    const trainerID = sessionStorage.getItem('trainerID');
    if (trainerID) {
      fetchTrainerInfo(trainerID);
    } else {
      setAlertMessage('Trainer information not found. Please try again later.');
      setAlertOpen(true);
    }
  }, []);

  const handleTip = () => {
    window.location.href = 'https://buy.stripe.com/3cs6osbBMcyF8Le8wx';
    // navigate('/client-rating');
  };

  const handleNoTip = () => {
    navigate('/client-rating');
  };

  return (
    <>
      <Appbar showMenu={true} />
      <ParentContainer>
        <Customcard>
          <Box display='flex' justifyContent='center'>
            <Typography
              variant='h5'
              align='center'
              sx={{ fontFamily: 'Oswald, sans-serif', marginBottom: '4%', marginTop: '2%', width: '100%' }}
            >
              Want to tip your trainer?
            </Typography>
          </Box>
          <Box display='flex' flexDirection='column' alignItems='center' mb={5}>
            <Box display='flex' alignItems='center'>
              <Avatar
                src={trainerInfo.UserPhoto}
                alt={`${trainerInfo.firstName} ${trainerInfo.lastInitial}`}
                sx={{ width: 112, height: 100, marginRight: 2, marginTop: 2 }}
              />
              <Box sx={{ marginLeft: 2 }}>
                <Typography sx={{ fontFamily: 'Oswald, sans-serif' }} variant='h6'>
                  {trainerInfo.firstName} {trainerInfo.lastInitial}
                </Typography>
                <Typography sx={{ fontFamily: 'Oswald, sans-serif' }} variant='body1'>
                  {trainerInfo.rating.toFixed(2)}{' '}
                  <StarIcon style={{ color: 'white', verticalAlign: '-8%', fontSize: '15px' }} />
                </Typography>
                <Typography sx={{ fontFamily: 'Oswald, sans-serif' }} variant='body2'>
                  {trainerInfo.gender}, {trainerInfo.age}
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box display='flex' justifyContent='center' alignItems='center' width='100%'>
            <Box alignItems='center' width='90%'>
              <GradientButton
                text='Yes, I want to tip my trainer!'
                handleClick={handleTip}
                showArrowIcon={true}
                styles={{
                  textTransform: 'none',
                  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                  fontSize: '1rem',
                  marginLeft: -0.5,
                  marginBottom: 3,
                }}
              />
              <Button
                onClick={handleNoTip}
                sx={{
                  backgroundColor: '#DEDEDE',
                  color: 'black',
                  width: '100%',
                  borderRadius: '10px',
                  textTransform: 'None',
                  fontSize: '1rem',
                  fontFamily: 'Oswald, sans-serif',
                  '&:hover': {
                    backgroundColor: '#c4c4c4',
                  },
                }}
              >
                No, I don’t want to tip.
              </Button>
            </Box>
          </Box>

          <Collapse in={alertOpen}>
            <Alert
              severity='error'
              action={
                <IconButton aria-label='close' color='inherit' size='small' onClick={() => setAlertOpen(false)}>
                  <CloseIcon fontSize='inherit' />
                </IconButton>
              }
              sx={{ mt: 2 }}
            >
              {alertMessage}
            </Alert>
          </Collapse>
        </Customcard>
        <Map />
      </ParentContainer>
    </>
  );
};

const ParentContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  backgroundColor: 'black',
}));
