// store.ts
import { configureStore, createSlice, PayloadAction } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import { persistReducer, persistStore } from 'redux-persist';
import currencyRecucer from './currencySlice';
import sessionRequestReducer from './sessionRequestSlice';
import { combineReducers } from 'redux';

interface UserState {
  uid: string | null;
  sessionID: string | null;
}

const initialState: UserState = {
  uid: null,
  sessionID: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUid(state, action: PayloadAction<string | null>) {
      state.uid = action.payload;
    },
    setSessionID(state, action: PayloadAction<string | null>) {
      state.sessionID = action.payload;
    },
  },
});

export const { setUid, setSessionID } = userSlice.actions;

const rootReducer = combineReducers({
  user: userSlice.reducer,
  currency: currencyRecucer,
  sessionRequest: sessionRequestReducer,
});

const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const persistor = persistStore(store);

export default store;
