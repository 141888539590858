import React from 'react';
import { Stepper, Step, StepLabel, StepIconProps } from '@mui/material';
import { fontFamily, styled } from '@mui/system';

const steps = ['Your Information', 'Identity Verification', 'Your Experience', 'Done!'];

interface CustomStepperProps {
  activeStep: number;
  style?: React.CSSProperties;
}

export const CustomStepper: React.FC<CustomStepperProps> = ({ activeStep, style }) => {
  return (
    <StepperWrapper>
      <CustomStepperContainer activeStep={activeStep} alternativeLabel style={style}>
        {steps.map((label) => (
          <Step key={label}>
            <CustomStepLabel StepIconComponent={CustomStepIcon}>{label}</CustomStepLabel>
          </Step>
        ))}
      </CustomStepperContainer>
    </StepperWrapper>
  );
};

const StepperWrapper = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  '&::after': {
    content: '""',
    position: 'absolute',
    top: '23%',
    left: 0,
    right: 0,
    height: '65%',
    backgroundColor: '#F5F5F5',
    zIndex: -1,
  },
  [theme.breakpoints.between('xs', 'md')]: {
    '&::after': {
      top: '19%',
    },
  },
}));

const CustomStepperContainer = styled(Stepper)(({ theme }) => ({
  width: '100%',
  backgroundColor: 'transparent',
  marginBottom: '1rem',
  padding: '0.5rem 0',
  '& .MuiStepConnector-line': {
    borderColor: '#F27C22',
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const CustomStepIcon: React.FC<StepIconProps> = (props) => {
  const { active, completed, className } = props;

  return (
    <CustomStepIconRoot ownerState={{ completed, active }} className={className}>
      <div className='QontoStepIcon-circle' />
    </CustomStepIconRoot>
  );
};

const CustomStepIconRoot = styled('div')<{ ownerState: { active?: boolean; completed?: boolean } }>(
  ({ ownerState }) => ({
    color: '#eaeaf0',
    display: 'flex',
    height: 26,
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'center',
    ...(ownerState.active && {
      color: '#000000',
    }),
    ...(ownerState.completed && {
      color: '#F27C22',
    }),
    '& .QontoStepIcon-circle': {
      width: 16,
      height: 16,
      borderRadius: '50%',
      backgroundColor: 'currentColor',
    },
  })
);

const CustomStepLabel = styled(StepLabel)(({ theme }) => ({
  '& .MuiStepLabel-label': {
    color: '#000',
    textAlign: 'center',
    fontFamily: 'Oswald, sans-serif',
    '&.Mui-active': {
      color: '#000',
    },
    '&.Mui-completed': {
      color: '#000',
    },
  },
}));
