import {
  Button,
  Box,
  Link,
  TextField,
  Typography,
  IconButton,
  InputLabel,
  FormControl,
  OutlinedInput,
  InputAdornment,
  ThemeProvider,
  Alert,
  Collapse,
} from '@mui/material';
import { Visibility, VisibilityOff, Google as GoogleIcon, Close as CloseIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { MouseEvent, useState, useEffect } from 'react';
import { firebaseAuth, googleProvider } from '../../../backend/firebase';
import { signInWithEmailAndPassword, signInWithPopup, signOut } from 'firebase/auth';
import { Appbar, BlackButton, GradientButton } from '../../components';
import { CustomCardContainer, CustomContainer, theme } from './styles';
import { useDispatch } from 'react-redux';
import { setUid } from '../../../backend/redux/store';
import { findTrainer } from '../../../backend/utils/HandleValidate';
import { useLocation } from 'react-router-dom';

export const TrainerSignInPage = () => {
  // state variables
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<boolean>(false);
  const [passwordError, setPasswordError] = useState<boolean>(false);
  const [generalError, setGeneralError] = useState<string>('');
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [loginMessage, setLoginMessage] = useState<string>('');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  // Password textfield methods
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  // Sign In Functions
  const handleSignIn = async () => {
    // reset error states
    setEmailError(false);
    setPasswordError(false);
    setGeneralError('');
    setShowAlert(false);

    // field checks
    if (email === '') {
      setEmailError(true);
    }
    if (password === '') {
      setPasswordError(true);
    }

    if (email !== '' && password !== '') {
      try {
        const userCredential = await signInWithEmailAndPassword(firebaseAuth, email, password);
        console.log(userCredential.user?.uid);
        dispatch(setUid(userCredential.user?.uid));

        // Check if user is validated or not
        // if not validated navigate to the validation questions else the actual app
        findTrainer(userCredential.user?.uid, navigate);

        // Optionally close the window if sign-in is part of a popup
        if (window.opener) {
          window.close();
        }
      } catch (error: any) {
        // Handle specific error codes
        if (
          error.code === 'auth/user-not-found' ||
          error.code === 'auth/wrong-password' ||
          error.code === 'auth/invalid-email'
        ) {
          setEmailError(true);
          setPasswordError(true);
          setGeneralError('Invalid email or password');
        } else {
          setGeneralError('An unexpected error occurred. Please try again later.');
          console.log(error);
        }
        setShowAlert(true);
      }
    } else {
      setGeneralError('Please fill in all fields to continue.');
      setShowAlert(true);
    }
  };

  const handleSignInGoogle = async () => {
    try {
      const userCredential = await signInWithPopup(firebaseAuth, googleProvider);
      console.log(userCredential.user?.uid);

      dispatch(setUid(userCredential.user?.uid));

      // Check if user is validated or not
      // if not validated navigate to the validation questions else the actual app

      findTrainer(userCredential.user?.uid, navigate);

      // Optionally close the window if sign-in is part of a popup
      if (window.opener) {
        window.close();
      }
    } catch (error) {
      setGeneralError('An unexpected error occurred. Please try again later.');
      console.log(error);
    }
  };

  useEffect(() => {
    if (location.state?.errorMessage) {
      setGeneralError(location.state.errorMessage);
      setShowAlert(true);
      // Clear the error message from location state
      window.history.replaceState({}, document.title);
    }
  }, [location.state]);

  return (
    <ThemeProvider theme={theme}>
      <div>
        <Appbar showMenu={true} />
        <CustomContainer maxWidth='lg'>
          <CustomCardContainer>
            <Typography
              variant='h6'
              component='div'
              sx={{ marginTop: 5, marginBottom: 4, color: '#F27C22', fontWeight: 'bold' }}
            >
              Welcome Back, Trainer!
            </Typography>
            {/* Email Input */}
            <TextField
              required
              error={emailError}
              id='outlined-basic'
              label='Email'
              variant='outlined'
              fullWidth
              sx={{
                marginBottom: 2,
                '& .MuiOutlinedInput-root': {
                  borderRadius: '15px',
                  height: '55px',
                  '& .MuiOutlinedInput-notchedOutline': { border: '3px solid #000' },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': { border: '3px solid #F27C22' },
                },
              }}
              InputLabelProps={{ style: { color: emailError ? theme.palette.error.main : '#000' } }}
              onChange={(e) => setEmail(e.target.value)}
            />
            {/* Password input */}
            <Box sx={{ width: '100%', mb: 2 }}>
              <FormControl required sx={{ width: '100%' }} error={passwordError} variant='outlined'>
                <InputLabel htmlFor='outlined-adornment-password' sx={{ color: '#000' }}>
                  Password
                </InputLabel>
                <OutlinedInput
                  sx={{
                    borderRadius: '15px',
                    height: '55px',
                    '& .MuiOutlinedInput-notchedOutline': { border: '3px solid #000' },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': { border: '3px solid #F27C22' },
                  }}
                  id='outlined-adornment-password'
                  type={showPassword ? 'text' : 'password'}
                  onChange={(e) => setPassword(e.target.value)}
                  endAdornment={
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge='end'
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label='Password'
                />
              </FormControl>
              {/* Forgot Password Link */}
              <Box sx={{ mt: 1, ml: 1, textAlign: 'left' }}>
                <Link
                  component='button'
                  variant='body2'
                  onClick={() => navigate('/forget-password')}
                  sx={{ fontFamily: 'Oswald, sans-serif', textDecoration: 'none', color: '#BDBDBD' }}
                >
                  I forgot my password
                </Link>
              </Box>
            </Box>

            {/* Error alert */}
            <Collapse in={showAlert}>
              <Alert
                severity='error'
                action={
                  <IconButton aria-label='close' color='inherit' size='small' onClick={() => setShowAlert(false)}>
                    <CloseIcon fontSize='inherit' />
                  </IconButton>
                }
                sx={{ marginBottom: 2 }}
              >
                {generalError}
              </Alert>
            </Collapse>

            {/* Sign in button */}
            <GradientButton showArrowIcon text='Sign In' handleClick={handleSignIn} />
            {/* Divider with "or" */}
            <div style={{ display: 'flex', alignItems: 'center', width: '100%', marginTop: 25, marginBottom: 25 }}>
              <hr style={{ flexGrow: 1, borderTop: '1px solid black', margin: '0 10px' }} />
              <Typography sx={{ padding: '0 10px', color: 'black' }}>or</Typography>
              <hr style={{ flexGrow: 1, borderTop: '1px solid black', margin: '0 10px' }} />
            </div>
            <Button
              variant='outlined'
              onClick={handleSignInGoogle}
              startIcon={<GoogleIcon htmlColor='#F27C22' />}
              sx={{
                marginTop: 2,
                backgroundColor: '#DEDEDE',
                borderColor: '#DEDEDE',
                color: 'black',
                width: '100%',
                height: '45px',
                borderRadius: '15px',
                textTransform: 'none',
                fontSize: '17px',
              }}
            >
              Continue With Google
            </Button>
            {/* Sign up button */}
            <BlackButton
              showArrowIcon
              text='I need to create an account'
              handleClick={() => navigate('/trainer-signup', { replace: true })}
            ></BlackButton>
          </CustomCardContainer>
        </CustomContainer>
      </div>
    </ThemeProvider>
  );
};
