import React from 'react';
import { Card, CardContent, Typography, Box, SvgIcon } from '@mui/material';
import { styled } from '@mui/system';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
interface OnlineSessionCardProps {
  title: string;
  estimatedWaitTime: string;
  price: string;
  onClick: () => void;
}

const CustomCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(0),
  background: 'white',
  cursor: 'pointer',
  width: '100%',
  borderRadius: '15px',
  boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
}));

const TimeBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1.25),
  fontWeight: 'bold',
}));

export const SessionCard = ({ title, estimatedWaitTime, price, onClick }: OnlineSessionCardProps) => {
  return (
    <CustomCard onClick={onClick} sx={{ mb: -1 }}>
      <CardContent sx={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%', p: 0 }}>
        <Typography
          variant='h6'
          component='div'
          sx={{ mt: 1, fontWeight: '500', fontFamily: 'Oswald, sans-serif', fontSize: 24, ml: 2 }}
        >
          {/* {' '} */}
          {title}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            height: '2%',
            ml: 1,
          }}
        >
          <TimeBox>
            <SvgIcon component={AccessTimeFilledIcon} sx={{ fontSize: 35, mt: 1, ml: 1 }}>
              {/* Filled black clock icon found in MUI */}
              <circle cx='12' cy='12' r='10' fill='black' />
              <path fill='black' d='M12 6v6l4.5 2.7-1.1 1.8L10 13V6z' />
            </SvgIcon>
            <Typography
              variant='body1'
              component='span'
              sx={{ color: 'black', fontWeight: '390', fontSize: 16, fontFamily: 'Oswald, sans-serif', mt: 1 }}
            >
              {' '}
              Estimated Wait Time: {estimatedWaitTime}
            </Typography>
          </TimeBox>
          <Typography
            variant='h5'
            component='span'
            sx={{ fontWeight: '500', fontFamily: 'Oswald, sans-serif', fontSize: 30, mr: 3, mt: -2 }}
          >
            {' '}
            {price}
          </Typography>
        </Box>
      </CardContent>
    </CustomCard>
  );
};
