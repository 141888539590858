import React, { useEffect, useState } from 'react';
import './globalStyle.css';
import { Routes, Route, BrowserRouter as Router } from 'react-router-dom';
import { UserRole } from './backend/utils/checkUserRole';
import {
  NoPage,
  ClientSignInPage,
  ClientSignUpPage,
  ClientValidationPage,
  TrainerSignInPage,
  TrainerSignUpPage,
  TrainerInfoPage,
  TrainerIdentityPage,
  HomePage,
  HomePageSession,
  TrainerExperiencePage,
  TrainerDonePage,
  TrainerHomePage,
  TrainerAcceptPage,
  DrawbarOptions,
  ProfilePage,
  ProfilePageSupport,
  VideoPage,
  VideoPageWrapper,
  FindTrainer,
  TrainerRatingPage,
  TrainerProfilePage,
  ClientProfileSessions,
  TrainerProfileSessions,
  ProfilePageInfo,
  TrainerProfilePageInfo,
  ClientRatingPage,
  TrainerProfilePayout,
  TrainerTipPayout,
  TrainerProfilePageSupport,
  ForgetPasswordPage,
  ClientPrebookedSessionsPage,
  ChooseTimingPage,
  ReserveChoicePage,
  ReserveInfoPage,
  TrainerReservationRequestPage,
  TrainerUpcomingSessionsPage,
  TrainerReservationDetails,
  ReviewTimingPage,
  TrainerConfirmationPage,
  ClientConfirmationPage,
  TrainerSetAvailabilityPage,
  SearchTrainerPage,
} from './frontend';
import { Backdrop, Box, Modal, Typography } from '@mui/material';
import { styled } from '@mui/system';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import store, { persistor } from './backend/redux/store';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import { ClientWaitPage } from './frontend/pages/client-wait';
import { TrainerWaitPage } from './frontend/pages/trainer-wait';
import ProtectedRoute from './frontend/components/protected-route/ProtectedRoute';

function App() {
  const [isLandscape, setIsLandscape] = useState(false);
  const [isLaptopDevice, setIsLaptopDevice] = useState(false);

  const RedirectToExternal: React.FC = () => {
    useEffect(() => {
      window.location.replace('https://gyfrapp.com');
    }, []);
    return null;
  };

  const isLaptop = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    const isMobile = /iphone|ipad|ipod|android|blackberry|mini|windows\sce|palm/i.test(userAgent);
    return !isMobile && window.innerWidth >= 1024;
  };

  useEffect(() => {
    const handleOrientationChange = () => {
      setIsLandscape(window.innerWidth > window.innerHeight);
    };

    const handleResize = () => {
      setIsLaptopDevice(isLaptop());
      handleOrientationChange();
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Routes>
            {/* Public Routes */}
            <Route path='/' element={<RedirectToExternal />} />
            <Route path='/drawbar' element={<DrawbarOptions />} />
            <Route path='/video-session' element={<VideoPage />} />
            <Route path='/video-session/:sessionID' element={<VideoPageWrapper />} />
            <Route path='/forget-password' element={<ForgetPasswordPage />} />
            <Route path='/client-login' element={<ClientSignInPage />} />
            <Route path='/client-signup' element={<ClientSignUpPage />} />
            <Route path='/client-validation' element={<ClientValidationPage />} />
            <Route path='/trainer-login' element={<TrainerSignInPage />} />
            <Route path='/trainer-signup' element={<TrainerSignUpPage />} />
            <Route path='/trainer-information' element={<TrainerInfoPage />} />
            <Route path='/trainer-identity' element={<TrainerIdentityPage />} />
            <Route path='/trainer-experience' element={<TrainerExperiencePage />} />
            <Route path='/trainer-done' element={<TrainerDonePage />} />

            {/* Client Protected Routes */}
            <Route
              path='/home-page'
              element={
                <ProtectedRoute requiredRole={UserRole.CLIENT}>
                  <HomePage />
                </ProtectedRoute>
              }
            />
            <Route
              path='/home-session'
              element={
                <ProtectedRoute requiredRole={UserRole.CLIENT}>
                  <HomePageSession />
                </ProtectedRoute>
              }
            />
            <Route
              path='/client-profile'
              element={
                <ProtectedRoute requiredRole={UserRole.CLIENT}>
                  <ProfilePage />
                </ProtectedRoute>
              }
            />
            <Route
              path='/profile-support'
              element={
                <ProtectedRoute requiredRole={UserRole.CLIENT}>
                  <ProfilePageSupport />
                </ProtectedRoute>
              }
            />
            <Route
              path='/find-trainer'
              element={
                <ProtectedRoute requiredRole={UserRole.CLIENT}>
                  <FindTrainer />
                </ProtectedRoute>
              }
            />
            <Route
              path='/client-sessions'
              element={
                <ProtectedRoute requiredRole={UserRole.CLIENT}>
                  <ClientProfileSessions />
                </ProtectedRoute>
              }
            />
            <Route
              path='/client-booked'
              element={
                <ProtectedRoute requiredRole={UserRole.CLIENT}>
                  <ClientPrebookedSessionsPage />
                </ProtectedRoute>
              }
            />
            <Route
              path='/client-info'
              element={
                <ProtectedRoute requiredRole={UserRole.CLIENT}>
                  <ProfilePageInfo />
                </ProtectedRoute>
              }
            />
            <Route
              path='/client-wait'
              element={
                <ProtectedRoute requiredRole={UserRole.CLIENT}>
                  <ClientWaitPage />
                </ProtectedRoute>
              }
            />
            <Route
              path='/client-rating'
              element={
                <ProtectedRoute requiredRole={UserRole.CLIENT}>
                  <ClientRatingPage />
                </ProtectedRoute>
              }
            />
            <Route
              path='/trainer-tip'
              element={
                <ProtectedRoute requiredRole={UserRole.CLIENT}>
                  <TrainerTipPayout />
                </ProtectedRoute>
              }
            />
            <Route
              path='/client-reserve'
              element={
                <ProtectedRoute requiredRole={UserRole.CLIENT}>
                  <ReserveInfoPage />
                </ProtectedRoute>
              }
            />
            <Route
              path='/reserve-choice'
              element={
                <ProtectedRoute requiredRole={UserRole.CLIENT}>
                  <ReserveChoicePage />
                </ProtectedRoute>
              }
            />
            <Route
              path='/review-timing'
              element={
                <ProtectedRoute requiredRole={UserRole.CLIENT}>
                  <ReviewTimingPage />
                </ProtectedRoute>
              }
            />
            <Route
              path='/client-confirmation'
              element={
                <ProtectedRoute requiredRole={UserRole.CLIENT}>
                  <ClientConfirmationPage />
                </ProtectedRoute>
              }
            />
            <Route
              path='/search-trainer'
              element={
                <ProtectedRoute requiredRole={UserRole.CLIENT}>
                  <SearchTrainerPage />
                </ProtectedRoute>
              }
            />

            {/* Trainer Protected Routes */}
            <Route
              path='/trainer-accept'
              element={
                <ProtectedRoute requiredRole={UserRole.TRAINER}>
                  <TrainerAcceptPage />
                </ProtectedRoute>
              }
            />
            <Route
              path='/trainer-home'
              element={
                <ProtectedRoute requiredRole={UserRole.TRAINER}>
                  <TrainerHomePage />
                </ProtectedRoute>
              }
            />
            <Route
              path='/trainer-rating'
              element={
                <ProtectedRoute requiredRole={UserRole.TRAINER}>
                  <TrainerRatingPage />
                </ProtectedRoute>
              }
            />
            <Route
              path='/trainer-profile'
              element={
                <ProtectedRoute requiredRole={UserRole.TRAINER}>
                  <TrainerProfilePage />
                </ProtectedRoute>
              }
            />
            <Route
              path='/trainer-wait'
              element={
                <ProtectedRoute requiredRole={UserRole.TRAINER}>
                  <TrainerWaitPage />
                </ProtectedRoute>
              }
            />
            <Route
              path='/trainer-sessions'
              element={
                <ProtectedRoute requiredRole={UserRole.TRAINER}>
                  <TrainerProfileSessions />
                </ProtectedRoute>
              }
            />
            <Route
              path='/trainer-payout'
              element={
                <ProtectedRoute requiredRole={UserRole.TRAINER}>
                  <TrainerProfilePayout />
                </ProtectedRoute>
              }
            />
            <Route
              path='/trainer-support'
              element={
                <ProtectedRoute requiredRole={UserRole.TRAINER}>
                  <TrainerProfilePageSupport />
                </ProtectedRoute>
              }
            />
            <Route
              path='/trainer-info'
              element={
                <ProtectedRoute requiredRole={UserRole.TRAINER}>
                  <TrainerProfilePageInfo />
                </ProtectedRoute>
              }
            />
            <Route
              path='/trainer-upcoming-sessions'
              element={
                <ProtectedRoute requiredRole={UserRole.TRAINER}>
                  <TrainerUpcomingSessionsPage />
                </ProtectedRoute>
              }
            />
            <Route
              path='/trainer-reservation'
              element={
                <ProtectedRoute requiredRole={UserRole.TRAINER}>
                  <TrainerReservationRequestPage />
                </ProtectedRoute>
              }
            />
            <Route
              path='/trainer-reservation-details'
              element={
                <ProtectedRoute requiredRole={UserRole.TRAINER}>
                  <TrainerReservationDetails />
                </ProtectedRoute>
              }
            />
            <Route
              path='/trainer-confirmation'
              element={
                <ProtectedRoute requiredRole={UserRole.TRAINER}>
                  <TrainerConfirmationPage />
                </ProtectedRoute>
              }
            />
            <Route
              path='/trainer-availability'
              element={
                <ProtectedRoute requiredRole={UserRole.TRAINER}>
                  <TrainerSetAvailabilityPage />
                </ProtectedRoute>
              }
            />
            <Route
              path='/choose-timing'
              element={
                <ProtectedRoute requiredRole={UserRole.TRAINER}>
                  <ChooseTimingPage />
                </ProtectedRoute>
              }
            />

            {/* Error Routes */}
            <Route path='/no-page' element={<NoPage />} />
            <Route path='*' element={<NoPage />} />
          </Routes>
        </PersistGate>
      </Provider>
    </>
  );
}

export default App;
