import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface SessionInfo {
  date: string;
  timeRange: {
    startTime: string;
    endTime: string;
  };
}

interface SessionRequestState {
  trainerID: string | null;
  trainerName: string | null;
  sessions: SessionInfo[];
  cost: number | null;
}

const initialState: SessionRequestState = {
  trainerID: null,
  trainerName: null,
  sessions: [],
  cost: null,
};

const sessionRequestSlice = createSlice({
  name: 'sessionRequest',
  initialState,
  reducers: {
    setTrainerID(state, action: PayloadAction<string | null>) {
      state.trainerID = action.payload;
    },
    setTrainerName(state, action: PayloadAction<string | null>) {
      state.trainerName = action.payload;
    },
    setSessions(state, action: PayloadAction<SessionInfo[]>) {
      state.sessions = action.payload;
    },
    setCost(state, action: PayloadAction<number | null>) {
      state.cost = action.payload;
    },
    clearSessionRequest(state) {
      state.trainerID = null;
      state.trainerName = null;
      state.sessions = [];
      state.cost = null;
    },
  },
});

export const { setTrainerID, setTrainerName, setSessions, setCost, clearSessionRequest } = sessionRequestSlice.actions;

export default sessionRequestSlice.reducer;
