import React, { CSSProperties, useEffect, useState } from 'react';
import { Typography, Box, Alert, Collapse, IconButton } from '@mui/material';
import { Appbar, Customcard as BaseCustomCard, Map } from '../../components';
import { HourglassEmptyOutlined as HourglassEmptyOutlinedIcon, Close as CloseIcon } from '@mui/icons-material';
import { styled } from '@mui/system';
import myApi, { FirebaseApiResponse } from '../../../backend/firebase/FirebaseApi';
import { base } from '../../../backend/utils/BaseUrl';
import { OnboardingSessionInfo } from '../../../backend/models/Data';
import { useNavigate } from 'react-router-dom';
import { app } from '../../../backend/firebase/FirebaseConfig';
import { getFirestore, doc, onSnapshot } from 'firebase/firestore';
const textStyle: CSSProperties = {
  color: 'white',
  fontFamily: 'Arial, sans-serif',
  textAlign: 'center',
};

const disclaimerTextStyle: CSSProperties = {
  color: 'red',
  fontFamily: 'Arial, sans-serif',
  textAlign: 'center',
  marginTop: '16px',
};

const iconStyle: CSSProperties = {
  color: 'white',
  marginRight: '8px',
};

const CustomCard = styled(BaseCustomCard)(({ theme }) => ({
  position: 'relative',
  backgroundColor: 'black',
  color: 'white',
  [theme.breakpoints.down('sm')]: {
    maxHeight: '80vh',
    marginBottom: theme.spacing(2),
  },
  [theme.breakpoints.up('md')]: {
    marginBottom: theme.spacing(2),
    maxHeight: '100vh',
    maxWidth: '600px',
    alignSelf: 'center',
  },
}));

export const FindTrainer: React.FC = () => {
  const navigate = useNavigate();
  const sessionID = sessionStorage.getItem('sessionID');
  const firestore = getFirestore(app);
  const [showAlert, setShowAlert] = useState<string>('');

  useEffect(() => {
    const clientSessionRef = doc(firestore, 'sessions', sessionStorage.getItem('sessionID')!);

    const unsubscribe = onSnapshot(clientSessionRef, (doc: any) => {
      const sessionData = doc.data();
      if (sessionData?.status === 'matched') {
        // Redirect to accept/decline page
        myApi.readSingleSession(base, sessionStorage.getItem('sessionID')!).then((res: FirebaseApiResponse) => {
          if (typeof res === 'string' || (res as any).error) {
            console.error(res);
            setShowAlert('An error occurred while fetching your session data. Please try again.');
          } else {
            let data: any = res;
            sessionStorage.setItem('trainerID', data.trainerID);

            myApi.getVideoData(base, sessionStorage.getItem('sessionID')!).then((res: any) => {
              if (typeof res === 'string' || (res as any).error) {
                console.error(res);
              } else {
                const { clientToken, trainerToken, callingID, clientUserId, trainerUserId, time } = res;
                // sessionStorage.getItem('userId')
                sessionStorage.setItem('userId', clientUserId);
                sessionStorage.setItem('callId', callingID);
                sessionStorage.setItem('token', clientToken);
                sessionStorage.setItem('time', time);
              }
            });
            navigate('/client-wait');
          }
        });
      }
    });

    return () => unsubscribe();
  }, [sessionID]);

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (sessionID) {
        updateStatus(sessionID, 'closed');
        event.preventDefault();
        event.returnValue = 'error';
        sessionStorage.clear();
        navigate('/home-page');
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    if (sessionID) {
      myApi.readSingleSession(base, sessionID).then((res: FirebaseApiResponse) => {
        if (typeof res === 'string' || (res as any).error) {
          console.error('Error:', res);
          setShowAlert('An error occurred while fetching your session data. Please try again.');
        } else {
          let data: any = res;
          if (data.status === 'pending_payment') {
            updateStatus(sessionID, 'waiting');
          } else if (data.status === 'waiting') {
            setShowAlert(
              'Please wait until you are matched with a trainer. If you leave before your session begins, you may not be reimbursed.'
            );
          } else if (data.status === 'closed') {
            setShowAlert('Session closed. Please choose another session.');
          } else {
            setShowAlert('An unexpected error occurred.');
          }
        }
      });
    } else {
      setShowAlert('You have not chosen your session or paid for a session.');
      navigate('/home-page');
    }

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [navigate, sessionID]);

  const updateStatus = (id: string, status: string) => {
    const data: OnboardingSessionInfo = { status };

    myApi.updateStatus(base, id, data).then((res: FirebaseApiResponse) => {
      if (typeof res === 'string' || (res as any).error) {
        console.error('Error:', res);
        setShowAlert('An error occurred while updating your session status. Please try again.');
      } else {
        console.log('Status updated successfully:', res);
      }
    });
  };

  return (
    <>
      <Appbar showMenu={false} userRole='client' />
      <Box display='flex' alignItems='center' flexDirection='column' width='100%'>
        <CustomCard>
          <Typography variant='h6' style={textStyle} sx={{ mt: 1, fontFamily: 'Oswald, sans-serif' }} gutterBottom>
            Finding your trainer...
          </Typography>
          <Box display='flex' alignItems='center' justifyContent='center' mb={2}>
            <HourglassEmptyOutlinedIcon style={iconStyle} />
            <Typography variant='body2' style={textStyle} sx={{ fontFamily: 'Oswald, sans-serif' }}>
              Give us a few seconds to find you a perfect trainer!
            </Typography>
          </Box>
          <Typography variant='body2' style={disclaimerTextStyle}>
            If you refresh or close this tab, your session may end. If that happens, please contact Gyfr support
            immediately.
          </Typography>

          <Collapse in={!!showAlert}>
            <Alert
              severity='error'
              action={
                <IconButton color='inherit' size='small' onClick={() => setShowAlert('')}>
                  <CloseIcon fontSize='inherit' />
                </IconButton>
              }
              sx={{ mt: 2 }}
            >
              {showAlert}
            </Alert>
          </Collapse>
        </CustomCard>
        <Map />
      </Box>
    </>
  );
};
