import React, { useEffect, useState } from 'react';
import {
  styled,
  Box,
  Grid,
  Typography,
  Button,
  Avatar,
  Select,
  MenuItem,
  useMediaQuery,
  SelectChangeEvent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Alert,
  Collapse,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Appbar, BackButton, BlackButton } from '../../components';
import {
  ArrowBack as ArrowBackIcon,
  Visibility as VisibilityIcon,
  ControlPoint as AddIcon,
  Close as CloseIcon,
} from '@mui/icons-material';
import firebaseApi from '../../../backend/firebase/FirebaseApi';
import { useSelector } from 'react-redux';
import { RootState } from '../../../backend/redux/store';
import { base } from '../../../backend/utils/BaseUrl';
import { OnboardingTrainerInfo, WeeklyAvailability, TimeRange } from '../../../backend';
import { firebaseAuth } from '../../../backend/firebase';
import moment from 'moment-timezone';
import myApi from '../../../backend/firebase/FirebaseApi';

type TimeSlot = `${string}-${string}`;

interface DayAvailability {
  isAvailable: boolean;
  times: TimeSlot[];
}

const weekDays: string[] = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
const weekDaysFull = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

// const timezones = moment.tz.names();

const generateTimeSlots = (): string[] => {
  const slots: string[] = [];
  for (let i = 0; i < 24; i++) {
    for (let j = 0; j < 4; j++) {
      const hour = i % 12 || 12;
      const minute = (j * 15).toString().padStart(2, '0');
      const ampm = i < 12 ? 'AM' : 'PM';
      slots.push(`${hour}:${minute} ${ampm}`);
    }
  }
  return slots;
};

const timeToMinutes = (timeStr: string) => {
  const [time, period] = timeStr.toLowerCase().split(' ');
  let [hours, minutes] = time.split(':').map(Number);

  // Changed to lowercase, now handles 12's correctly & pm correctly
  if (period === 'pm' && hours !== 12) {
    hours += 12;
  } else if (period === 'am' && hours === 12) {
    hours = 0;
  }

  return hours * 60 + minutes;
};

// Convert minutes to 12-hour time format
const minutesToTime = (minutes: number) => {
  let hours = Math.floor(minutes / 60);
  const mins = minutes % 60;
  let period = 'AM';

  if (hours >= 12) {
    period = 'PM';
    if (hours > 12) hours -= 12;
  } else if (hours === 0) {
    hours = 12;
  }

  return `${hours}:${mins.toString().padStart(2, '0')} ${period}`;
};

// Parse time range string into start and end minutes
const parseTimeRange = (rangeStr: string) => {
  console.log('new time parsing: ', rangeStr);
  const [start, end] = rangeStr.split('-');
  console.log('start: ', start);
  console.log('end: ', end);
  return {
    start: timeToMinutes(start),
    end: timeToMinutes(end),
  };
};

// Format minutes into time range string
const formatTimeRange = (startMinutes: number, endMinutes: number) => {
  return `${minutesToTime(startMinutes)}-${minutesToTime(endMinutes)}`;
};

const convertDBTimeToUITime = (timeRanges: TimeRange[] = []): TimeSlot[] => {
  return timeRanges.map((range) => {
    // Ensure both start and end times are properly formatted
    const startMoment = moment(range.startTime, ['hh:mm A']);
    const endMoment = moment(range.endTime, ['hh:mm A']);

    if (!startMoment.isValid() || !endMoment.isValid()) {
      console.warn('Invalid time format:', range);
    }

    // Format to match exact format from generateTimeSlots
    const startTime = startMoment.format('h:mm A');
    const endTime = endMoment.format('h:mm A');

    return `${startTime}-${endTime}` as TimeSlot;
  });
};

const convertAvailabilityToState = (weeklyAvailability: WeeklyAvailability = {}) => {
  return weekDaysFull.map((day) => {
    const dayKey = day.toLowerCase() as keyof WeeklyAvailability;
    const dayAvailability = weeklyAvailability[dayKey];

    // If there are time ranges for this day, mark as available and convert times
    if (dayAvailability && dayAvailability.length > 0) {
      const convertedTimes = convertDBTimeToUITime(dayAvailability);

      return {
        isAvailable: true,
        times: convertedTimes,
      };
    }

    // Otherwise return unavailable state
    return {
      isAvailable: false,
      times: [],
    };
  });
};

export const TrainerSetAvailabilityPage: React.FC = () => {
  const navigate = useNavigate();
  const timeSlots: string[] = generateTimeSlots();
  const [nextResetDate, setNextResetDate] = useState<string>('');
  const [trainerInfo, setTrainerInfo] = useState<OnboardingTrainerInfo | null>(null);
  const [loading, setLoading] = useState(true);
  const [showAlert, setShowAlert] = useState<string>('');
  const [error, setError] = useState<string | null>(null);
  const uid = useSelector((state: RootState) => state.user.uid);

  console.log('Your auth UID:', uid);
  const [currentUserTimezone, setCurrentUserTimezone] = useState<string>('');
  const [previewOpen, setPreviewOpen] = useState(false);
  const [availability, setAvailability] = useState<DayAvailability[]>(
    weekDays.map(() => ({ isAvailable: false, times: [] }))
  );

  const isLandscapeLaptop = useMediaQuery('(min-width:1024px) and (orientation: landscape)');

  const [selectedTimezone, setSelectedTimezone] = useState<string>('');
  useEffect(() => {
    const fetchTimezone = async (userUid: string) => {
      try {
        const data: any = await firebaseApi.readSingleTrainer(base, userUid);
        const timezoneFromDB = data.timezone || 'UTC';

        setCurrentUserTimezone(timezoneFromDB);
        setSelectedTimezone(timezoneFromDB);
      } catch (error) {
        console.error('Error fetching trainer timezone:', error);
        setShowAlert('Error fetching trainer timezone');
      }
    };

    const unsubscribe = firebaseAuth.onAuthStateChanged((user) => {
      if (user) {
        fetchTimezone(user.uid);
      } else {
        console.error('User not authenticated');
        setShowAlert('User not authenticated');
      }
    });

    return () => unsubscribe();
  }, []);

  const handleTimezoneChange = (event: SelectChangeEvent<unknown>) => {
    const newTimezone = event.target.value as string;
    setSelectedTimezone(newTimezone);
  };

  const handleBackClick = () => {
    navigate('/trainer-profile');
  };

  const handleToggleAvailability = (index: number): void => {
    setAvailability((prev) =>
      prev.map((day, i) =>
        i === index
          ? { ...day, isAvailable: !day.isAvailable, times: day.isAvailable ? [] : ['10:00 AM-5:00 PM'] }
          : day
      )
    );
  };

  const handleAddTime = (dayIndex: number): void => {
    setAvailability((prev) =>
      prev.map((day, i) => (i === dayIndex ? { ...day, times: [...day.times, '10:00 AM-5:00 PM'] } : day))
    );
  };

  const handleRemoveTime = (dayIndex: number, timeIndex: number): void => {
    setAvailability((prev) =>
      prev.map((day, i) =>
        i === dayIndex
          ? { ...day, times: day.times.filter((_, index) => index !== timeIndex), isAvailable: day.times.length !== 1 }
          : day
      )
    );
  };

  // Validate a single time range
  const validateTimeRange = (rangeStr: string) => {
    const [startTime, endTime] = rangeStr.split('-');

    // Parse times using moment to properly handle AM/PM
    const startMoment = moment(startTime, 'h:mm A');
    const endMoment = moment(endTime, 'h:mm A');

    // Compare the actual moments instead of raw minutes
    if (!startMoment.isBefore(endMoment)) {
      setShowAlert('End time must be after start time');
      return false;
    }
    return true;
  };

  // Merge overlapping ranges
  const mergeOverlappingRanges = (ranges: TimeSlot[]) => {
    if (ranges.length <= 1) return ranges;

    console.log('Merging ranges:', ranges);
    // Convert ranges to minutes and sort
    const convertedRanges = ranges.map(parseTimeRange).sort((a, b) => a.start - b.start);

    const merged = [convertedRanges[0]];
    console.log('Initial merged:', merged);

    for (let i = 1; i < convertedRanges.length; i++) {
      console.log('Current range:', convertedRanges[i]);
      const current = convertedRanges[i];
      const previous = merged[merged.length - 1];

      if (current.start <= previous.end) {
        console.log('Ranges overlap:', previous, current);
        // Ranges overlap - merge them
        previous.end = Math.max(previous.end, current.end);
      } else {
        // No overlap - add new range
        merged.push(current);
      }
    }
    console.log('Final merged:', merged);
    // Convert back to time range strings
    return merged.map((range) => formatTimeRange(range.start, range.end));
  };

  const handleTimeChange = (dayIndex: number, timeIndex: number, field: 0 | 1, value: string): void => {
    const [start, end] = availability[dayIndex].times[timeIndex].split('-');
    const validation = field === 0 ? validateTimeRange(`${value}-${end}`) : validateTimeRange(`${start}-${value}`);
    // Validate the new time range
    if (!validation) {
      return;
    }
    setAvailability((prev) =>
      prev.map((day, i) =>
        i === dayIndex
          ? {
              ...day,
              times: day.times.map((time, tIndex) =>
                tIndex === timeIndex
                  ? (time
                      .split('-')
                      .map((t, f) => (f === field ? value : t))
                      .join('-') as TimeSlot)
                  : time
              ),
            }
          : day
      )
    );
  };

  const calculateResetDate = (lastUpdated: string) => {
    // Convert lastUpdated string to Date object
    const lastUpdatedDate = new Date(lastUpdated);

    // Add 30 days
    const resetDate = new Date(lastUpdatedDate);
    resetDate.setDate(resetDate.getDate() + 30);

    // Round to midnight (00:00:00) of that day
    resetDate.setHours(0, 0, 0, 0);

    // Format date
    return resetDate.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  };

  useEffect(() => {
    const fetchTrainerInfo = async () => {
      if (!uid) {
        console.warn('No UID available');
        setError('User not found');
        setLoading(false);
        return;
      }

      try {
        const response = await myApi.readSingleTrainer(base, uid);
        console.log('Fetched trainer data:', response);

        if (typeof response === 'string' || (response as any).error) {
          throw new Error(typeof response === 'string' ? response : (response as any).error);
        }

        const trainerData = response as OnboardingTrainerInfo;
        setTrainerInfo(trainerData);

        // Set availability state from trainer data
        if (trainerData.availability) {
          const availabilityState = convertAvailabilityToState(trainerData.availability);
          setAvailability(availabilityState);
        }

        if (trainerData.lastUpdated) {
          const nextReset = calculateResetDate(trainerData.lastUpdated);
          setNextResetDate(nextReset);
        }
      } catch (err) {
        console.error('Error in fetchTrainerInfo:', err);
        setShowAlert('Failed to fetch trainer info');
      } finally {
        setLoading(false);
      }
    };

    fetchTrainerInfo();
  }, [uid]);

  const handleSubmit = async () => {
    const userUid = firebaseAuth.currentUser?.uid;
    if (userUid) {
      try {
        const weeklyAvailability: WeeklyAvailability = weekDaysFull.reduce((acc, day, index) => {
          const dayAvailability = availability[index];
          const dayKey = day.toLowerCase() as keyof WeeklyAvailability;

          if (dayAvailability.isAvailable) {
            acc[dayKey] = mergeOverlappingRanges(dayAvailability.times).map((timeSlot) => {
              const [start, end] = timeSlot.split('-');
              return {
                startTime: start,
                endTime: end,
              };
            });
          }

          return acc;
        }, {} as WeeklyAvailability);

        console.log('Converting availability to WeeklyAvailability format:', weeklyAvailability);

        const updates: Partial<OnboardingTrainerInfo> = {
          availability: weeklyAvailability,
          lastUpdated: new Date().toISOString(),
        };

        console.log('Sending updates to Firebase:', updates);
        await firebaseApi.updateSingleTrainer(base, userUid, updates);
        console.log('Successfully saved availability');

        // modify to update more fields
        await firebaseApi.updateSingleTrainer(base, userUid, { timezone: selectedTimezone });
        setCurrentUserTimezone(selectedTimezone);
        console.log('Timezone updated successfully');

        // recalculate reset date
        const nextReset = calculateResetDate(new Date().toISOString());
        setNextResetDate(nextReset);
        console.log('Calculated reset date:', nextReset);
      } catch (error) {
        setShowAlert('Error submit availability');
        console.error('Error submit availability:', error);
      }
    }
  };

  const DaySchedule = (Day: { day: string; dayIndex: number; timeSlots: string[] }) => {
    return (
      <Box key={Day.dayIndex} sx={{ display: 'flex', mb: 2 }}>
        <Avatar
          sx={{
            fontFamily: 'Oswald, sans-serif',
            bgcolor: '#F27C22',
            width: 30,
            height: 30,
            mt: 1,
          }}
        >
          {Day.day}
        </Avatar>
        <Box sx={{ ml: 2, flexGrow: 1 }}>
          {availability[Day.dayIndex].isAvailable ? (
            availability[Day.dayIndex].times.map((time, timeIndex) => (
              <Box key={timeIndex} sx={{ display: 'flex', my: 1 }}>
                <CustomScrollableSelect
                  value={time.split('-')[0]}
                  onChange={(e: SelectChangeEvent<string>) =>
                    handleTimeChange(Day.dayIndex, timeIndex, 0, e.target.value)
                  }
                  options={timeSlots}
                />
                <Typography sx={{ mr: 1 }}>-</Typography>
                <CustomScrollableSelect
                  value={time.split('-')[1]}
                  onChange={(e: SelectChangeEvent<string>) =>
                    handleTimeChange(Day.dayIndex, timeIndex, 1, e.target.value)
                  }
                  options={timeSlots}
                />
                <IconButton onClick={() => handleRemoveTime(Day.dayIndex, timeIndex)} size='small'>
                  <CloseIcon fontSize='small' />
                </IconButton>
              </Box>
            ))
          ) : (
            <Typography mt={1.5}>Unavailable</Typography>
          )}
        </Box>
        <Box>
          <IconButton
            onClick={() =>
              availability[Day.dayIndex].isAvailable
                ? handleAddTime(Day.dayIndex)
                : handleToggleAvailability(Day.dayIndex)
            }
            size='small'
            sx={{ alignItems: 'start', mt: 1 }}
          >
            <AddIcon fontSize='small' />
          </IconButton>
        </Box>
      </Box>
    );
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <>
      <Appbar showMenu={true} showCloseIcon={true} />
      <StyledBackButton onClick={handleBackClick} />
      <Grid
        container
        sx={{
          flexGrow: 1,
          overflow: 'hidden',
          '@media (min-width:1024px) and (orientation: landscape)': { marginTop: '-5%' },
        }}
      >
        <TitleWrapper item xs={12} md={isLandscapeLaptop ? 6 : 12}>
          <Title>Choose Your Availability</Title>
          <Description>
            {`Your schedule will automatically reset on ${nextResetDate}. Please make sure to update your availability around that date to avoid any interruptions.`}
            <br />
            {'Please select your availability in Eastern Standard Time (EST).'}
          </Description>
          {/* <TimezoneDropdown>
            <CustomSelect
              value={selectedTimezone}
              onChange={handleTimezoneChange}
              variant='outlined'
              size='small'
              MenuProps={MenuProps}
            >
              {timezones.map((timezone: string) => (
                <MenuItem key={timezone} value={timezone}>
                  {timezone}
                </MenuItem>
              ))}
            </CustomSelect>
          </TimezoneDropdown> */}
        </TitleWrapper>
        <ContentWrapper item xs={12} md={isLandscapeLaptop ? 6 : 12}>
          <Typography fontSize={25} fontFamily={'Oswald, sans-serif'} marginInline={'4%'}>
            Select Hours
          </Typography>
          <HoursWrapper>
            <Collapse in={!!showAlert}>
              <Alert
                severity='error'
                sx={{ mb: 2, borderRadius: '15px' }}
                action={
                  <IconButton
                    aria-label='close'
                    color='inherit'
                    size='small'
                    onClick={() => {
                      setShowAlert('');
                    }}
                  >
                    <CloseIcon fontSize='inherit' />
                  </IconButton>
                }
              >
                {showAlert}
              </Alert>
            </Collapse>
            {weekDays.map((day, index) => (
              <DaySchedule key={index} day={day} dayIndex={index} timeSlots={timeSlots} />
            ))}
          </HoursWrapper>
          <ButtonsWrapper>
            <PreviewButton startIcon={<VisibilityIcon />} onClick={() => setPreviewOpen(true)}>
              Preview
            </PreviewButton>
            <BlackButton text='Save Schedule' handleClick={handleSubmit}></BlackButton>
          </ButtonsWrapper>
        </ContentWrapper>
        <Dialog open={previewOpen} onClose={() => setPreviewOpen(false)} maxWidth={'md'} scroll='paper'>
          <DialogTitle>Weekly Availability Preview</DialogTitle>
          <DialogContent>
            <Box>
              {availability.map((day, index) => (
                <div key={index}>
                  {day.isAvailable && (
                    <div>
                      <DialogContentText fontWeight={'bold'} mt={1}>
                        {weekDaysFull[index]}
                      </DialogContentText>
                      {mergeOverlappingRanges(day.times).map((range, rangeIndex) => (
                        <DialogContentText key={rangeIndex}>{range}</DialogContentText>
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setPreviewOpen(false)}>Close</Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </>
  );
};

const StyledBackButton = styled(BackButton)(({ theme }) => ({
  marginTop: '2%',
  marginLeft: '2.5%',
  marginBottom: '0.5%',
  padding: '0',
  justifyContent: 'left',
  [theme.breakpoints.down('sm')]: {
    marginTop: '5%',
  },
  [theme.breakpoints.between('sm', 'md')]: {
    marginTop: '4%',
  },
  [theme.breakpoints.between('md', 'lg')]: {
    marginTop: '3%',
  },
  '@media (min-width:1024px) and (orientation: landscape)': {
    margin: '40px',
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  mt: 1,
  mb: 2,
  textAlign: 'center',
  fontWeight: '500',
  color: '#F27C22',
  font: 'oswald',
  fontSize: 30,
  fontFamily: 'Oswald, sans-serif',
  '@media (min-width:1024px) and (orientation: landscape)': {
    fontSize: 50,
  },
}));

const Description = styled(Typography)(({ theme }) => ({
  color: '#797A79',
  fontSize: 12,
  lineHeight: '20px',
  textAlign: 'center',
  marginInline: '4%',
  marginBottom: '15px',
  fontFamily: 'Oswald, sans-serif',
  whiteSpace: 'pre-line',
  '@media (min-width:1024px) and (orientation: landscape)': {
    marginBottom: '2%',
  },
}));

const TimezoneDropdown = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  marginBottom: '2%',
  '@media (min-width:1024px) and (orientation: landscape)': {
    marginBottom: '10%',
    textAlign: 'center',
  },
}));

const CustomSelect = styled(Select)(({ theme }) => ({
  width: '100%',
  maxWidth: 300,
  borderRadius: 15,
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: '#F27C22',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#F27C22',
  },
}));

const MenuProps = {
  PaperProps: {
    sx: {
      maxHeight: 200,
      overflowY: 'auto',
      borderRadius: 2,
    },
  },
  anchorOrigin: {
    vertical: 'bottom' as 'bottom',
    horizontal: 'left' as 'left',
  },
  transformOrigin: {
    vertical: 'top' as 'top',
    horizontal: 'left' as 'left',
  },
};

const PreviewButton = styled(Button)(({ theme }) => ({
  variants: 'text',
  width: '100%',
  alignItems: 'center',
  textTransform: 'none',
  color: 'black',
  fontSize: 12,
  fontWeight: 'lighter',
  '& .MuiButton-startIcon': {
    marginRight: '4px',
  },
  marginBottom: '15px',
}));

interface CustomScrollableSelectProps {
  value: string;
  onChange: (event: SelectChangeEvent<string>) => void;
  options: string[];
}

const CustomScrollableSelect: React.FC<CustomScrollableSelectProps> = ({ value, onChange, options }) => {
  return (
    <Select
      value={value}
      onChange={onChange}
      size='small'
      variant='standard'
      sx={{ mr: 1, width: 100, fontSize: 15 }}
      MenuProps={{
        PaperProps: {
          style: {
            maxHeight: 120, // Match the maxHeight set in StyledSelect
            backgroundColor: '#DEDEDE',
          },
        },
      }}
    >
      {options.map((option) => (
        <MenuItem key={option} value={option} sx={{ fontSize: 15, minHeight: 25, paddingLeft: 1, paddingY: 0 }}>
          {option}
        </MenuItem>
      ))}
    </Select>
  );
};

const TitleWrapper = styled(Grid)(({ theme }) => ({
  '@media (min-width:1024px) and (orientation: landscape)': {
    alignContent: 'center',
  },
}));

const HoursWrapper = styled(Box)(({ theme }) => ({
  '@media (min-width:1024px) and (orientation: landscape)': {
    height: '60vh',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '6px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#888',
      borderRadius: '3px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#f1f1f1',
    },
  },
  margin: '4%',
  paddingLeft: '3%',
}));

const ContentWrapper = styled(Grid)(({ theme }) => ({
  '@media (min-width:1024px) and (orientation: landscape)': {
    padding: '2% 5%',
  },
  display: 'grid',
  alignContent: 'start',
}));

const ButtonsWrapper = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    marginTop: '25%',
    maxWidth: '90%',
    marginLeft: '4%',
  },
  [theme.breakpoints.between('sm', 'md')]: {
    marginTop: '4%',
    maxWidth: '95%',
    marginLeft: '2.1%',
  },
  [theme.breakpoints.up('lg')]: {
    maxWidth: '95%',
    marginLeft: '4%',
  },
}));
