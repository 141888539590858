import React, { useState, useEffect } from 'react';
import { Typography, Box, Button, Alert, Collapse, IconButton } from '@mui/material';
import { styled } from '@mui/system';
import {
  ArrowBack as ArrowBackIcon,
  ArrowForwardIos as ArrowForwardIosIcon,
  Close as CloseIcon,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { Appbar, BackButton, BlackButton } from '../../components';
import myApi, { FirebaseApiResponse } from '../../../backend/firebase/FirebaseApi';
import { base } from '../../../backend/utils/BaseUrl';
import { useSelector } from 'react-redux';
import { RootState } from '../../../backend/redux/store';
import { usePriceFormatter } from '../../hooks/usePriceFormatter';

export const TrainerProfilePayout = () => {
  const [currentBalance, setCurrentBalance] = useState<number>(0);
  const [showConfirm, setShowConfirm] = useState(false);
  const navigate = useNavigate();
  const uid = useSelector((state: RootState) => state.user.uid);
  const [accountLink, setAccountLink] = useState<string>('');
  const [error, setError] = useState<string | null>(null);
  const [open, setOpen] = useState<boolean>(false);

  const { formatPrice } = usePriceFormatter();

  const handleBackClick = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (uid) {
      myApi.readSingleTrainer(base, uid).then((res: any) => {
        if (typeof res === 'string' || (res as any).error) {
          console.error(res);
          setError('An error occurred while fetching your earnings.');
          setOpen(true);
        } else {
          setCurrentBalance(res.earnings);
          console.log(res);
          setAccountLink(res.accountLink);
        }
      });
    } else {
      setError('An error occurred while fetching your earnings.');
      setOpen(true);
    }
  }, [uid]);

  const ifNewLink = () => {
    myApi.payoutNewLink(base, uid!).then((res: any) => {
      if (typeof res === 'string' || (res as any).error) {
        setError('An error occurred: ' + res.error);
        setOpen(true);
      } else {
        window.location.href = res.accountLink;
      }
    });
  };

  const clickSubmit = () => {
    if (!accountLink) {
      setError('Please add a payout method to request a payout.');
      setOpen(true);
      return;
    }
    if (currentBalance < 30) {
      setError(`Payout amount must be greater than ${formatPrice(30)}.`);
      setOpen(true);
    } else {
      myApi
        .payoutSingleTrainer(base, uid!)
        .then((res: FirebaseApiResponse) => {
          if (typeof res === 'string' || (res as any).error) {
            console.error(res);
            setError('An error occurred during the payout process.');
            setOpen(true);
          } else {
            setCurrentBalance(0);
            setShowConfirm(false);
          }
        })
        .catch((error) => {
          console.error(error);
          setError('An unexpected error occurred.');
          setOpen(true);
        });
    }
  };

  return (
    <>
      <Appbar showMenu={true} showCloseIcon={true} />
      {!showConfirm ? (
        <>
          <PayoutContainer>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                marginTop: '1rem',
              }}
            >
              <StyledBackButton onClick={handleBackClick} />
              <Box sx={{ flex: 1, textAlign: 'center' }}>
                <CurrentBalance sx={{ marginTop: '0' }}>Current Balance</CurrentBalance>
              </Box>
              <Box sx={{ width: '1.5rem' }}></Box>
            </Box>
            <BalanceAmount>{formatPrice(currentBalance)}</BalanceAmount>
          </PayoutContainer>
          <Divider />
          <PayoutContainer>
            <PayoutButton onClick={() => setShowConfirm(true)}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                <Box sx={{ textAlign: 'left' }}>
                  <Typography variant='body1' sx={{ fontWeight: 'bold', fontSize: '1rem' }}>
                    Receive my Payout!
                  </Typography>

                  <Typography variant='body2' sx={{ fontSize: '0.8rem', textAlign: 'left', paddingRight: '0.5rem' }}>
                    Your earnings must be above {formatPrice(30)} to request a payout.
                  </Typography>
                </Box>
                <img
                  src='/moneybag_black.png'
                  alt='Payout Icon'
                  style={{ cursor: 'pointer', width: '50px', height: '50px', color: 'black', marginRight: '5px' }}
                />
              </Box>
            </PayoutButton>

            <PayoutButton onClick={ifNewLink}>
              <Box sx={{ textAlign: 'left' }}>
                <Typography variant='body1' sx={{ fontWeight: 'bold', fontSize: '1rem' }}>
                  Add Your Payout Method.
                </Typography>
              </Box>
              <ArrowForwardIosIcon />
            </PayoutButton>
            <Collapse in={open}>
              <CustomAlert
                severity='error'
                action={
                  <IconButton aria-label='close' color='inherit' size='small' onClick={() => setOpen(false)}>
                    <CloseIcon fontSize='inherit' />
                  </IconButton>
                }
              >
                {error}
              </CustomAlert>
            </Collapse>
          </PayoutContainer>
        </>
      ) : (
        <>
          <PayoutContainer>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                marginTop: '1rem',
              }}
            >
              <Button
                onClick={() => setShowConfirm(false)}
                sx={{ textTransform: 'none', height: 'auto', minWidth: '0' }}
              >
                <ArrowBackIcon sx={{ color: 'black', fontSize: '1.5rem' }} />
              </Button>
              <Box sx={{ flex: 1, textAlign: 'center' }}>
                <CurrentBalance sx={{ marginTop: '0' }}>Current Balance</CurrentBalance>
              </Box>
              <Box sx={{ width: '1.5rem' }}></Box>
            </Box>
            <BalanceAmount>{formatPrice(currentBalance)}</BalanceAmount>
            <Typography
              sx={{
                marginLeft: '3%',
                marginTop: '40px',
                fontSize: '0.8rem',
                textAlign: 'justify',
                textJustify: 'inter-word',
                fontFamily: 'Oswald, sans-serif',
              }}
            >
              After confirmation, the funds will be transferred to you within 3-5 business days. While we process
              payments as quickly as possible, the timing is not guaranteed. Please note the requested payout amount
              must be greater than {formatPrice(30)}.
            </Typography>
          </PayoutContainer>
          <BottomContainer>
            <Collapse in={open}>
              <Alert
                severity='error'
                action={
                  <IconButton aria-label='close' color='inherit' size='small' onClick={() => setOpen(false)}>
                    <CloseIcon fontSize='inherit' />
                  </IconButton>
                }
                sx={{
                  marginBottom: 2,
                  backgroundColor: 'transparent',
                  border: 'none',
                  boxShadow: 'none',
                }}
              >
                {error}
              </Alert>
            </Collapse>
            <AmountContainer>
              <Typography variant='body1' sx={{ flex: 1, fontSize: '0.9rem' }}>
                Amount Requested:
              </Typography>
              <Typography variant='body1' sx={{ fontSize: '0.9rem', fontFamily: 'roboto' }}>
                {formatPrice(currentBalance)}
              </Typography>
            </AmountContainer>
            <ButtonContainer>
              <BlackButton text='Confirm Request' handleClick={() => clickSubmit()}></BlackButton>
            </ButtonContainer>
          </BottomContainer>
        </>
      )}
    </>
  );
};

const StyledBackButton = styled(BackButton)(({ theme }) => ({
  textTransform: 'none',
  minWidth: '0',
  color: 'black',
  fontSize: '1.5rem',
  display: 'flex',
  marginTop: '10px',
  marginBottom: '20px',
}));

const PayoutContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: '10px',
  marginBottom: '20px',
  width: '96%',
  padding: '4px',
}));

const CurrentBalance = styled(Typography)(({ theme }) => ({
  fontSize: '24px',
  fontWeight: 'bold',
  marginTop: '20px',
  color: '#F27C22',
  padding: '10px 10px',
  textAlign: 'center',
  fontFamily: 'sans-serif',
  [theme.breakpoints.up('sm')]: {
    marginLeft: '2.5%',
  },
}));

const BalanceAmount = styled('span')(({ theme }) => ({
  display: 'block',
  fontSize: '2rem',
  color: 'black',
  marginTop: '1px',
  fontFamily: 'sans-serif',
  fontWeight: 'bold',
  [theme.breakpoints.up('sm')]: {
    marginLeft: '3%',
  },
}));

const PayoutButton = styled(Button)(({ theme }) => ({
  marginTop: '20px',
  backgroundColor: '#D9D9D9',
  color: 'black',
  borderRadius: '15px',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: '#D0D0D0',
  },
  marginLeft: '2.5%',
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
}));

const AmountContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: '95%',
  padding: '10px',
  alignItems: 'center',
  marginTop: 'auto',
}));

const ButtonContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: '95%',
  padding: '10px',
  alignItems: 'center',
  marginTop: 'auto',
  marginLeft: '1%',
  [theme.breakpoints.down('sm')]: {
    marginLeft: '2%',
    width: '95%',
  },
}));

const BottomContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  position: 'fixed',
  bottom: '20px',
  justifyContent: 'center',
  padding: '10px',
  width: '90%',

  [theme.breakpoints.up('sm')]: {
    marginLeft: '1%',
    width: '95%',
  },
}));

const Divider = styled('div')(({ theme }) => ({
  border: '0.25px solid #8B8787',
  width: '100%',
  height: '0px',
  marginTop: '1rem',
  opacity: '1',
}));

const CustomAlert = styled(Alert)(({ theme }) => ({
  marginTop: 2,
  marginLeft: '10%',
  backgroundColor: 'transparent',
  border: 'none',
  boxShadow: 'none',
  width: '90%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: '5.5%',
    width: '92%',
  },
}));
