import React, { useEffect, useState } from 'react';
import { styled, Box, Typography, Alert, IconButton, Collapse } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { Appbar, BlackButton, BackButton } from '../../components';
import { DatePicker, TimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ArrowDropDown as ArrowDropDownIcon, Close as CloseIcon } from '@mui/icons-material';
import { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import myApi, { FirebaseApiResponse } from '../../../backend/firebase/FirebaseApi';
import { base } from '../../../backend/utils/BaseUrl';
import { OnboardingTrainerInfo, WeeklyAvailability, TimeRange } from '../../../backend';
import { TimeConflictUtils } from '../../../backend/utils/TimeValidation';
import moment from 'moment-timezone';

interface SessionData {
  date: Dayjs | null;
  time: Dayjs | null;
}

interface TimeSlot {
  startTime: string;
  endTime: string;
}

interface AvailabilityByDate {
  [date: string]: TimeSlot[];
}

interface BlockedTime {
  start: number; // Minutes since start of day
  end: number; // Minutes since start of day
}

export const ChooseTimingPage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { trainerID, trainerName, sessionType, cost, numberOfSessions } = location.state || {};
  const [sessionData, setSessionData] = useState<SessionData[]>([]);
  const [trainerInfo, setTrainerInfo] = useState<OnboardingTrainerInfo | null>(null);
  const [availabilityByDate, setAvailabilityByDate] = useState<AvailabilityByDate>({});
  const [loading, setLoading] = useState(true);
  const [showAlert, setShowAlert] = useState<string>('');
  const [blockedTimesByDate, setBlockedTimesByDate] = useState<{ [key: string]: BlockedTime[] }>({});

  useEffect(() => {
    setSessionData(
      Array(numberOfSessions)
        .fill(null)
        .map(() => ({ date: null, time: null }))
    );
  }, [numberOfSessions]);

  useEffect(() => {
    const fetchTrainerInfo = async () => {
      if (!trainerID) {
        console.warn('Trainer ID is missing.');
        setShowAlert('Trainer information not available');
        setLoading(false);
        return;
      }

      try {
        const response = await myApi.readSingleTrainer(base, trainerID);
        if (typeof response === 'string' || (response as any).error) {
          throw new Error(typeof response === 'string' ? response : (response as any).error);
        }
        const trainerData = response as OnboardingTrainerInfo;
        setTrainerInfo(trainerData);
        if (trainerData.availability) {
          processAvailability(trainerData.availability);
        }
      } catch (err) {
        console.error('Error fetching trainer info:', err);
        setShowAlert('Failed to fetch trainer info');
      } finally {
        setLoading(false);
      }
    };

    fetchTrainerInfo();
  }, [trainerID]);

  const processAvailability = (availability: WeeklyAvailability) => {
    const availByDate: AvailabilityByDate = {};
    const today = moment();

    for (let i = 0; i < 30; i++) {
      const currentDate = moment(today).add(i, 'days');
      const dayOfWeek = currentDate.format('dddd').toLowerCase();
      const dayAvailability = availability[dayOfWeek as keyof WeeklyAvailability];

      if (dayAvailability && dayAvailability.length > 0) {
        const dateStr = currentDate.format('YYYY-MM-DD');
        availByDate[dateStr] = dayAvailability.map((slot) => ({
          startTime: slot.startTime,
          endTime: slot.endTime,
        }));
      }
    }

    setAvailabilityByDate(availByDate);
  };

  const fetchBlockedTimes = async (date: string) => {
    if (!trainerID) return [];

    console.log('user current timezone: ', Intl.DateTimeFormat().resolvedOptions().timeZone);

    try {
      const blockedSlots = await TimeConflictUtils.getBlockedTimeSlots(
        trainerID,
        date,
        Intl.DateTimeFormat().resolvedOptions().timeZone
      );

      // Convert time strings to minute values
      return blockedSlots.map((slot) => ({
        start: moment(slot.startTime, 'hh:mm A').hours() * 60 + moment(slot.startTime, 'hh:mm A').minutes(),
        end: moment(slot.endTime, 'hh:mm A').hours() * 60 + moment(slot.endTime, 'hh:mm A').minutes(),
      }));
    } catch (error) {
      console.error('Error fetching blocked times:', error);
      return [];
    }
  };

  const handleDateChange = async (index: number, newDate: Dayjs | null) => {
    if (newDate) {
      const dateStr = newDate.format('YYYY-MM-DD');
      if (!blockedTimesByDate[dateStr]) {
        const blockedTimes = await fetchBlockedTimes(dateStr);
        setBlockedTimesByDate((prev) => ({
          ...prev,
          [dateStr]: blockedTimes,
        }));
      }
    }

    setSessionData((prevData) =>
      prevData.map((session, i) => (i === index ? { ...session, date: newDate, time: null } : session))
    );
  };

  const getAvailableTimeSlots = (date: Dayjs, sessionLengthMinutes: number): Dayjs[] => {
    const dateStr = date.format('YYYY-MM-DD');
    const slots = availabilityByDate[dateStr] || [];
    const blockedTimes = blockedTimesByDate[dateStr] || [];
    const availableSlots: Dayjs[] = [];

    slots.forEach((slot) => {
      const startTime = moment(`${dateStr} ${slot.startTime}`);
      const endTime = moment(`${dateStr} ${slot.endTime}`);
      let currentSlot = moment(startTime);

      while (currentSlot.isBefore(endTime)) {
        const currentMinutes = currentSlot.hours() * 60 + currentSlot.minutes();
        const sessionEndMinutes = currentMinutes + sessionLengthMinutes;

        // Check if this time slot overlaps with any blocked times
        const isBlocked = blockedTimes.some(
          (blockedTime) => !(sessionEndMinutes <= blockedTime.start || currentMinutes >= blockedTime.end)
        );

        if (!isBlocked && sessionEndMinutes <= endTime.hours() * 60 + endTime.minutes()) {
          availableSlots.push(dayjs(currentSlot.format()));
        }

        currentSlot.add(15, 'minutes'); // Increment by 15-minute intervals
      }
    });

    return availableSlots;
  };

  const handleTimeChange = async (index: number, newTime: Dayjs | null) => {
    const session = sessionData[index];
    if (!session.date || !newTime || !trainerInfo?.timezone) return;

    const proposedDate = session.date.format('YYYY-MM-DD');
    const proposedTimeRange: TimeRange = {
      startTime: newTime.format('hh:mm A'),
      endTime: newTime.add(parseInt(sessionType.split(' ')[0], 10), 'minutes').format('hh:mm A'),
    };

    // Check if time is within blocked times
    const currentMinutes = newTime.hour() * 60 + newTime.minute();
    const sessionEndMinutes = currentMinutes + parseInt(sessionType.split(' ')[0], 10);
    const blockedTimes = blockedTimesByDate[proposedDate] || [];

    const isBlocked = blockedTimes.some(
      (blockedTime) => !(sessionEndMinutes <= blockedTime.start || currentMinutes >= blockedTime.end)
    );

    if (isBlocked) {
      setShowAlert('This time slot conflicts with an existing session.');
      return;
    }

    // reset error state if new time is valid
    setShowAlert('');

    setSessionData((prevData) => prevData.map((session, i) => (i === index ? { ...session, time: newTime } : session)));
  };

  const handleBackClick = () => {
    navigate('/reserve-choice');
  };

  const validateAndSaveSessions = async () => {
    const incomplete = sessionData.some((session) => !session.date || !session.time);
    if (incomplete) {
      setShowAlert('Please select both date and time for all sessions');
      return;
    }

    if (!trainerInfo?.timezone) {
      setShowAlert('Trainer timezone information is missing');
      return;
    }

    // Check for overlapping sessions
    const sessionDurationMinutes = parseInt(sessionType.split(' ')[0], 10);
    for (let i = 0; i < sessionData.length; i++) {
      const session1 = sessionData[i];
      const session1Start = moment(
        `${session1.date!.format('YYYY-MM-DD')} ${session1.time!.format('hh:mm A')}`,
        'YYYY-MM-DD hh:mm A'
      );
      const session1End = moment(session1Start).add(sessionDurationMinutes, 'minutes');

      // Compare with all subsequent sessions
      for (let j = i + 1; j < sessionData.length; j++) {
        const session2 = sessionData[j];
        const session2Start = moment(
          `${session2.date!.format('YYYY-MM-DD')} ${session2.time!.format('hh:mm A')}`,
          'YYYY-MM-DD hh:mm A'
        );
        const session2End = moment(session2Start).add(sessionDurationMinutes, 'minutes');

        // Check if sessions overlap
        if (session1.date!.format('YYYY-MM-DD') === session2.date!.format('YYYY-MM-DD')) {
          if (!(session1End.isSameOrBefore(session2Start) || session2End.isSameOrBefore(session1Start))) {
            setShowAlert(`Sessions ${i + 1} and ${j + 1} overlap. Please choose different times.`);
            return;
          }
        }
      }
    }

    // Validate each session's time is within trainer's availability
    for (const session of sessionData) {
      const dateStr = session.date!.format('YYYY-MM-DD');
      const timeStr = session.time!.format('hh:mm A');
      const sessionEndTime = moment(session.time!.toDate()).add(sessionDurationMinutes, 'minutes');
      const sessionEndTimeStr = sessionEndTime.format('hh:mm A');

      // Get trainer's availability for this day
      const dayAvailability = availabilityByDate[dateStr] || [];

      // Check if the time falls within any of the trainer's available slots
      const isTimeWithinAvailability = dayAvailability.some((slot) => {
        const slotStart = moment(`${dateStr} ${slot.startTime}`, 'YYYY-MM-DD hh:mm A');
        const slotEnd = moment(`${dateStr} ${slot.endTime}`, 'YYYY-MM-DD hh:mm A');
        const sessionStart = moment(`${dateStr} ${timeStr}`, 'YYYY-MM-DD hh:mm A');
        const sessionEnd = moment(`${dateStr} ${sessionEndTimeStr}`, 'YYYY-MM-DD hh:mm A');

        return sessionStart.isSameOrAfter(slotStart) && sessionEnd.isSameOrBefore(slotEnd);
      });

      if (!isTimeWithinAvailability) {
        setShowAlert(
          `Selected time (${timeStr} - ${sessionEndTimeStr}) is outside of trainer's availability for ${dateStr}`
        );
        return;
      }
    }

    const formattedSessions = sessionData.map((session) => ({
      date: session.date!.format('YYYY-MM-DD'),
      timeRange: {
        startTime: session.time!.format('hh:mm A'),
        endTime: moment(session.time!.toDate()).add(sessionDurationMinutes, 'minutes').format('hh:mm A'),
      },
      sessionType,
    }));

    const validationResult = await TimeConflictUtils.validateSessionRequest(
      trainerID,
      formattedSessions,
      trainerInfo.timezone
    );

    if (!validationResult.valid) {
      setShowAlert(validationResult.error || 'Time conflict detected');
      return;
    }

    navigate('/review-timing', {
      state: {
        trainerID,
        trainerName,
        sessions: formattedSessions,
        cost,
      },
    });
  };

  if (loading) return <Typography>Loading...</Typography>;

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Appbar showMenu={false} showCloseIcon={false} userRole='client' />
      <StyledBackButton onClick={handleBackClick} />
      <Container>
        <Title variant='h4' sx={{ fontFamily: 'Oswald, sans-serif' }}>
          {numberOfSessions === 1 ? 'Choose Session Timing' : 'Choose Session Timings'}
        </Title>
        <Description>The availability is shown as EST.</Description>
        <Collapse in={!!showAlert}>
          <Alert
            severity='error'
            sx={{ mb: 2, borderRadius: '15px' }}
            action={
              <IconButton
                aria-label='close'
                color='inherit'
                size='small'
                onClick={() => {
                  setShowAlert('');
                }}
              >
                <CloseIcon fontSize='inherit' />
              </IconButton>
            }
          >
            {showAlert}
          </Alert>
        </Collapse>
        {sessionData.map((session, index) => (
          <SessionContainer key={index}>
            {numberOfSessions > 1 && <SessionLabel>Session {index + 1}</SessionLabel>}
            <PickersContainer isSingleSession={numberOfSessions === 1}>
              <StyledDatePicker
                label='Choose Date'
                value={session.date}
                onChange={(value) => handleDateChange(index, value)}
                shouldDisableDate={(date) => !availabilityByDate[date.format('YYYY-MM-DD')]}
                slots={{
                  openPickerIcon: StyledArrowDropDownIcon,
                }}
                slotProps={{
                  textField: { variant: 'outlined' as const },
                }}
              />
              <StyledTimePicker
                label='Choose Time'
                value={session.time}
                onChange={(newTime) => handleTimeChange(index, newTime)}
                disabled={!session.date}
                shouldDisableTime={(time, view) => {
                  if (!session.date) return false;
                  const availableSlots = getAvailableTimeSlots(session.date, parseInt(sessionType.split(' ')[0], 10));

                  if (view === 'hours') {
                    return !availableSlots.some((slot) => slot.hour() === time.hour());
                  } else {
                    const currentHour = session.time?.hour() ?? time.hour();
                    return !availableSlots.some(
                      (slot) => slot.hour() === currentHour && slot.minute() === time.minute()
                    );
                  }
                }}
                slots={{
                  openPickerIcon: StyledArrowDropDownIcon,
                }}
                slotProps={{
                  textField: { variant: 'outlined' as const },
                }}
              />
            </PickersContainer>
          </SessionContainer>
        ))}
        <ButtonContainer>
          <BlackButton text='Review My Timings' handleClick={validateAndSaveSessions}></BlackButton>
        </ButtonContainer>
      </Container>
    </LocalizationProvider>
  );
};

const ButtonContainer = styled(Box)(({ theme }) => ({
  position: 'static',
  background: 'transparent',
  marginTop: '3%',
  [theme.breakpoints.down('sm')]: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'center',
    padding: '16px 0',
    zIndex: 1000,
    background: 'transparent',
  },
}));

const Container = styled(Box)(({ theme }) => ({
  padding: '2%',
}));

const Title = styled(Typography)(({ theme }) => ({
  marginTop: '25px',
  color: '#F27C22',
  marginBottom: '25px',
  fontWeight: 'bold',
  textAlign: 'center',
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.7rem',
    marginTop: '7vh',
  },
  '@media (max-width: 380px)': {
    marginTop: '7vh',
  },
}));

const SessionContainer = styled(Box)({
  marginBottom: '30px',
});

const SessionLabel = styled(Typography)({
  fontSize: '15px',
  fontWeight: 'bold',
  marginBottom: '10px',
});

const PickersContainer = styled(Box)<{ isSingleSession: boolean }>(({ isSingleSession }) => ({
  display: 'flex',
  flexDirection: isSingleSession ? 'column' : 'row',
  gap: '16px',
}));

const StyledArrowDropDownIcon = styled(ArrowDropDownIcon)({
  color: 'black',
  fontSize: '30px',
});

const StyledDatePicker = styled(DatePicker)({
  width: '100%',
  '& .MuiOutlinedInput-root': {
    backgroundColor: '#f5f5f5',
    borderRadius: '15px',
    '& fieldset': {
      borderColor: 'transparent',
    },
    '&:hover fieldset': {
      borderColor: 'transparent',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'transparent',
    },
  },
  '& .MuiOutlinedInput-input': {
    padding: '15px 20px',
    paddingRight: '40px',
  },
  '& .MuiInputLabel-root': {
    transform: 'translate(20px, 16px) scale(1)',
  },
  '& .MuiInputLabel-shrink': {
    transform: 'translate(20px, -6px) scale(0.75)',
  },
});

const StyledTimePicker = styled(TimePicker)({
  width: '100%',
  '& .MuiOutlinedInput-root': {
    backgroundColor: '#f5f5f5',
    borderRadius: '15px',
    '& fieldset': {
      borderColor: 'transparent',
    },
    '&:hover fieldset': {
      borderColor: 'transparent',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'transparent',
    },
  },
  '& .MuiOutlinedInput-input': {
    padding: '15px 20px',
    paddingRight: '40px',
  },
  '& .MuiInputLabel-root': {
    transform: 'translate(20px, 16px) scale(1)',
  },
  '& .MuiInputLabel-shrink': {
    transform: 'translate(20px, -6px) scale(0.75)',
  },
});

const StyledBackButton = styled(BackButton)(({ theme }) => ({
  position: 'absolute',
  top: 80,
  left: 20,
  zIndex: 1000,
  [theme.breakpoints.down('md')]: {
    top: 70,
    left: 15,
  },
  [theme.breakpoints.down('sm')]: {
    top: 65,
    left: 0,
  },
  '@media (max-width: 380px)': {
    top: 60,
    left: -5,
  },
}));

const Description = styled(Typography)(({ theme }) => ({
  color: '#797A79',
  fontSize: 12,
  lineHeight: '20px',
  textAlign: 'center',
  marginInline: '4%',
  marginBottom: '15px',
  fontFamily: 'Oswald, sans-serif',
  whiteSpace: 'pre-line',
  '@media (min-width:1024px) and (orientation: landscape)': {
    marginBottom: '2%',
  },
}));
