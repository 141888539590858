import React, { useEffect, useState } from 'react';
import { useParams, Navigate } from 'react-router-dom';
import myApi from '../../../backend/firebase/FirebaseApi';
import { base } from '../../../backend/utils/BaseUrl';
import { VideoPage } from './VideoPage';

interface VideoData {
  clientToken: string;
  trainerToken: string;
  callingID: string;
  clientUserId: string;
  trainerUserId: string;
  time: string;
  clientID: string;
  trainerID: string;
}

export const VideoPageWrapper: React.FC = () => {
  const { sessionID } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const initializeVideoCall = async () => {
      try {
        // Try to get data from sessionStorage first
        let existingSessionId = sessionStorage.getItem('sessionID');
        console.log('session id: ', existingSessionId);
        console.log('session id: ', sessionID);

        if (!existingSessionId) {
          existingSessionId = sessionID || null;
        }

        if (!existingSessionId) {
          setLoading(false);
          return;
        }

        // Otherwise fetch fresh data
        const response = await myApi.getVideoData(base, existingSessionId!);

        if (typeof response === 'string' || (response as any).error) {
          throw new Error(typeof response === 'string' ? response : (response as any).error);
        }

        const videoData = response as VideoData;
        console.log('videoData: ', videoData);

        // Store data in sessionStorage
        sessionStorage.setItem('sessionID', existingSessionId!);
        sessionStorage.setItem('callId', videoData.callingID);
        if (videoData.time) sessionStorage.setItem('time', videoData.time);
        //set based on client or trainer visiting(agent)
        let token;
        let userId;
        if (sessionStorage.getItem('Agent') === 'trainer') {
          token = videoData.trainerToken;
          userId = videoData.trainerUserId;
        } else {
          token = videoData.clientToken;
          userId = videoData.clientUserId;
        }
        sessionStorage.setItem('token', token);
        sessionStorage.setItem('userId', userId);
        sessionStorage.removeItem('status');
        setLoading(false);
      } catch (err) {
        console.error('Error fetching video data:', err);
        setError(err instanceof Error ? err.message : 'Failed to fetch video data');
        setLoading(false);
      }
    };

    initializeVideoCall();
  }, [sessionID]);

  if (loading) {
    return <div>Loading video call data...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  // Verify we have all required data
  const requiredFields = ['sessionID', 'callId', 'token', 'userId'].every((field) => sessionStorage.getItem(field));
  console.log('requiredFields: ', requiredFields);

  if (!requiredFields) {
    return <Navigate to='/trainer-upcoming-sessions' replace />;
  }

  return <VideoPage />;
};
