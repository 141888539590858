import React, { useEffect, useState } from 'react';
import {
  styled,
  Box,
  Grid,
  Typography,
  Button,
  Card,
  CardContent,
  useMediaQuery,
  IconButton,
  Alert,
  Collapse,
  Avatar,
  Snackbar,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { useNavigate, useLocation } from 'react-router-dom';
import { Appbar, BackButton } from '../../components';
import firebaseApi from '../../../backend/firebase/FirebaseApi';
import { firebaseAuth } from '../../../backend/firebase';
import { usePriceFormatter } from '../../hooks/usePriceFormatter';
import myApi from '../../../backend/firebase/FirebaseApi';
// import { appBase, base } from '../../../backend/utils/BaseUrl';
import { base } from '../../../backend/utils/BaseUrl';
import { useSelector } from 'react-redux';
import { RootState } from '../../../backend/redux/store';
import { TimeConflictUtils } from '../../../backend/utils/TimeValidation';

export const TrainerReservationDetails: React.FC = () => {
  const navigate = useNavigate();
  const isLandscapeLaptop = useMediaQuery('(min-width:1024px) and (orientation: landscape)');
  const { formatPrice } = usePriceFormatter();
  const location = useLocation();
  const requestDetails = location.state?.request;
  const [isRejecting, setIsRejecting] = useState(false);
  const [isAccepting, setIsAccepting] = useState(false);
  const trainerID = useSelector((state: RootState) => state.user.uid);
  const [alertState, setAlertState] = useState<{
    open: boolean;
    message: string;
    severity: 'success' | 'error';
  }>({
    open: false,
    message: '',
    severity: 'success',
  });

  const handleBackClick = () => {
    navigate(-1);
  };

  if (!requestDetails) {
    return (
      <Box sx={{ mt: 4, mx: 2 }}>
        <Collapse in={true}>
          <Alert
            severity='error'
            sx={{
              borderRadius: 2,
              '@media (min-width:1024px)': { mx: '15%' },
            }}
          >
            No reservation details found. Please go back and select a reservation.
          </Alert>
        </Collapse>
      </Box>
    );
  }

  const sendNotification = async (notificationDetails: any, type: string, recipientID: string) => {
    // Send notification to client
    try {
      const NotificationBody = {
        recipientID,
        type,
        details: notificationDetails,
      };
      console.log(NotificationBody);

      const notificationResponse = await myApi.sendEmailNotification(base, NotificationBody);

      if ('error' in notificationResponse) {
        console.error('Error sending notification:', notificationResponse.error);
        // Continue with the flow but log the error
      }
    } catch (notificationError) {
      console.error('Error sending notification:', notificationError);
      // Continue with the flow but log the error
    }
  };

  const handleReject = async () => {
    if (isRejecting || !trainerID || !requestDetails?.id) {
      setAlertState({
        open: true,
        message: 'Unable to reject request. Missing required information.',
        severity: 'error',
      });
      return;
    }

    setIsRejecting(true);
    try {
      // Add logging to verify the data being sent
      console.log('Sending rejection with:', {
        requestID: requestDetails.id,
        trainerID: trainerID,
      });
      const response = await myApi.rejectSessionRequest(base, {
        requestID: requestDetails.id,
        trainerID: trainerID,
      });

      if (response.message == 'Request rejected successfully') {
        setAlertState({
          open: true,
          message: 'Session request rejected successfully.',
          severity: 'success',
        });
        // Prepare notification details
        const notificationDetails = {
          trainerID,
          clientID: requestDetails.clientID,
          clientName: requestDetails.clientName,
          sessionType: requestDetails.sessionType,
          cost: requestDetails.cost,
        };

        await sendNotification(notificationDetails, 'rejected', notificationDetails.clientID);

        setTimeout(() => {
          navigate('/trainer-reservation', {
            state: { rejectedRequestId: requestDetails.id },
          });
        }, 2000);
      } else {
        console.error('Error rejecting request:', response.error);
        throw new Error();
      }
    } catch (error: any) {
      setAlertState({
        open: true,
        message: error.message || 'Failed to reject request. Please try again.',
        severity: 'error',
      });
    } finally {
      setIsRejecting(false);
    }
  };

  useEffect(() => {
    // Check if we're returning from a rejection
    if (location.state?.rejectedRequestId) {
      console.log('Rejected request ID:', location.state.rejectedRequestId);
      // // Remove the rejected request from the list
      // setRequestData((prev) => prev.filter((request) => request.id !== location.state.rejectedRequestId));
      // Clean up the location state
      navigate(location.pathname, { replace: true });
    }
  }, [location.state, navigate]);

  const handleAccept = async () => {
    if (!trainerID) {
      setAlertState({
        open: true,
        message: 'Unable to accept request. Please try logging in again.',
        severity: 'error',
      });
      return;
    }

    setIsAccepting(true);
    try {
      // First, validate the sessions for time conflicts
      const trainerResponse: any = await myApi.readSingleTrainer(base, trainerID);
      const timezone = trainerResponse?.timezone || 'UTC';

      const validationResult = await TimeConflictUtils.validateSessionRequest(
        trainerID,
        requestDetails.sessions.map((session: any) => ({
          date: session.date,
          timeRange: {
            startTime: session.timeRange.startTime,
            endTime: session.timeRange.endTime,
          },
          sessionType: requestDetails.sessionType,
        })),
        timezone
      );

      if (!validationResult.valid) {
        setAlertState({
          open: true,
          message: validationResult.error || 'Time conflict detected. Please check your schedule.',
          severity: 'error',
        });
        return;
      }

      const data = { requestID: requestDetails.id, trainerID: trainerID };
      const res = await firebaseApi.acceptSessionRequest(base, data);

      setAlertState({
        open: true,
        message: 'Session request accepted successfully.',
        severity: 'success',
      });

      if (res.status === 'accepted') {
        console.log(`Session request accpeted successfully`);
        // Send notifications
        const formattedSessions = res.createdSessions?.map((session: any, index: number) => ({
          date: requestDetails.sessions[index].date,
          timeRange: requestDetails.sessions[index].timeRange,
          // Generate video call link
          // videoLink: `${appBase}/video-session/${session.sessionID}`,
          videoLink: `${base}/video-session/${session.sessionID}`,
        }));

        setAlertState({
          open: true,
          message: 'Session request accepted successfully.',
          severity: 'success',
        });
        // Prepare notification details
        const notificationDetails = {
          trainerID,
          clientID: requestDetails.clientID,
          clientName: requestDetails.clientName,
          sessionType: requestDetails.sessionType,
          cost: requestDetails.cost,
          sessions: formattedSessions,
        };

        await sendNotification(notificationDetails, 'accepted', notificationDetails.clientID);
        await sendNotification(notificationDetails, 'accepted', notificationDetails.trainerID);

        setAlertState({
          open: true,
          message: 'Session request accepted successfully.',
          severity: 'success',
        });

        navigate('/trainer-confirmation', {
          state: { clientName: requestDetails.clientName },
        });
      } else {
        throw new Error(res.error);
      }
    } catch (error: any) {
      setAlertState({
        open: true,
        message: error.message || 'Failed to accept request. Please try again.',
        severity: 'error',
      });
    } finally {
      setIsAccepting(false);
    }
  };

  return (
    <>
      <Appbar showMenu={true} showCloseIcon={true} />
      <StyledBackButton onClick={handleBackClick} />

      <Collapse in={alertState.open}>
        <Alert
          severity={alertState.severity}
          action={
            <IconButton
              aria-label='close'
              color='inherit'
              size='small'
              onClick={() => setAlertState((prev) => ({ ...prev, open: false }))}
            >
              <CloseIcon fontSize='inherit' />
            </IconButton>
          }
          sx={{
            mb: 2,
            mx: 2,
            '@media (min-width:1024px)': {
              mx: '15%',
            },
          }}
        >
          {alertState.message}
        </Alert>
      </Collapse>

      <Grid
        container
        sx={{
          flexGrow: 1,
          overflow: 'hidden',
          '@media (min-width:1024px) and (orientation: landscape)': { marginTop: '-5%' },
        }}
      >
        <Grid item xs={12} md={isLandscapeLaptop ? 6 : 12} sx={{ height: '100%' }}>
          <Box
            sx={{
              '@media (min-width:1024px) and (orientation: landscape)': {
                marginTop: '18%',
                paddingLeft: '15%',
                height: '100%',
              },
            }}
          >
            <Title>Time Left to Accept: {requestDetails.acceptTimeLeft}</Title>
            <Description>
              Pre-booked sessions are paid at a higher hourly rate. Please carefully review the pay, date, and time of
              the requested session. Ensure you accept the session within the specified time, or the request will be
              automatically declined. Only accept a booking if you are certain you can accommodate the requested time.
              Multiple cancellations may result in account deactivation.
            </Description>
          </Box>
        </Grid>
        <Grid item xs={12} md={isLandscapeLaptop ? 6 : 12} sx={{ height: '100%', overflow: 'auto' }}>
          <Card
            sx={{
              mb: 4,
              borderRadius: '15px',
              backgroundColor: '#DEDEDE',
              marginInline: '2.5%',
              '@media (min-width:1024px) and (orientation: landscape)': {
                marginInline: '20%',
                mt: '13%',
              },
            }}
          >
            <CardContent sx={{ display: 'grid', mt: -1 }}>
              <Typography variant='h6' fontWeight={600} mb={0.5} textAlign={'center'}>
                {requestDetails.sessionLength} {requestDetails.sessionLength === 1 ? 'Session' : 'Sessions'} with{' '}
                {requestDetails.clientName}
              </Typography>
              <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                <Avatar alt={`Session with ${requestDetails.profilePhoto}`} sx={{ width: 95, height: 90, mr: 2 }}>
                  {requestDetails.profilePhoto}
                </Avatar>
                <Box sx={{ display: 'grid', alignSelf: 'center', textAlign: 'center' }}>
                  <Typography variant='body2' fontWeight={'bold'}>
                    Total Earnings
                  </Typography>
                  <Typography variant='h4' fontWeight={500}>
                    {formatPrice(requestDetails.income * requestDetails.sessionLength)}
                  </Typography>
                </Box>
              </Box>

              <Typography fontWeight='bold' mb={1}>
                Session Details:
              </Typography>
              <ScrollableList>
                {requestDetails.sessions.map((session: any, index: number) => (
                  <Box key={index} sx={{ borderLeft: 2, pl: 1, ml: 2, mb: 1.5 }}>
                    <Typography variant='body2' mb={0.5} fontWeight={'bold'}>
                      {session.date} at {session.timeRange.startTime} EST
                    </Typography>
                    <Typography variant='body2' mb={0.5} color={'#8B8787'}>
                      {requestDetails.sessionType + ' online session' || 'Unknown Session Type'}
                    </Typography>
                  </Box>
                ))}
              </ScrollableList>
            </CardContent>
            <Box sx={{ display: 'flex', mb: 2, marginInline: 1 }}>
              <Button
                sx={{
                  width: '65%',
                  mr: '2%',
                  borderRadius: '15px',
                  textAlign: 'center',
                  textTransform: 'none',
                  backgroundColor: '#148C3D',
                  color: 'white',
                  fontSize: '16px',
                  '&:hover': {
                    backgroundColor: '#FF7000',
                  },
                  '&:disabled': {
                    backgroundColor: '#cccccc',
                  },
                }}
                onClick={handleAccept}
                disabled={isRejecting || isAccepting}
              >
                {isAccepting ? 'Accepting...' : 'Accept Reservation'}
              </Button>
              <Button
                sx={{
                  width: '32%',
                  borderRadius: '15px',
                  textAlign: 'center',
                  textTransform: 'none',
                  backgroundColor: 'black',
                  color: '#F27C22',
                  fontSize: '16px',
                  '&:hover': {
                    backgroundColor: '#FF7000',
                  },
                  '&:disabled': {
                    backgroundColor: '#333333',
                    color: '#999999',
                  },
                }}
                onClick={handleReject}
                disabled={isRejecting || isAccepting}
              >
                {isRejecting ? 'Rejecting...' : 'Reject'}
              </Button>
            </Box>
          </Card>
        </Grid>
      </Grid>
      <Snackbar
        open={alertState.open}
        autoHideDuration={2000}
        onClose={() => setAlertState((prev) => ({ ...prev, open: false }))}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setAlertState((prev) => ({ ...prev, open: false }))}
          severity={alertState.severity}
          sx={{ width: '100%' }}
        >
          {alertState.message}
        </Alert>
      </Snackbar>
    </>
  );
};

const ScrollableList = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  maxHeight: '30vh',
  overflowY: 'auto',
  '&::-webkit-scrollbar': {
    width: '8px',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#888',
    borderRadius: '4px',
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: '#f1f1f1',
  },
  '@media (min-width:1024px) and (orientation: landscape)': {
    maxHeight: '35vh',
  },
}));

const StyledBackButton = styled(BackButton)(({ theme }) => ({
  marginTop: '2%',
  marginLeft: '2.5%',
  marginBottom: '0.5%',
  padding: '0',
  justifyContent: 'left',
  [theme.breakpoints.down('sm')]: {
    marginTop: '5%',
  },
  [theme.breakpoints.between('sm', 'md')]: {
    marginTop: '4%',
  },
  [theme.breakpoints.between('md', 'lg')]: {
    marginTop: '3%',
  },
  '@media (min-width:1024px) and (orientation: landscape)': {
    margin: '40px',
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  mt: 0,
  mb: 2,
  textAlign: 'center',
  fontWeight: '500',
  color: '#F27C22',
  font: 'oswald',
  fontSize: 30,
  fontFamily: 'Oswald, sans-serif',
  '@media (min-width:1024px) and (orientation: landscape)': {
    fontSize: 60,
  },
}));

const Description = styled(Typography)(({ theme }) => ({
  color: '#797A79',
  fontSize: 12,
  lineHeight: '20px',
  textAlign: 'center',
  marginInline: '2.5%',
  marginBottom: '32px',
  fontFamily: 'Oswald, sans-serif',
  '@media (min-width:1024px) and (orientation: landscape)': {
    marginBottom: '10%',
  },
  [theme.breakpoints.up('sm')]: {
    fontSize: 14,
  },
  [theme.breakpoints.up('md')]: {
    fontSize: 16,
  },
}));
