import {
  AccountBox as StateIdIcon,
  ArrowForwardIos as ArrowForwardIosIcon,
  Close as CloseIcon,
  Delete as DeleteIcon,
  DirectionsCar as DriverLicenseIcon,
  KeyboardBackspace as KeyboardBackspaceIcon,
  Public as PassportIcon,
} from '@mui/icons-material';
import {
  Alert,
  Box,
  Button,
  ButtonProps,
  Collapse,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  styled,
} from '@mui/material';
import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Webcam from 'react-webcam';
import { Appbar, CustomStepper, GradientButton } from '../../components';
import { TrainerInfo } from './TrainerInfo';
import { ref, uploadBytes, getDownloadURL, deleteObject, StorageReference, getMetadata } from 'firebase/storage';
import { storage } from '../../../backend/firebase/FirebaseConfig';
import { RootState } from '../../../backend/redux/store';
import { useSelector } from 'react-redux';

const videoConstraints = {
  width: 500,
  height: 360,
  facingMode: 'user',
};

export const TrainerIdentityPage: React.FC = () => {
  const uid = useSelector((state: RootState) => state.user.uid);
  const [trainerInfo, setTrainerInfo] = useState<TrainerInfo>(() => {
    const savedInfo = localStorage.getItem('trainerInfo');

    return savedInfo
      ? JSON.parse(savedInfo)
      : {
          firstName: '',
          lastName: '',
          phone: '',
          gender: '',
          dateOfBirth: '',
          identificationOption: '',
          identityFrontImageURL: '',
          identityBackImageURL: '',
          identityFaceImageURL: '',
          consistency: '',
          before: '',
          textValue: '',
          certext: '',
          certificationImageURL: '',
          termsAccepted: false,
        };
  });
  const location = useLocation();
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const [step, setStep] = useState<number>(location.state?.step || 0);
  const [openDialog, setOpenDialog] = useState(false);
  const [isFrontImageUploaded, setIsFrontImageUploaded] = useState<boolean>(
    !!localStorage.getItem(trainerInfo.identityFrontImageURL)
  );
  const [isBackImageUploaded, setIsBackImageUploaded] = useState<boolean>(
    !!localStorage.getItem(trainerInfo.identityBackImageURL)
  );
  const [isFaceImageUploaded, setIsFaceImageUploaded] = useState<boolean>(
    !!localStorage.getItem(trainerInfo.identityFaceImageURL)
  );
  const webcamRef = useRef<Webcam>(null);
  const [showWebcam, setShowWebcam] = useState(false);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>('');

  const navigate = useNavigate();

  const handleOptionSelect = (option: string) => {
    const storedOption = localStorage.getItem('identity_selected_option');
    if (option !== storedOption) {
      resetUploadStates();
    }
    setSelectedOption(option);
    setStep(1);
    setShowAlert(false);
    localStorage.setItem('identity_selected_option', option);
    setTrainerInfo((prevState) => ({ ...prevState, identificationOption: option }));
  };

  const handleBack = () => {
    if (step === 0) {
      navigate('/trainer-information');
    } else if (step === 1) {
      setStep(0);
    } else if (step === 2) {
      setStep(1);
      const frontImageURL = localStorage.getItem('identity_front_imageURL');
      const backImageURL = localStorage.getItem('identity_back_imageURL');
      setTrainerInfo((prevState) => ({
        ...prevState,
        identityFrontImageURL: frontImageURL || '',
        identityBackImageURL: backImageURL || '',
      }));
    }
  };

  const resetUploadStates = async () => {
    setIsFrontImageUploaded(false);
    setIsBackImageUploaded(false);
    localStorage.removeItem('identity_front_imageURL');
    localStorage.removeItem('identity_back_imageURL');
    setTrainerInfo((prevState) => ({ ...prevState, identityFrontImageURL: '', identityBackImageURL: '' }));
    await deleteIdentificationImages();
  };

  async function deleteIdentificationImages() {
    const frontImageRef = ref(storage, `trainers/${uid}/identificationImage/front`);
    const backImageRef = ref(storage, `trainers/${uid}/identificationImage/back`);
    try {
      // Check if the front image exists
      await getMetadata(frontImageRef);
      // If it exists, delete the front image
      await deleteObject(frontImageRef);
      console.log('Front image deleted.');
    } catch (error: any) {
      if (error.code === 'storage/object-not-found') {
        console.log('Front image does not exist.');
      } else {
        console.error('Error checking front image:', error);
      }
    }

    try {
      // Check if the back image exists
      await getMetadata(backImageRef);
      // If it exists, delete the back image
      await deleteObject(backImageRef);
      console.log('Back image deleted.');
    } catch (error: any) {
      if (error.code === 'storage/object-not-found') {
        console.log('Back image does not exist.');
      } else {
        console.error('Error checking back image:', error);
      }
    }
  }

  const handleImageUpload = async (event: React.ChangeEvent<HTMLInputElement>, type: 'front' | 'back' | 'face') => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      const storageRef = ref(storage, `trainers/${uid}/identificationImage/${type}`);
      try {
        await uploadBytes(storageRef, file);
        const downloadURL = await getDownloadURL(storageRef);
        if (type === 'face') {
          localStorage.setItem(`identity_face_imageURL`, downloadURL);
          setTrainerInfo((prevState) => ({
            ...prevState,
            identityFaceImageURL: downloadURL || '',
          }));
          setIsFaceImageUploaded(true);
        } else if (type === 'front') {
          localStorage.setItem(`identity_front_imageURL`, downloadURL);
          setTrainerInfo((prevState) => ({
            ...prevState,
            identityFrontImageURL: downloadURL || '',
          }));
          setIsFrontImageUploaded(true);
        } else if (type === 'back') {
          localStorage.setItem(`identity_back_imageURL`, downloadURL);
          setTrainerInfo((prevState) => ({
            ...prevState,
            identityBackImageURL: downloadURL || '',
          }));
          setIsBackImageUploaded(true);
        }
      } catch (error) {
        console.log('Error uploading image: ', error);
        setAlertMessage('Error uploading image. Please try again.');
        setShowAlert(true);
      }
    }
    setOpenDialog(false);
  };

  const handleCapture = async () => {
    const imageSrc = webcamRef.current?.getScreenshot();
    if (imageSrc) {
      const blob = await fetch(imageSrc).then((res) => res.blob());
      const storageRef = ref(storage, `trainers/${uid}/identificationImage/face`);
      try {
        await uploadBytes(storageRef, blob);
        const downloadURL = await getDownloadURL(storageRef);

        localStorage.setItem('identity_face_imageURL', downloadURL);
        setIsFaceImageUploaded(true);
        setTrainerInfo((prevState) => ({ ...prevState, identityFaceImageURL: downloadURL }));

        setShowWebcam(false);
        setOpenDialog(false);
      } catch (error) {
        console.error('Error taking face image: ', error);
        setAlertMessage('Error taking face image. Please try again.');
        setShowAlert(true);
      }
    }
  };

  const handleDelete = async (type: 'front' | 'back' | 'face') => {
    let imageRef: StorageReference | undefined;
    if (type === 'front') {
      imageRef = ref(storage, `trainers/${uid}/identificationImage/front`);
      setIsFrontImageUploaded(false);
      localStorage.removeItem('identity_front_imageURL');
      setTrainerInfo((prevState) => ({ ...prevState, identityFrontImageURL: '' }));
    } else if (type === 'back') {
      imageRef = ref(storage, `trainers/${uid}/identificationImage/back`);
      setIsBackImageUploaded(false);
      localStorage.removeItem('identity_back_imageURL');
      setTrainerInfo((prevState) => ({ ...prevState, identityBackImageURL: '' }));
    } else if (type === 'face') {
      imageRef = ref(storage, `trainers/${uid}/identificationImage/face`);
      setIsFaceImageUploaded(false);
      localStorage.removeItem('identity_face_imageURL');
      setTrainerInfo((prevState) => ({ ...prevState, identityFaceImageURL: '' }));
    }

    if (imageRef) {
      try {
        await deleteObject(imageRef);
        console.log(`${type} image deleted from Firebase Storage`);
      } catch (error) {
        console.error(`Error deleting ${type} image from Firebase Storage: `, error);
        setAlertMessage('Error deleting image. Please try again.');
        setShowAlert(true);
      }
    }
  };

  useEffect(() => {
    const restoreState = () => {
      const frontImage = localStorage.getItem('identity_front_imageURL');
      const backImage = localStorage.getItem('identity_back_imageURL');
      const faceImage = localStorage.getItem('identity_face_imageURL');
      const storedOption = localStorage.getItem('identity_selected_option');

      setIsFrontImageUploaded(!!frontImage);
      setIsBackImageUploaded(!!backImage);
      setIsFaceImageUploaded(!!faceImage);

      if (storedOption) {
        setSelectedOption(storedOption);
      }

      if (step === 1) {
        setTrainerInfo((prevState) => ({
          ...prevState,
          identityFrontImageURL: frontImage || '',
          identityBackImageURL: backImage || '',
        }));
      } else {
        setTrainerInfo((prevState) => ({
          ...prevState,
          identityFaceImageURL: faceImage || '',
        }));
      }
    };

    restoreState();
  }, [step]);

  useEffect(() => {
    if (
      (isFaceImageUploaded && step === 2) ||
      (isFrontImageUploaded && isBackImageUploaded && step === 1 && selectedOption !== 'Passport') ||
      (isFrontImageUploaded && step === 1 && selectedOption === 'Passport')
    ) {
      setShowAlert(false);
    }
  }, [isFrontImageUploaded, isBackImageUploaded, isFaceImageUploaded, step, selectedOption]);

  const handleContinue = () => {
    if (
      (!isFaceImageUploaded && step === 2) ||
      ((!isFrontImageUploaded || !isBackImageUploaded) && step === 1 && selectedOption !== 'Passport') ||
      (!isFrontImageUploaded && step === 1 && selectedOption === 'Passport')
    ) {
      setAlertMessage('Please upload the required image(s) to proceed!');
      setShowAlert(true);
    } else if (step === 1) {
      setShowAlert(false);
      setStep(2);
    } else if (step === 2) {
      setShowAlert(false);
      localStorage.setItem('trainerInfo', JSON.stringify(trainerInfo));
      navigate('/trainer-experience', {
        state: { fromIdentityStep: 2 },
      });
    }
  };

  const renderUploadSection = () => {
    if (!selectedOption) return null;
    switch (selectedOption) {
      case "Driver's License":
        return (
          <>
            <CustomHeading>Upload Driver's License</CustomHeading>
            <Description>Make sure the entire license is visible and all information is legible.</Description>
            <UploadDescription variant='body1' gutterBottom>
              Upload front side of Driver's License
            </UploadDescription>
            <UploadBox>
              <StyledUploadButton variant='contained'>
                Upload Here!
                <input
                  type='file'
                  accept='image/*'
                  hidden
                  onChange={(e) => handleImageUpload(e, 'front')}
                  key={Date.now()}
                />
              </StyledUploadButton>
              {isFrontImageUploaded && <UploadedImageStatus onDelete={() => handleDelete('front')} />}
            </UploadBox>
            <UploadDescription variant='body1' gutterBottom>
              Upload back side of Driver's License
            </UploadDescription>
            <UploadBox>
              <StyledUploadButton variant='contained'>
                Upload Here!
                <input
                  type='file'
                  accept='image/*'
                  hidden
                  onChange={(e) => handleImageUpload(e, 'back')}
                  key={Date.now()}
                />
              </StyledUploadButton>
              {isBackImageUploaded && <UploadedImageStatus onDelete={() => handleDelete('back')} />}
            </UploadBox>
          </>
        );
      case 'State ID':
        return (
          <>
            <CustomHeading>Upload State ID</CustomHeading>
            <Description>Make sure the entire ID is visible and all information is legible.</Description>
            <UploadDescription variant='body1' gutterBottom>
              Upload front side of State ID
            </UploadDescription>
            <UploadBox>
              <StyledUploadButton variant='contained'>
                Upload Here!
                <input
                  type='file'
                  accept='image/*'
                  hidden
                  onChange={(e) => handleImageUpload(e, 'front')}
                  key={Date.now()}
                />
              </StyledUploadButton>
              {isFrontImageUploaded && <UploadedImageStatus onDelete={() => handleDelete('front')} />}
            </UploadBox>
            <UploadDescription variant='body1' gutterBottom>
              Upload back side of State ID
            </UploadDescription>
            <UploadBox>
              <StyledUploadButton variant='contained'>
                Upload Here!
                <input
                  type='file'
                  accept='image/*'
                  hidden
                  onChange={(e) => handleImageUpload(e, 'back')}
                  key={Date.now()}
                />
              </StyledUploadButton>
              {isBackImageUploaded && <UploadedImageStatus onDelete={() => handleDelete('back')} />}
            </UploadBox>
          </>
        );
      case 'Passport':
        return (
          <>
            <CustomHeading>Upload Passport</CustomHeading>
            <Description>
              Make sure the picture is clear and all information, including name, date of birth, sex, and nationality is
              legible.
            </Description>
            <UploadDescription variant='body1' gutterBottom>
              Upload Picture of Passport
            </UploadDescription>
            <UploadBox>
              <StyledUploadButton variant='contained'>
                Upload Here!
                <input
                  type='file'
                  accept='image/*'
                  hidden
                  onChange={(e) => handleImageUpload(e, 'front')}
                  key={Date.now()}
                />
              </StyledUploadButton>
              {isFrontImageUploaded && <UploadedImageStatus onDelete={() => handleDelete('front')} />}
            </UploadBox>
          </>
        );
      default:
        return null;
    }
  };

  const renderFacePic = () => {
    return (
      <>
        <CustomHeading>Upload a clear picture of your face</CustomHeading>
        <Description>
          Upload a clear photo of your face that matches the picture on your proof of identification. Avoid wearing any
          accessories or face coverings like sunglasses or hats unless they are for religious reasons. Choose this
          picture carefully, as it will be used as your profile photo and be visible to clients. Please note that this
          picture cannot be changed later.
        </Description>
        <UploadBox>
          <StyledUploadButton
            variant='contained'
            onClick={() => {
              setOpenDialog(true);
              setShowWebcam(false);
            }}
            key={Date.now()}
          >
            Upload Here!
          </StyledUploadButton>
          {isFaceImageUploaded && (
            <UploadedImageStatus
              onDelete={() => {
                handleDelete('face');
              }}
            />
          )}
        </UploadBox>
        <Dialog open={openDialog} onClose={() => setOpenDialog(false)} maxWidth='sm' fullWidth>
          <DialogTitle>Choose an option</DialogTitle>
          <DialogContent>
            <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: 2 }}>
              <StyledFaceButton variant='contained'>
                Upload from device
                <input type='file' hidden accept='image/*' onChange={(e) => handleImageUpload(e, 'face')} />
              </StyledFaceButton>
              <StyledFaceButton variant='contained' onClick={() => setShowWebcam(true)}>
                Take a photo
              </StyledFaceButton>
            </Box>
            {showWebcam && (
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Webcam
                  mirrored
                  audio={false}
                  ref={webcamRef}
                  screenshotFormat='image/jpeg'
                  videoConstraints={videoConstraints}
                  style={{ width: videoConstraints.width, height: videoConstraints.height, marginTop: 5 }}
                />
                <StyledFaceButton variant='contained' sx={{}} onClick={handleCapture}>
                  Capture Photo
                </StyledFaceButton>
              </Box>
            )}
          </DialogContent>
        </Dialog>
      </>
    );
  };

  return (
    <div>
      <Appbar showMenu={true} />

      <CustomDiv>
        <CustomStepper activeStep={1} />

        <BackButtonContainer>
          <IconButton onClick={handleBack}>
            <KeyboardBackspaceIcon sx={{ color: '#F27C22', fontSize: 30 }} />
          </IconButton>
        </BackButtonContainer>

        {step === 0 ? (
          <>
            <CustomContainer>
              <CustomHeading>Choose a way to verify your identity:</CustomHeading>
              <Description>
                You will need to select one of the following to verify your identity. This information will remain
                confidential with us and will not be shared with anyone.
              </Description>
              <OptionContainer sx={{ marginBottom: 4 }}>
                <IdentityOption
                  icon={<DriverLicenseIcon />}
                  text="Driver's License"
                  onClick={() => handleOptionSelect("Driver's License")}
                />
                <IdentityOption icon={<StateIdIcon />} text='State ID' onClick={() => handleOptionSelect('State ID')} />
                <IdentityOption
                  icon={<PassportIcon />}
                  text='Passport'
                  onClick={() => handleOptionSelect('Passport')}
                />
              </OptionContainer>
            </CustomContainer>
          </>
        ) : step === 1 && selectedOption ? (
          <>
            <CustomContainer>
              {renderUploadSection()}
              <CustomCollapse in={showAlert}>
                <Alert
                  severity='error'
                  action={
                    <IconButton
                      aria-label='close'
                      color='inherit'
                      size='small'
                      onClick={() => {
                        setShowAlert(false);
                      }}
                    >
                      <CloseIcon fontSize='inherit' />
                    </IconButton>
                  }
                >
                  {alertMessage}
                </Alert>
              </CustomCollapse>
              <GradientButton showArrowIcon text='Continue' handleClick={handleContinue} styles={ContinueStyle} />
            </CustomContainer>
          </>
        ) : (
          <>
            <CustomContainer>
              {renderFacePic()}
              <CustomCollapse in={showAlert}>
                <Alert
                  severity='error'
                  action={
                    <IconButton
                      aria-label='close'
                      size='small'
                      onClick={() => {
                        setShowAlert(false);
                      }}
                    >
                      <CloseIcon fontSize='inherit' />
                    </IconButton>
                  }
                >
                  {alertMessage}
                </Alert>
              </CustomCollapse>

              <GradientButton showArrowIcon text='Continue' handleClick={handleContinue} styles={ContinueStyle} />
            </CustomContainer>
          </>
        )}
      </CustomDiv>
    </div>
  );
};

const CustomDiv = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignContent: 'center',
  width: '100%',
  paddingTop: '2%',
  [theme.breakpoints.down('sm')]: {
    paddingTop: '15px',
  },
  [theme.breakpoints.up('lg')]: {
    paddingTop: '20px',
  },
}));

const BackButtonContainer = styled(Container)(({ theme }) => ({
  marginLeft: '0%',
  [theme.breakpoints.between('md', 'lg')]: {
    marginLeft: '10%',
    width: '60%',
  },
  [theme.breakpoints.up('lg')]: {
    marginLeft: '20%',
    width: '50%',
  },
}));

const CustomContainer = styled(Container)(({ theme }) => ({
  width: '50%',
  flexDirection: 'column',
  alignContent: 'center',
  display: 'flex',
  marginLeft: '25%',
  [theme.breakpoints.down('sm')]: {
    width: '80%',
    marginLeft: '10%',
  },
  [theme.breakpoints.between('sm', 'md')]: {
    width: '70%',
    marginLeft: '15%',
  },
  [theme.breakpoints.up('lg')]: {
    width: '50%',
    marginLeft: '25%',
  },
}));

interface IdentityOptionProps {
  icon: React.ReactNode;
  text: string;
  onClick: () => void;
}

const CustomListItemButton = styled(ListItemButton)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(1),
  },
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(2),
  },
}));

const CustomListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  color: 'black',
  [theme.breakpoints.down('sm')]: {
    minWidth: theme.spacing(4),
  },
  [theme.breakpoints.up('sm')]: {
    minWidth: theme.spacing(6),
  },
}));

const CustomListItemText = styled(ListItemText)(({ theme }) => ({
  marginLeft: '-5%',
  textAlign: 'left',
  fontWeight: 'bold',
  fontFamily: 'Roboto',
  [theme.breakpoints.between('xs', 'md')]: {
    marginLeft: '0%',
  },
  [theme.breakpoints.up('lg')]: {
    marginLeft: '0%',
  },
}));

const CustomIconButton = styled(IconButton)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(1),
  },
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(2),
  },
}));

const iconStyles = {
  display: 'flex',
  alignItems: 'center',
  color: 'black',
};

const IdentityOption: React.FC<IdentityOptionProps> = ({ icon, text, onClick }) => {
  return (
    <CustomListItemButton onClick={onClick}>
      <CustomListItemIcon>{icon}</CustomListItemIcon>
      <CustomListItemText disableTypography primary={text} />
      <CustomIconButton edge='end'>
        <Box sx={iconStyles}>
          <ArrowForwardIosIcon />
        </Box>
      </CustomIconButton>
    </CustomListItemButton>
  );
};

const OptionContainer = styled(Container)(({ theme }) => ({
  marginTop: '4%',
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    marginLeft: '-3%',
  },
}));

const CustomHeading = styled(Typography)(({ theme }) => ({
  marginTop: '5%',
  marginLeft: '5%',
  marginBottom: '5%',
  color: '#F27C22',
  fontFamily: 'Inter',
  fontSize: '27px',
  lineHeight: '30.26px',
  height: '33px',
  fontWeight: 'bold', // Bold the heading
  [theme.breakpoints.down('sm')]: {
    marginTop: '0%',
    marginLeft: '4%',
    fontSize: '24px',
    marginBottom: '0%',
  },
  [theme.breakpoints.between('md', 'lg')]: {
    marginBottom: '10%',
  },
  [theme.breakpoints.up('lg')]: {
    marginBottom: '5%',
  },
}));

const Description = styled(Typography)(({ theme }) => ({
  color: '#797A79',
  fontSize: '15px',
  lineHeight: '22.23px',
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(2),
  maxWidth: '95%',
  marginLeft: '5%',
  fontFamily: 'Oswald, sans-serif',
  [theme.breakpoints.down('sm')]: {
    marginLeft: '4%',
    marginTop: '15%',
  },
  [theme.breakpoints.between('sm', 'md')]: {
    marginLeft: '5%',
  },
  [theme.breakpoints.between('md', 'lg')]: {
    marginLeft: '5%',
    maxWidth: '87%',
    marginBottom: '6%',
  },
}));

const UploadDescription = styled(Typography)(({ theme }) => ({
  marginTop: '3%',
  marginLeft: '5%',
  fontSize: '20px',
  fontFamily: 'Oswald, sans-serif',
  [theme.breakpoints.down('sm')]: {
    marginLeft: '4%',
    fontSize: '15px',
  },
  [theme.breakpoints.between('sm', 'md')]: {
    marginLeft: '5%',
  },
  [theme.breakpoints.between('md', 'lg')]: {
    marginLeft: '5%',
    marginTop: '7%',
  },
}));

const StyledUploadButton: React.FC<ButtonProps> = (props) => (
  <UploadButton component='label' {...props}>
    {props.children}
  </UploadButton>
);

const UploadButton = styled(Button)(({ theme }) => ({
  width: 'wrap-content',
  height: 'wrap-content',
  borderRadius: '15px',
  padding: '10px 40px',
  backgroundColor: '#000',
  fontWeight: 'bold',
  textTransform: 'none',
  fontSize: '15px',
  marginLeft: '5%',
  [theme.breakpoints.down('sm')]: {
    marginLeft: '3.5%',
    fontSize: '10px',
  },
}));

const UploadBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignContent: 'center',
  [theme.breakpoints.down('sm')]: {
    marginBottom: '10%',
  },
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  marginTop: '13px',
  fontSize: '15px',
  [theme.breakpoints.down('sm')]: {
    marginLeft: '5%',
    fontSize: '10px',
  },
  [theme.breakpoints.between('sm', 'md')]: {
    marginLeft: '3%',
  },
  [theme.breakpoints.between('md', 'lg')]: {
    marginLeft: '3%',
  },
  [theme.breakpoints.up('lg')]: {
    marginLeft: '2%',
  },
}));

interface UploadedImageStatusProps {
  onDelete: () => void;
}

const UploadedImageStatus: React.FC<UploadedImageStatusProps> = ({ onDelete }) => (
  <>
    <StyledTypography>Image uploaded</StyledTypography>
    <IconButton onClick={onDelete} color='error'>
      <DeleteIcon />
    </IconButton>
  </>
);

const StyledFaceButton: React.FC<ButtonProps> = (props) => (
  <FaceButton component='label' {...props}>
    {props.children}
  </FaceButton>
);

const FaceButton = styled(Button)(({ theme }) => ({
  width: '50%',
  height: 'fit-content',
  marginTop: 2,
  borderRadius: '15px',
  padding: '10px 32px',
  backgroundColor: '#000',
  fontWeight: 'bold',
  textTransform: 'none',
  marginRight: '1%',
  [theme.breakpoints.down('sm')]: {
    width: '50%',
    height: '55px',
    fontSize: '12px',
  },
  [theme.breakpoints.between('sm', 'md')]: {
    fontSize: '13px',
  },
  [theme.breakpoints.between('md', 'lg')]: {
    fontSize: '14px',
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '16px',
  },
}));

const CustomCollapse = styled(Collapse)(({ theme }) => ({
  marginLeft: '8%',
  width: '90%',
  marginTop: '4%',
  marginBottom: '3%',
  [theme.breakpoints.down('sm')]: {
    marginLeft: '3.5%',
  },
  [theme.breakpoints.between('sm', 'md')]: {
    marginLeft: '8%',
  },
  [theme.breakpoints.between('md', 'lg')]: {
    marginLeft: '8%',
    marginTop: '6%',
  },
}));

const ContinueStyle: CSSProperties = {
  maxWidth: '95%',
  marginLeft: '3%',
  marginBottom: '5%',
};
