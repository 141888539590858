import React from 'react';
import { styled, Box, Typography, Divider, Button, Grid, useMediaQuery } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { Appbar, BlackButton } from '../../components';
import {
  QueryBuilder as QueryBuilderIcon,
  CreditCard as CreditCardIcon,
  Mail as MailIcon,
  Cancel as CancelIcon,
  Forum as ForumIcon,
  ArrowForwardIos as ForwardIcon,
} from '@mui/icons-material';

export const TrainerConfirmationPage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const clientName = location.state?.clientName || 'Client';
  const isLandscapeLaptop = useMediaQuery('(min-width:1024px) and (orientation: landscape)');

  const handleButtonClick = () => {
    navigate('/trainer-home');
  };

  return (
    <PageContainer>
      <Appbar showMenu={false} showCloseIcon={false} userRole='trainer' />
      <Grid container spacing={0}>
        <Grid item xs={12} md={isLandscapeLaptop ? 6 : 12}>
          <ReserveImage src='../../../../confirmation.jpg' alt='Gyfr Reserve' />
        </Grid>
        <Grid item xs={12} md={isLandscapeLaptop ? 6 : 12}>
          <ContentContainer>
            <Title variant='h3'>Booking Confirmed!</Title>
            <InformationContainer>
              <InfoBox>
                <IconBox>
                  <QueryBuilderIcon style={iconStyles} />
                </IconBox>
                <CustomListItemText children='Make sure to join your pre-booked sessions on time and greet your client with a smile!' />
              </InfoBox>
              <Divider />
              <InfoBox>
                <IconBox>
                  <CreditCardIcon style={iconStyles} />
                </IconBox>
                <CustomListItemText children='After each session, you will receive a portion of your total pay. Your full payment will be released once all sessions are completed.' />
              </InfoBox>
              <Divider />
              <InfoBox>
                <IconBox>
                  <CancelIcon style={iconStyles} />
                </IconBox>
                <CustomListItemText children='Failure to show up for a session may result in account deactivation. Contact Gyfr Support immediately if you need to cancel.' />
              </InfoBox>
              <Divider />
              <InfoBox>
                <IconBox>
                  <ForumIcon style={iconStyles} />
                </IconBox>
                <CustomListItemText children='You can now text your client to stay in touch, discuss their progress, set nutritional goals, and answer their questions!' />
              </InfoBox>
              <Divider />
              <InfoBox>
                <IconBox>
                  <MailIcon style={iconStyles} />
                </IconBox>
                <CustomListItemText children='When it is time to join your session, please navigate to "Upcoming Sessions" and click "Join Call"' />
              </InfoBox>
            </InformationContainer>
            <Box sx={{ flexGrow: 1 }} />
            <Box alignContent={'center'} display='flex' justifyContent='center'>
              <ButtonContainer>
                <BlackButton showArrowIcon handleClick={handleButtonClick} text={`Text ${clientName}`}></BlackButton>
              </ButtonContainer>
            </Box>
          </ContentContainer>
        </Grid>
      </Grid>
    </PageContainer>
  );
};

const ButtonContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  '@media (min-width:1024px) and (orientation: landscape)': {
    marginLeft: '13%',
    width: '80%',
  },
}));

const PageContainer = styled(Box)(({ theme }) => ({
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
  overflowY: 'auto',
  '@media (min-width:1024px) and (orientation: landscape)': {
    overflow: 'hidden',
  },
}));

const ReserveImage = styled('img')(({ theme }) => ({
  marginTop: '6px',
  width: '100%',
  height: 'auto',
  objectFit: 'cover',
  '@media (min-width:1024px) and (orientation: landscape)': {
    marginTop: '10px',
    width: '55vw',
    height: '100vh',
  },
}));

const ContentContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  '@media (min-width:1024px) and (orientation: landscape)': {
    height: '100vh',
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  marginTop: '20px',
  color: '#F27C22',
  marginBottom: '20px',
  fontWeight: 'bold',
  fontFamily: 'Oswald, sans-serif',
  '@media (min-width:1024px) and (orientation: landscape)': {
    marginBottom: '15px',
    marginLeft: '18%',
    marginTop: '8vh',
  },
}));

const InformationContainer = styled(Box)(({ theme }) => ({
  marginBottom: '20px',
  '@media (min-width:1024px) and (orientation: landscape)': {
    marginLeft: '15%',
    width: '80%',
  },
}));

const InfoBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: '13px 0',
}));

const IconBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginLeft: '3%',
  [theme.breakpoints.down('sm')]: {
    marginLeft: '0',
  },
}));

const iconStyles = {
  display: 'flex',
  alignItems: 'center',
  color: 'black',
  width: '40px',
  height: '40px',
};

const CustomListItemText = styled(Typography)(({ theme }) => ({
  marginLeft: 17,
  textAlign: 'left',
  fontSize: '15px',
  lineHeight: '120%',
}));
