import { CSSProperties, useState, useEffect } from 'react';
import { Typography, Box, Alert, Collapse, IconButton } from '@mui/material';
import { fontFamily, styled } from '@mui/system';
import { SessionCard } from './SessionCard';
import { Appbar, Customcard, Map } from '../../components';
import { Close as CloseIcon } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import CurrencyConverter from '../../../backend/utils/CurrencyConverter';
import { setUserCurrency, setRatesLoaded } from '../../../backend/redux/currencySlice';
import { usePriceFormatter } from '../../hooks/usePriceFormatter';

import { useNavigate } from 'react-router-dom';
const h1Style: CSSProperties = {
  color: '#F27C22',
  fontSize: '25px',
  marginBottom: '-1rem',
  textAlign: 'center',
  padding: '0.5rem 1rem',
  borderRadius: '10px',
  fontWeight: '790',
  marginTop: '0.5rem',
};

const ParentContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  height: '50%',
  alignItems: 'center',
  marginBottom: '20px',
  width: '100%',
  backgroundColor: 'black',
  flexDirection: 'column',
}));

const ActionsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-top: 1em;
  margin-bottom: 1em;
  width: 100%;
`;

export const HomePage = () => {
  const [sessionType, chooseSessionType] = useState<string>('');
  const [alertOpen, setAlertOpen] = useState<boolean>(false);

  const { formatPrice } = usePriceFormatter();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleInPersonClick = () => {
    setAlertOpen(true);
  };

  return (
    <>
      <Appbar showMenu={false} userRole='client' />
      <ParentContainer>
        <Customcard
          children={
            <>
              <Typography variant='h1' style={h1Style}>
                Choose your session type
              </Typography>
            </>
          }
          actions={
            <ActionsContainer>
              <SessionCard
                title='Online Session'
                estimatedWaitTime='2 minutes'
                price={formatPrice(6.99)}
                onClick={() => navigate('/home-session ')}
              />
              <SessionCard
                title='In-Person Session'
                estimatedWaitTime='5 minutes'
                price={formatPrice(9.99)}
                onClick={handleInPersonClick}
              />
              <Collapse in={alertOpen}>
                <Alert
                  severity='error'
                  action={
                    <IconButton aria-label='close' color='inherit' size='small' onClick={() => setAlertOpen(false)}>
                      <CloseIcon fontSize='inherit' />
                    </IconButton>
                  }
                >
                  In-person option coming soon! Please stay tuned and try out our online sessions in the meantime!
                </Alert>
              </Collapse>
            </ActionsContainer>
          }
        />
        <Map />
      </ParentContainer>
    </>
  );
};
