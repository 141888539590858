import React, { CSSProperties } from 'react';
import { Button } from '@mui/material';
import { styled } from '@mui/system';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const StyledButton = styled(Button)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderRadius: '15px',
  width: '100%',
  height: '45px',
  padding: '12px 24px',
  margin: 2,
  textTransform: 'none',
  marginTop: '20px',
  backgroundColor: 'black',
  '&:hover': {
    backgroundColor: '#333',
  },
}));

const TextSpan = styled('span')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  textAlign: 'center',
  fontFamily: 'sans-serif',
  textTransform: 'none',
  lineHeight: '1.2',
  fontWeight: 'lighter',
  color: 'white',
  paddingLeft: '12px',
  paddingRight: '12px',
  [theme.breakpoints.down('sm')]: {
    fontSize: '16px',
  },
  [theme.breakpoints.up('sm')]: {
    fontSize: '19px',
  },
}));

const IconSpan = styled('span')({
  flexShrink: 0,
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  right: '9px',
  top: '50%',
  transform: 'translateY(-50%)',
});

export type BlackButtonProps = {
  text: string;
  showArrowIcon?: boolean;
  handleClick: () => void;
  styles?: CSSProperties;
};

export const BlackButton: React.FC<BlackButtonProps> = (props) => {
  return (
    <StyledButton style={props.styles} variant='contained' onClick={props.handleClick}>
      <TextSpan>{props.text}</TextSpan>
      {props.showArrowIcon && (
        <IconSpan>
          <ArrowForwardIosIcon fontSize='medium' style={{ color: 'white' }} />
        </IconSpan>
      )}
    </StyledButton>
  );
};
