import { Container, Typography, styled } from '@mui/material';
import React, { CSSProperties } from 'react';
import { useNavigate } from 'react-router-dom';
import { Appbar, CustomStepper, GradientButton } from '../../components';

export const TrainerDonePage: React.FC = () => {
  const navigate = useNavigate();

  const handleContinue = () => {
    navigate('/trainer-home');
  }; // placeholder

  const handleExternalLink = (url: string) => {
    window.open(url, '_blank');
  };

  const clearStoredData = () => {
    localStorage.removeItem('certification_imageURL');

    localStorage.removeItem('identity_front_imageURL');
    localStorage.removeItem('identity_back_imageURL');
    localStorage.removeItem('identity_face_imageURL');
    localStorage.removeItem('identity_selected_option');

    localStorage.removeItem('trainerInfo');
  };

  // api call
  clearStoredData();

  return (
    <div>
      <Appbar showMenu={true} />

      <CustomDiv>
        <CustomStepper activeStep={3} />

        <CustomContainer>
          <CustomHeading>
            Success! We have received your application. Please check your account again in 24-72 hours!
          </CustomHeading>
          <NextText>Next Steps:</NextText>
          <Description>
            While we review your account, please take a moment to read the following guide. It will help you familiarize
            yourself with our platform, understand the pay structure, and learn the guidelines for interacting with and
            providing training to clients.
          </Description>
          <GradientButton
            text='Your Gyfr Guide'
            handleClick={() => handleExternalLink('https://gyfrapp.com/pages/personal-trainer-guide')}
            styles={{ marginLeft: '-1%', maxWidth: '95%' }}
          />
        </CustomContainer>
      </CustomDiv>
    </div>
  );
};

const CustomDiv = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignContent: 'center',
  width: '100%',
  paddingTop: '2%',
  [theme.breakpoints.down('sm')]: {
    paddingTop: '15px',
  },
  [theme.breakpoints.up('lg')]: {
    paddingTop: '20px',
  },
}));

const CustomContainer = styled(Container)(({ theme }) => ({
  width: '50%',
  flexDirection: 'column',
  alignContent: 'center',
  display: 'flex',
  marginLeft: '26%',
  [theme.breakpoints.down('sm')]: {
    width: '80%',
    marginLeft: '13%',
  },
  [theme.breakpoints.between('sm', 'md')]: {
    width: '70%',
    marginLeft: '18%',
  },
  [theme.breakpoints.up('lg')]: {
    width: '50%',
    marginLeft: '26%',
  },
}));

const CustomHeading = styled(Typography)(({ theme }) => ({
  marginTop: '5%',
  marginBottom: '4%',
  color: '#F27C22',
  fontFamily: 'Inter',
  fontSize: '27px',
  lineHeight: '30.26px',
  height: '33px',
  fontWeight: 'bold',
  [theme.breakpoints.down('sm')]: {
    marginTop: '0%',
    marginBottom: '130px',
    fontSize: '24px',
  },
  [theme.breakpoints.between('md', 'lg')]: {
    marginBottom: '20%',
  },
  [theme.breakpoints.up('lg')]: {
    marginBottom: '80px',
  },
}));

const Description = styled(Typography)(({ theme }) => ({
  color: '#797A79',
  fontSize: '15px',
  lineHeight: '22.23px',
  marginBottom: '8%',
  maxWidth: '90%',
  fontFamily: 'Oswald, sans-serif',
  [theme.breakpoints.between('sm', 'md')]: {
    width: '85%',
  },
  [theme.breakpoints.between('md', 'lg')]: {
    marginBottom: '10%',
  },
}));

const NextText = styled(Typography)(({ theme }) => ({
  color: '#000',
  fontFamily: 'Inter',
  fontSize: '20px',
  lineHeight: '24.2px',
  fontWeight: 'bold', // Make the text bold
  marginTop: '1.5rem',
  marginBottom: '0.5rem',
  [theme.breakpoints.down('xs')]: {
    marginTop: '1rem',
  },
  [theme.breakpoints.between('xs', 'sm')]: {
    marginTop: '2rem',
  },
  [theme.breakpoints.between('sm', 'md')]: {
    marginTop: '3rem',
  },
  [theme.breakpoints.up('md')]: {
    marginTop: '1.5rem',
  },
}));
