import { firebaseAuth } from '../firebase/FirebaseConfig';
import myApi from '../firebase/FirebaseApi';
import { base } from '../utils/BaseUrl';

export enum UserRole {
  CLIENT = 'client',
  TRAINER = 'trainer',
}

export interface UserRoles {
  isClient: boolean;
  isTrainer: false;
}

// Check single role based on route/context
export const checkSingleRole = async (uid: string, role: UserRole): Promise<boolean> => {
  try {
    const response = await (role === UserRole.CLIENT
      ? myApi.readSingleClient(base, uid)
      : myApi.readSingleTrainer(base, uid));

    return !(typeof response === 'string' || (response as any).error);
  } catch {
    return false;
  }
};

export const getUserHomeRoute = (currentPath?: string): string => {
  return currentPath?.includes('trainer') ? '/trainer-login' : '/client-login';
};

export const isPageAccessible = async (uid: string, pathname: string): Promise<boolean> => {
  const clientRoutes = [
    '/home-page',
    '/home-session',
    '/client-profile',
    '/profile-support',
    '/find-trainer',
    '/client-sessions',
    '/client-booked',
    '/client-info',
    '/client-wait',
    '/client-rating',
    '/trainer-tip',
    '/client-reserve',
    '/reserve-choice',
    '/review-timing',
    '/client-confirmation',
    '/search-trainer',
    '/choose-timing',
  ];

  const trainerRoutes = [
    '/trainer-home',
    '/trainer-accept',
    '/trainer-rating',
    '/trainer-profile',
    '/trainer-wait',
    '/trainer-sessions',
    '/trainer-payout',
    '/trainer-support',
    '/trainer-info',
    '/trainer-upcoming-sessions',
    '/trainer-reservation',
    '/trainer-reservation-details',
    '/trainer-confirmation',
    '/trainer-availability',
  ];

  const publicRoutes = [
    '/',
    '/client-login',
    '/trainer-login',
    '/client-signup',
    '/trainer-signup',
    '/forget-password',
    '/video-session',
    '/drawbar',
  ];

  if (publicRoutes.includes(pathname)) return true;

  // For protected routes, check the appropriate role
  if (clientRoutes.includes(pathname)) {
    return await checkSingleRole(uid, UserRole.CLIENT);
  }
  if (trainerRoutes.includes(pathname)) {
    return await checkSingleRole(uid, UserRole.TRAINER);
  }

  return false;
};
