import { Close as CloseIcon } from '@mui/icons-material';
import {
  Alert,
  Box,
  Collapse,
  Container,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  styled,
} from '@mui/material';
import { CSSProperties, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Appbar, CustomStepper, GradientButton } from '../../components';
import { TrainerInfo } from './TrainerInfo';

export const TrainerInfoPage = () => {
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [firstNameErr, setFirstNameErr] = useState(false);
  const [lastNameErr, setLastNameErr] = useState(false);
  const [phoneErr, setPhoneErr] = useState(false);
  const [genderErr, setGenderErr] = useState(false);
  const [monthErr, setMonthErr] = useState(false);
  const [dayErr, setDayErr] = useState(false);
  const [yearErr, setYearErr] = useState(false);
  const navigate = useNavigate();

  const phonePattern = /^[0-9]{10}$/;

  const [trainerInfo, setTrainerInfo] = useState<TrainerInfo>(() => {
    const savedInfo = localStorage.getItem('trainerInfo');
    return savedInfo
      ? JSON.parse(savedInfo)
      : {
          firstName: '',
          lastName: '',
          phone: '',
          gender: '',
          dateOfBirth: '',
          identificationOption: '',
          identityFrontImageURL: '',
          identityBackImageURL: '',
          identityFaceImageURL: '',
          consistency: '',
          before: '',
          textValue: '',
          certext: '',
          certificationImageURL: '',
          termsAccepted: false,
        };
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setTrainerInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleDateChange = (value: string, part: 'day' | 'month' | 'year') => {
    const [month, day, year] = trainerInfo.dateOfBirth ? trainerInfo.dateOfBirth.split('/') : ['', '', ''];

    const newDate = {
      month: part === 'month' ? value : month,
      day: part === 'day' ? value : day,
      year: part === 'year' ? value : year,
    };

    if (newDate.month === 'mm') newDate.month = '';
    if (newDate.day === 'dd') newDate.day = '';
    if (newDate.year === 'yyyy') newDate.year = '';

    setTrainerInfo((prevState) => ({
      ...prevState,
      dateOfBirth: `${newDate.month}/${newDate.day}/${newDate.year}`,
    }));
  };

  useEffect(() => {
    localStorage.setItem('trainerInfo', JSON.stringify(trainerInfo));
  }, [trainerInfo]);

  useEffect(() => {
    const storedInfo = localStorage.getItem('trainerInfo');
    if (storedInfo) {
      const parsedInfo = JSON.parse(storedInfo);
      setTrainerInfo(parsedInfo);
    }
  }, []);

  const handleContinue = () => {
    setFirstNameErr(!trainerInfo.firstName);
    setLastNameErr(!trainerInfo.lastName);
    setPhoneErr(!trainerInfo.phone || !phonePattern.test(trainerInfo.phone));
    setGenderErr(!trainerInfo.gender);

    const [month, day, year] = trainerInfo.dateOfBirth.split('/');
    setMonthErr(!month);
    setDayErr(!day);
    setYearErr(!year);

    const valid =
      trainerInfo.firstName &&
      trainerInfo.lastName &&
      trainerInfo.phone &&
      phonePattern.test(trainerInfo.phone) &&
      trainerInfo.gender &&
      month &&
      day &&
      year;

    if (valid) {
      navigate('/trainer-identity', {
        replace: true,
        state: { trainerInfo },
      });
    } else {
      setShowAlert(true);
      setAlertMessage('Please fill in all fields correctly.');
    }
  };

  useEffect(() => {
    if (
      trainerInfo.firstName &&
      trainerInfo.lastName &&
      trainerInfo.phone &&
      phonePattern.test(trainerInfo.phone) &&
      trainerInfo.gender &&
      trainerInfo.dateOfBirth.split('/').length === 3
    ) {
      setShowAlert(false);
    }
  }, [trainerInfo]);

  const days = Array.from({ length: 31 }, (_, i) => i + 1);
  const months = Array.from({ length: 12 }, (_, i) => i + 1);
  const years = Array.from({ length: 2024 - 1900 + 1 }, (_, i) => 2024 - i);

  return (
    <div>
      <Appbar showMenu={true} />

      <CustomDiv>
        <CustomStepper activeStep={0} />

        <CustomContainer>
          <CustomHeading sx={{ fontWeight: 'bold' }}>Some information about you...</CustomHeading>

          <CustomFormControl
            error={firstNameErr}
            label="What's your first name?"
            variant='outlined'
            value={trainerInfo.firstName}
            name='firstName'
            onChange={handleInputChange}
            InputProps={{ style: { borderRadius: '15px', height: '61px', border: '15px' } }}
            InputLabelProps={{ style: labelStyle }}
          />

          <CustomFormControl
            error={lastNameErr}
            label="What's your last name?"
            variant='outlined'
            value={trainerInfo.lastName}
            name='lastName'
            onChange={handleInputChange}
            InputProps={{ style: { borderRadius: '15px', height: '61px', border: '15px' } }}
            InputLabelProps={{ style: labelStyle }}
          />

          <CustomFormControl
            error={phoneErr}
            label="What's your phone number?"
            variant='outlined'
            value={trainerInfo.phone}
            name='phone'
            onChange={handleInputChange}
            InputProps={{ style: { borderRadius: '15px', height: '61px', border: '15px' } }}
            InputLabelProps={{ style: labelStyle }}
          />
          <Note variant='body2'>*For Non-US numbers, please include country code without any spaces.</Note>

          <StyledFormControl>
            <CustomLabel>How do you identify? </CustomLabel>
            <Select
              error={genderErr}
              value={trainerInfo.gender}
              name='gender'
              onChange={(e) => handleInputChange(e as React.ChangeEvent<HTMLInputElement>)}
              sx={{ borderRadius: '15px', height: '61px', color: 'black' }}
              label='How do you identify?'
            >
              <MenuItem value=''>
                <em></em>
              </MenuItem>
              <MenuItem value='Male'>Male</MenuItem>
              <MenuItem value='Female'>Female</MenuItem>
              <MenuItem value='Non-binary'>Non-binary</MenuItem>
              <MenuItem value='Other'>Other</MenuItem>
            </Select>
          </StyledFormControl>

          <DOBType>What is your date of birth?</DOBType>

          <DOBContainer>
            <FormControl sx={{ marginRight: 1, flex: 1 }}>
              <InputLabel id='month-label'>MM</InputLabel>
              <Select
                labelId='month-label'
                value={trainerInfo.dateOfBirth.split('/')[0] || 'mm'}
                sx={{ borderRadius: '15px', height: '61px' }}
                onChange={(e) => handleDateChange(e.target.value, 'month')}
                label='MM'
                error={monthErr}
              >
                <MenuItem value='mm'>MM</MenuItem>
                {months.map((month) => (
                  <MenuItem key={month} value={month}>
                    {month.toString().padStart(2, '0')}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl sx={{ marginRight: 1, flex: 1 }}>
              <InputLabel id='day-label'>DD</InputLabel>
              <Select
                labelId='day-label'
                value={trainerInfo.dateOfBirth.split('/')[1] || 'dd'}
                sx={{ borderRadius: '15px', height: '61px' }}
                onChange={(e) => handleDateChange(e.target.value, 'day')}
                label='DD'
                error={dayErr}
              >
                <MenuItem value='dd'>DD</MenuItem>
                {days.map((day) => (
                  <MenuItem key={day} value={day}>
                    {day.toString().padStart(2, '0')}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl sx={{ flex: 1 }}>
              <InputLabel id='year-label'>YYYY</InputLabel>
              <Select
                labelId='year-label'
                value={trainerInfo.dateOfBirth.split('/')[2] || 'yyyy'}
                sx={{ borderRadius: '15px', height: '61px' }}
                onChange={(e) => handleDateChange(e.target.value, 'year')}
                label='YYYY'
                error={yearErr}
              >
                <MenuItem value='yyyy'>YYYY</MenuItem>
                {years.map((year) => (
                  <MenuItem key={year} value={year}>
                    {year}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </DOBContainer>

          <CustomCollapse in={showAlert}>
            <Alert
              severity='error'
              action={
                <IconButton
                  aria-label='close'
                  color='inherit'
                  size='small'
                  onClick={() => {
                    setShowAlert(false);
                  }}
                >
                  <CloseIcon fontSize='inherit' />
                </IconButton>
              }
            >
              {alertMessage}
            </Alert>
          </CustomCollapse>
          <GradientButton showArrowIcon text='Continue' handleClick={handleContinue} styles={ContinueStyle} />
        </CustomContainer>
      </CustomDiv>
    </div>
  );
};

const CustomDiv = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignContent: 'center',
  width: '100%',
  paddingTop: '2%',
  [theme.breakpoints.down('sm')]: {
    paddingTop: '15px',
  },
  [theme.breakpoints.up('lg')]: {
    paddingTop: '20px',
  },
}));

const CustomContainer = styled(Container)(({ theme }) => ({
  width: '50%',
  flexDirection: 'column',
  alignContent: 'center',
  display: 'flex',
  marginLeft: '25%',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    marginLeft: '0%',
  },
  [theme.breakpoints.between('sm', 'md')]: {
    width: '70%',
    marginLeft: '15%',
  },
  [theme.breakpoints.up('lg')]: {
    width: '50%',
    marginLeft: '25%',
  },
}));

const CustomHeading = styled(Typography)(({ theme }) => ({
  marginTop: '5%',
  marginLeft: '6%',
  marginBottom: '5%',
  color: '#F27C22',
  fontFamily: 'Inter',
  fontSize: '27px',
  lineHeight: '30.26px',
  height: '33px',
  [theme.breakpoints.down('sm')]: {
    marginLeft: '5%',
    marginBottom: '15%',
    fontSize: '24px',
    lineHeight: '25px',
    height: '30px',
    width: '90%',
  },
  [theme.breakpoints.between('md', 'lg')]: {
    marginBottom: '15%',
  },
  [theme.breakpoints.up('lg')]: {
    marginBottom: '40px',
  },
}));

const CustomFormControl = styled(TextField)(({ theme }) => ({
  width: '90%',
  marginBottom: '5%',
  marginLeft: '5%',
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'black',
      borderWidth: '3px',
    },
    '&:hover fieldset': {
      borderColor: 'black',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'black',
    },
  },
  borderRadius: '15px',
  height: '61px',
}));

const Note = styled(Typography)(({ theme }) => ({
  width: '85%',
  marginTop: '-3%',
  marginBottom: '4%',
  marginLeft: '7%',
  color: '#F27C22',
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  width: '90%',
  marginBottom: '5%',
  marginLeft: '5%',
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'black',
      borderWidth: '3px',
    },
    '&:hover fieldset': {
      borderColor: 'black',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'black',
    },
  },
  borderRadius: '15px',
  height: '61px',
}));

const DOBType = styled(Typography)(({ theme }) => ({
  marginLeft: '7%',
  marginBottom: '5%',
  color: '#000',
}));

const DOBContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignContent: 'center',
  width: '90%',
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'black',
      borderWidth: '3px',
    },
    '&:hover fieldset': {
      borderColor: 'black',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'black',
    },
  },
  borderRadius: '15px',
  marginLeft: '5%',
}));

const CustomLabel = styled(InputLabel)(({ theme }) => ({
  color: '#000',
  '&.Mui-focused': {
    color: '#000',
  },
}));

const labelStyle: CSSProperties = {
  color: '#000',
};

const CustomCollapse = styled(Collapse)(({ theme }) => ({
  marginLeft: '5%',
  width: '90%',
  marginTop: '4%',
  marginBottom: '2%',
  [theme.breakpoints.down('sm')]: {
    marginBottom: '4%',
  },
}));

const ContinueStyle: CSSProperties = {
  marginLeft: '5%',
  maxWidth: '90%',
  marginBottom: '5%',
};
