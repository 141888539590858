import React, { useEffect, useState } from 'react';
import { Box, Typography, Avatar, Button, ListItemButton, ListItemText, styled, Divider } from '@mui/material';
import {
  SupportAgent as SupportIcon,
  LocalOffer as DiscountIcon,
  Info as InfoIcon,
  FitnessCenter as TrainerIcon,
  PermIdentity as YourInfoIcon,
  History as HistoryIcon,
  Diversity3 as FriendsIcon,
  Star as StarIcon,
  ArrowForwardIos as ArrowForwardIosIcon,
  AccessTime as PreBookedSessionIcon,
  Forum as ChatIcon,
  Search as SearchIcon,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { Appbar } from '../../components';
import { firebaseAuth } from '../../../backend/firebase';
import { useDispatch } from 'react-redux';
import { setUid } from '../../../backend/redux/store';
import firebaseApi from '../../../backend/firebase/FirebaseApi';
import { base } from '../../../backend/utils/BaseUrl';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { ref, getDownloadURL } from 'firebase/storage';
import { storage } from '../../../backend/firebase/FirebaseConfig';

export const ProfilePage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [generalError, setGeneralError] = useState<string>('');
  const [firstName, setFirstName] = useState('Sam');
  const [lastName, setLastName] = useState('Smith');
  const [rating, setRating] = useState(0);
  const [profileImage, setProfileImage] = useState<string>('');

  const handleExternalLink = (url: string) => {
    window.open(url, '_blank');
  };

  const handleSignOutButton = async () => {
    try {
      await signOut(firebaseAuth);
      dispatch(setUid(null));

      // Optionally close the window if sign-out is part of a popup
      if (window.opener) {
        window.close();
      }
    } catch (error) {
      setGeneralError('An unexpected error occurred. Please try again later.');
      console.log(error);
    }
    navigate('/');
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(firebaseAuth, (user) => {
      if (user) {
        fetchClientInfo(user);
      }
    });

    return () => unsubscribe();
  }, []);

  const fetchClientInfo = async (user: any) => {
    try {
      const uid = user.uid;
      const data: any = await firebaseApi.readSingleClient(base, uid);
      console.log(data);
      const profileImageRef = ref(storage, `clients/${uid}/profilePhoto`);

      let profileImageURL;
      try {
        profileImageURL = await getDownloadURL(profileImageRef);
      } catch (imageError) {
        console.warn('Profile photo not found, using default image:', imageError);
        profileImageURL = firstName.charAt(0).toUpperCase() + lastName.charAt(0).toUpperCase();
      }

      setProfileImage(profileImageURL);
      setFirstName(data.firstName || '');
      setLastName(data.lastName || '');
      setRating(data.rating || 0.0);
    } catch (error) {
      console.error('Error fetching client info:', error);
    }
  };

  return (
    <>
      <Appbar showMenu={true} showCloseIcon={true} />
      <ProfileContainer>
        <ProfileHeader>
          <ProfileInfo>
            <Typography
              variant='h4'
              component='h1'
              sx={{ textTransform: 'uppercase', fontWeight: 'bold', fontFamily: 'Oswald, sans-serif' }}
            >
              {firstName}
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '5px' }}>
              <StarIcon sx={{ color: 'black', marginRight: '5px' }} />
              <Typography variant='h6' component='h2'>
                {rating.toFixed(2)}
              </Typography>
            </Box>
          </ProfileInfo>
          <Avatar
            alt='Profile Picture'
            src={profileImage}
            sx={{ width: '112px', height: '100px', marginRight: -1.5 }}
          />
        </ProfileHeader>
        <ButtonRow>
          <CustomButton
            onClick={() => navigate('/client-info')}
            sx={{
              fontWeight: 'bold',
              lineHeight: '15px',
              height: '100px',
              gap: '10px',
            }}
          >
            <YourInfoIcon />
            Your Information
          </CustomButton>
          <CustomButton
            onClick={() => navigate('/client-booked')}
            sx={{
              fontWeight: 'bold',
              lineHeight: '15px',
              height: '100px',
              gap: '10px',
            }}
          >
            <PreBookedSessionIcon />
            Pre-Booked Sessions
          </CustomButton>
          <CustomButton
            onClick={() => navigate('/')}
            sx={{
              fontWeight: 'bold',
              lineHeight: '15px',
              height: '100px',
              gap: '10px',
            }}
          >
            <ChatIcon />
            Chat with your Trainers
          </CustomButton>
        </ButtonRow>

        <InviteButton
          onClick={() => handleExternalLink('https://gyfrapp.com/pages/affiliate-marketing')}
          sx={{ marginTop: '20px', width: '95%' }}
        >
          <Box>
            <Typography variant='subtitle1' sx={{ fontWeight: 'bold' }}>
              Become an affiliate and earn!
            </Typography>
            <Typography variant='caption'>
              Earn 10% commission on every session booked through your referral!
            </Typography>
          </Box>
          <FriendsIcon sx={{ marginLeft: '20px', fontSize: '60px' }} />
        </InviteButton>
      </ProfileContainer>
      {/* TODO: Add navigation hyperlink */}
      <ListItemButtonStyled onClick={() => navigate('/search-trainer')}>
        <IconBox sx={iconStyles}>
          <SearchIcon />
        </IconBox>
        <CustomListItemText primary='Find a Trainer' />
        <ArrowIconBox>
          <ArrowForwardIosIcon />
        </ArrowIconBox>
      </ListItemButtonStyled>
      <Divider />
      <ListItemButtonStyled onClick={() => navigate('/client-sessions')}>
        <IconBox sx={iconStyles}>
          <HistoryIcon />
        </IconBox>
        <CustomListItemText primary='Training History' />
        <ArrowIconBox>
          <ArrowForwardIosIcon />
        </ArrowIconBox>
      </ListItemButtonStyled>
      <Divider />
      <ListItemButtonStyled onClick={() => navigate('/profile-support')}>
        <IconBox sx={iconStyles}>
          <SupportIcon />
        </IconBox>
        <CustomListItemText primary='Gyfr Support' />
        <ArrowIconBox>
          <ArrowForwardIosIcon />
        </ArrowIconBox>
      </ListItemButtonStyled>
      <Divider />
      <ListItemButtonStyled onClick={() => handleExternalLink('https://gyfrapp.com/pages/gyfr-membership')}>
        <IconBox sx={iconStyles}>
          <DiscountIcon />
        </IconBox>
        <CustomListItemText primary='Gyfr Plus Membership' />
        <ArrowIconBox>
          <ArrowForwardIosIcon />
        </ArrowIconBox>
      </ListItemButtonStyled>
      <Divider />
      <ListItemButtonStyled onClick={() => handleExternalLink('https://gyfrapp.com/')}>
        <IconBox sx={iconStyles}>
          <InfoIcon />
        </IconBox>
        <CustomListItemText primary='Visit Our Website' />
        <ArrowIconBox>
          <ArrowForwardIosIcon />
        </ArrowIconBox>
      </ListItemButtonStyled>
      <ProfileContainer>
        <CustomButton variant='contained' onClick={handleSignOutButton} sx={{ width: '95%', textTransform: 'none' }}>
          Sign Out
        </CustomButton>
      </ProfileContainer>
    </>
  );
};

const ProfileContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: '20px',
  padding: '20px',
  width: '90%',
  margin: '0 auto',
}));

const ProfileHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  width: '85%',
  justifyContent: 'space-between',
  marginTop: '15px',
}));

const ProfileInfo = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
}));

const ButtonRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: '95%',
  marginTop: '20px',
  gap: '15px',
}));

const CustomButton = styled(Button)(({ theme }) => ({
  width: '48%',
  backgroundColor: '#D9D9D9',
  color: 'black',
  borderRadius: '15px',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: '#D0D0D0',
  },
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '10px',
}));

const InviteButton = styled(Button)(({ theme }) => ({
  width: '90%',
  backgroundColor: '#D9D9D9',
  color: 'black',
  borderRadius: '15px',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: '#D0D0D0',
  },
  display: 'flex',
  justifyContent: 'space-between',
  padding: '10px',
  textAlign: 'left',
}));

const CustomListItemText = styled(ListItemText)(({ theme }) => ({
  marginLeft: '10px',
  textAlign: 'left',
  fontWeight: 'bold',
  fontFamily: 'Roboto',
}));

const iconStyles = {
  display: 'flex',
  alignItems: 'center',
  color: 'black',
};

const IconBox = styled(Box)(({ theme }) => ({
  marginLeft: '7%',
}));

const ArrowIconBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  color: 'black',
  marginRight: '6%',
}));

const ListItemButtonStyled = styled(ListItemButton)(({ theme }) => ({
  padding: '15px 0',
}));
