import { useState } from 'react';
import {
  TextField,
  Typography,
  ThemeProvider,
  Alert,
  Collapse,
  IconButton,
  styled,
  Container,
  Card,
} from '@mui/material';
import { Close as CloseIcon, ArrowBack as ArrowBackIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { Appbar, GradientButton } from '../../components';
import { theme } from '../client-login/styles';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';

export const ForgetPasswordPage = () => {
  const [email, setEmail] = useState<string>('');
  const [emailError, setEmailError] = useState<boolean>(false);
  const [generalError, setGeneralError] = useState<string>('');
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const navigate = useNavigate();
  const auth = getAuth();

  const handleBackClick = () => {
    navigate(-1);
  };

  const handlePasswordReset = async () => {
    setEmailError(false);
    setGeneralError('');
    setSuccessMessage('');
    setShowAlert(false);

    if (email === '') {
      setEmailError(true);
      setGeneralError('Please enter your email address.');
      setShowAlert(true);
      return;
    }

    await sendPasswordResetEmail(auth, email)
      .then(() => {
        setSuccessMessage('Password reset email sent successfully. Please check your inbox.');
      })
      .catch((error) => {
        if (
          error.message == 'Firebase: Error (auth/user-not-found).' ||
          error.message == 'Firebase: Error (auth/invalid-email).'
        ) {
          setEmailError(true);
          setGeneralError('The email address is invalid.');
        } else {
          setEmailError(true);
          setGeneralError(`${error.message}`);
        }
      });
    setShowAlert(true);
  };

  return (
    <ThemeProvider theme={theme}>
      <div>
        <Appbar showMenu={true} />
        <BackButton onClick={handleBackClick}>
          <ArrowBackIcon sx={{ fontSize: '30px', fontWeight: 'bold', color: 'black' }} />
        </BackButton>
        <CustomContainer maxWidth='lg'>
          <CustomCardContainer>
            {/* Welcome Back Heading */}
            <CustomHeading sx={{ fontFamily: 'Oswald, sans-serif' }}>Forgot Password</CustomHeading>

            <Description
              sx={{
                color: '#797A79',
                fontSize: '15px',
                lineHeight: '22.23px',
                marginBottom: 4,
                maxWidth: '95%',
              }}
            >
              Enter your email address and we will send you a link to reset your password.
            </Description>

            {/* Email Input */}
            <TextField
              required
              error={emailError}
              id='outlined-basic'
              label='Email'
              variant='outlined'
              fullWidth
              sx={{
                marginBottom: 2,
                '& .MuiOutlinedInput-root': {
                  borderRadius: '15px',
                  height: '55px',
                  '& .MuiOutlinedInput-notchedOutline': { border: '3px solid #000' },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': { border: '3px solid #F27C22' },
                },
              }}
              InputLabelProps={{ style: { color: emailError ? theme.palette.error.main : '#000' } }}
              onChange={(e) => setEmail(e.target.value)}
            />

            {/* Alert message */}
            <Collapse in={showAlert}>
              <Alert
                severity={successMessage ? 'success' : 'error'}
                action={
                  <IconButton aria-label='close' color='inherit' size='small' onClick={() => setShowAlert(false)}>
                    <CloseIcon fontSize='inherit' />
                  </IconButton>
                }
                sx={{ marginTop: 2, marginBottom: 2 }}
              >
                {successMessage || generalError}
              </Alert>
            </Collapse>

            {/* Reset button */}
            <GradientButton text='Submit' handleClick={handlePasswordReset} styles={{ marginTop: 10 }} />
          </CustomCardContainer>
        </CustomContainer>
      </div>
    </ThemeProvider>
  );
};

const CustomCardContainer = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  border: 'none',
  boxShadow: 'none',
  [theme.breakpoints.down('sm')]: {
    width: '90%',
    margin: 'auto auto',
  },
  [theme.breakpoints.between('sm', 'md')]: {
    width: '80%',
    margin: 'auto auto',
    maxHeight: '90vh',
  },
  [theme.breakpoints.between('md', 'lg')]: {
    width: '70%',
    margin: 'auto auto',
    maxHeight: '85vh',
  },
  [theme.breakpoints.between('lg', 'xl')]: {
    width: '60%',
    margin: 'auto auto',
    maxHeight: '200vh',
  },
  [theme.breakpoints.up('xl')]: {
    width: '50%',
    margin: 'auto auto',
    maxHeight: '75vh',
  },
}));

const CustomContainer = styled(Container)(({ theme }) => ({
  marginTop: theme.spacing(0),
}));

const BackButton = styled(IconButton)(({ theme }) => ({
  marginTop: '2%',
  marginLeft: '4%',
  [theme.breakpoints.down('sm')]: {
    marginTop: '5%',
  },
  [theme.breakpoints.between('sm', 'md')]: {
    marginTop: '4%',
  },
  [theme.breakpoints.between('md', 'lg')]: {
    marginTop: '3%',
  },
}));

const CustomHeading = styled(Typography)(({ theme }) => ({
  marginBottom: '5%',
  color: '#F27C22',
  fontFamily: 'Inter',
  fontSize: '27px',
  lineHeight: '30.26px',
  height: '33px',
  [theme.breakpoints.down('sm')]: {
    fontSize: '24px',
    marginBottom: '3%',
  },
  [theme.breakpoints.between('md', 'lg')]: {
    marginBottom: '3%',
  },
}));

const Description = styled(Typography)(({ theme }) => ({
  color: '#797A79',
  fontSize: '15px',
  lineHeight: '22.23px',
  [theme.breakpoints.down('sm')]: {
    marginLeft: '1.5%',
    maxWidth: '90%',
  },
}));
