// CurrencyConverter.ts
import fx from 'money';

interface RateCache {
  rates: { [key: string]: number };
  base: string;
  timestamp: number;
}

interface LocationCache {
  currency: string;
  timestamp: number;
}

class CurrencyConverter {
  private static instance: CurrencyConverter;
  private isInitialized: boolean = false;

  private constructor() {
    // Initialize fx with USD as base currency and empty rates
    fx.base = 'USD';
    fx.rates = {
      USD: 1, // Default rate
    };
  }

  public static getInstance(): CurrencyConverter {
    if (!CurrencyConverter.instance) {
      CurrencyConverter.instance = new CurrencyConverter();
    }
    return CurrencyConverter.instance;
  }

  async initializeRates(): Promise<void> {
    if (this.isInitialized) return;

    const cachedRates = localStorage.getItem('exchangeRates');
    if (cachedRates) {
      const data = JSON.parse(cachedRates) as RateCache;
      // Cache for 2 hours
      if (Date.now() - data.timestamp < 2 * 60 * 60 * 1000) {
        fx.rates = data.rates;
        fx.base = data.base;
        this.isInitialized = true;
        return;
      }
    }

    try {
      const response = await fetch('https://open.exchangerate-api.com/v6/latest/USD'); //fetch exchange rates
      const data = await response.json();

      if (data && data.rates) {
        fx.base = 'USD';
        fx.rates = data.rates;

        const rateCache: RateCache = {
          rates: data.rates,
          base: 'USD',
          timestamp: Date.now(),
        };

        localStorage.setItem('exchangeRates', JSON.stringify(rateCache));
        this.isInitialized = true;
      }
    } catch (error) {
      console.error('Error fetching exchange rates:', error);
      if (cachedRates) {
        const data = JSON.parse(cachedRates) as RateCache;
        fx.rates = data.rates;
        fx.base = data.base;
        this.isInitialized = true;
      }
    }
  }

  async getUserCurrency(): Promise<string> {
    const cachedLocation = localStorage.getItem('userLocation');
    if (cachedLocation) {
      const data = JSON.parse(cachedLocation) as LocationCache;
      // Cache location for 12 hours
      if (Date.now() - data.timestamp < 12 * 60 * 60 * 1000) {
        // if (Date.now() - data.timestamp < 2 * 1000) {   //for testing
        return data.currency;
      }
    }

    try {
      const response = await fetch('https://ipapi.co/json/'); //get user location use ipapi
      const data = await response.json();
      const currency = data.currency || 'USD';

      localStorage.setItem(
        'userLocation',
        JSON.stringify({
          currency,
          timestamp: Date.now(),
        })
      );

      return currency;
    } catch (error) {
      console.error('Error fetching user currency:', error);
      return 'USD';
    }
  }

  convert(amount: number, fromCurrency: string, toCurrency: string): number {
    try {
      // If currencies are the same, return original amount
      if (fromCurrency === toCurrency) return amount;

      // If not initialized or missing rates, return original amount
      if (!this.isInitialized || !fx.rates || !fx.base) return amount;

      // Ensure currencies exist in rates
      if (!fx.rates[fromCurrency] || !fx.rates[toCurrency]) return amount;

      return Number(fx(amount).from(fromCurrency).to(toCurrency).toFixed(2));
    } catch (error) {
      console.error('Conversion error:', error);
      return amount; // Return original amount if conversion fails
    }
  }

  formatPrice(amount: number, currency: string): string {
    try {
      return new Intl.NumberFormat(undefined, {
        style: 'currency',
        currency: currency,
      }).format(amount);
    } catch (error) {
      console.error('Formatting error:', error);
      return `${currency} ${amount.toFixed(2)}`; // Fallback formatting
    }
  }
}

export default CurrencyConverter.getInstance();
