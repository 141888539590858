import React from 'react';
import { IconButton } from '@mui/material';
import { styled } from '@mui/system';
import { ArrowBack as ArrowBackIcon } from '@mui/icons-material';

const StyledButton = styled(IconButton)(({ theme }) => ({
  // Basic styling that's common across most uses
  '& .MuiSvgIcon-root': {
    fontSize: '30px',
    fontWeight: 'bold',
    color: 'black',
  },
}));

interface BackButtonProps {
  onClick: () => void;
  className?: string;
  style?: React.CSSProperties;
}

export const BackButton: React.FC<BackButtonProps> = ({ onClick, className, style }) => {
  return (
    <StyledButton onClick={onClick} className={className} style={style}>
      <ArrowBackIcon />
    </StyledButton>
  );
};
