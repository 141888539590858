import React, { useEffect, useState, useRef } from 'react';
import {
  styled,
  Box,
  Typography,
  Avatar,
  Button,
  Card,
  CardContent,
  Grid,
  useMediaQuery,
  useTheme,
  CircularProgress,
  IconButton,
  Alert,
  Collapse,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Appbar, BackButton } from '../../components';
import { ArrowForwardIos as ForwardIcon, Close as CloseIcon } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { RootState } from '../../../backend';
import { base } from '../../../backend/utils/BaseUrl';
import myApi from '../../../backend/firebase/FirebaseApi';
import moment from 'moment-timezone';

interface SessionData {
  sessionID: string;
  clientName: string;
  date: string;
  time: string;
  sessionType: string;
  avatarUrl: string;
  dateTime: any;
  clientID: string;
}

export const TrainerUpcomingSessionsPage: React.FC = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up(850));
  const [centerVertically, setCenterVertically] = useState(false);
  const cardRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const trainerID = useSelector((state: RootState) => state.user.uid);
  const [sessions, setSessions] = useState<SessionData[]>([]);
  const [loading, setLoading] = useState(true);
  const [showAlert, setShowAlert] = useState<string>('');

  const handleBackClick = () => {
    navigate(-1);
  };

  const handleCloseAlert = () => {
    setShowAlert('');
  };

  const handleJoinCall = (session: SessionData) => {
    const sessionTime = moment(session.dateTime, 'YYYY-MM-DD hh:mm A', 'America/New_York');
    console.log(sessionTime.format());
    const currentTime = moment().tz('America/New_York');
    console.log(currentTime);

    // Calculate time windows
    const earliestJoinTime = moment(sessionTime).subtract(5, 'minutes');
    const latestJoinTime = moment(sessionTime).add(10, 'minutes');

    if (currentTime.isBefore(earliestJoinTime)) {
      setShowAlert('It is too early to join this session. Please join within 5 minutes of the scheduled time.');
      return;
    }

    if (currentTime.isAfter(latestJoinTime)) {
      setShowAlert('This session has expired. You can only join within 10 minutes after the scheduled time.');
      return;
    }

    sessionStorage.setItem('Agent', 'trainer');
    sessionStorage.setItem('clientID', session.clientID);

    // Navigate to video call page
    navigate(`/video-session/${session.sessionID}`);
  };

  useEffect(() => {
    if (isDesktop && cardRef.current && containerRef.current) {
      const cardHeight = cardRef.current.offsetHeight;
      const containerHeight = containerRef.current.offsetHeight;
      const cardsPerPage = Math.floor(containerHeight / cardHeight);
      setCenterVertically(sessions.length <= cardsPerPage);
    }
  }, [isDesktop, sessions.length]);

  useEffect(() => {
    const fetchSessions = async () => {
      if (!trainerID) {
        setShowAlert('Trainer ID not found. Please try logging in again.');
        setLoading(false);
        return;
      }

      try {
        const response: any = await myApi.getUpcomingSessionsByTrainer(base, trainerID);
        if (Array.isArray(response)) {
          const formattedSessions = await Promise.all(
            response.map(async (session) => {
              const clientResponse: any = await myApi.readSingleClient(base, session.clientID);
              const clientInfo = typeof clientResponse === 'string' ? null : clientResponse;

              const sessionDateTime = session.dateTime?.toDate ? session.dateTime.toDate() : new Date(session.dateTime);

              return {
                sessionID: session.sessionID,
                clientName: clientInfo ? `${clientInfo.firstName} ${clientInfo.lastName?.charAt(0)}.` : 'Unknown',
                clientID: session.clientID,
                date: sessionDateTime.toLocaleDateString('en-US', {
                  month: '2-digit',
                  day: '2-digit',
                  year: 'numeric',
                }),
                time: moment(sessionDateTime, 'YYYY-MM-DD hh:mm A', 'America/New_York')
                  .local()
                  .tz(moment.tz.guess())
                  .format('hh:mm A z'),
                sessionType: session.sessionType,
                avatarUrl:
                  clientInfo?.profilePhoto == ''
                    ? clientInfo.firstName.charAt(0).toUpperCase() + clientInfo.lastName.charAt(0).toUpperCase()
                    : clientInfo?.profilePhoto || '',
                dateTime: sessionDateTime,
              };
            })
          );

          setSessions(formattedSessions);
        } else {
          throw new Error('Invalid response format');
        }
      } catch (error) {
        console.error('Error fetching sessions:', error);
        setShowAlert('Failed to fetch sessions. Please refresh the page or try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchSessions();
  }, [trainerID]);

  const handleTextClient = (session: SessionData) => {
    try {
      console.log(`Text ${session.clientName}`);
      // Add your text client logic here
    } catch (error) {
      setShowAlert('Failed to send message. Please try again.');
    }
  };

  const renderContent = () => {
    if (loading) {
      return (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
          <CircularProgress />
        </Box>
      );
    }

    if (sessions.length === 0) {
      return (
        <Typography
          sx={{ textAlign: 'center', mt: 4, color: '#666', fontFamily: 'Oswald, sans-serif', fontSize: '1.2rem' }}
        >
          No upcoming reserved sessions
        </Typography>
      );
    }

    return sessions.map((session, index) => (
      <Card
        key={`${session.sessionID}-${index}`}
        ref={cardRef}
        sx={{
          mb: 4,
          borderRadius: '15px',
          backgroundColor: '#DEDEDE',
          marginInline: '2.5%',
          '@media (min-width:1024px) and (orientation: landscape)': {
            marginInline: '15%',
            mb: 4,
            width: '70%',
          },
        }}
      >
        <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', pb: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <Avatar
              alt={`Session with ${session.clientName}`}
              src={session.avatarUrl}
              sx={{ mt: 1.5, ml: 3, width: 95, height: 90, mr: 6 }}
            >
              {session.avatarUrl}
            </Avatar>
            <Box>
              <Typography variant='body2' fontWeight='bold' fontSize={'13px'} mb={1} mt={1}>
                Session with {session.clientName}
              </Typography>
              <Typography variant='body2' fontWeight='bold' fontSize={'13px'} mb={1}>
                Date: {session.date}
              </Typography>
              <Typography variant='body2' fontWeight='bold' fontSize={'13px'} mb={1}>
                Time: {session.time}
              </Typography>
              <Typography variant='body2' fontSize={'13px'} fontWeight='bold'>
                Session Length: {session.sessionType}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ mt: 'auto', width: '100%' }}>
            <Button
              variant='contained'
              endIcon={<ForwardIcon />}
              sx={{
                width: '100%',
                borderRadius: '12px',
                justifyContent: 'space-between',
                textAlign: 'left',
                marginBottom: '14px',
                textTransform: 'none',
                backgroundColor: 'black',
                height: '40px',
                '&:hover': {
                  backgroundColor: 'gray',
                },
              }}
              onClick={() => handleTextClient(session)}
            >
              Text {session.clientName}
            </Button>
            <Button
              variant='contained'
              endIcon={<ForwardIcon />}
              sx={{
                width: '100%',
                borderRadius: '12px',
                justifyContent: 'space-between',
                textAlign: 'left',
                textTransform: 'none',
                backgroundColor: '#F27C22',
                height: '40px',
                '&:hover': {
                  backgroundColor: 'orange',
                },
              }}
              onClick={() => handleJoinCall(session)}
            >
              Join Call
            </Button>
          </Box>
        </CardContent>
      </Card>
    ));
  };

  return (
    <>
      <Appbar showMenu={true} showCloseIcon={true} />
      <StyledBackButton onClick={handleBackClick} />
      {isDesktop ? (
        <Grid container sx={{ height: 'calc(100vh - 68px)', mt: '60px' }}>
          <Grid item md={5} sx={{ height: '100%', overflow: 'hidden' }}>
            <Box
              sx={{
                '@media (min-width:1024px) and (orientation: landscape)': {
                  marginTop: '20vh',
                  paddingLeft: '15%',
                  height: '100%',
                },
              }}
            >
              <Title sx={{ fontWeight: '550' }}>Upcoming Pre-Booked Sessions</Title>
              <Description>
                Ensure you join the call on time. You will only be paid after successfully completing each session. If
                you are unable to attend a session, please contact Gyfr Support immediately. Multiple missed sessions
                may result in permanent deactivation of your trainer account. If a client does not arrive within the
                first 15 minutes of the scheduled session, please contact Gyfr Support immediately via live chat.
              </Description>
            </Box>
          </Grid>
          <Grid item xs={12} md={7} sx={{ height: '100%', overflow: 'auto' }}>
            <Box
              ref={containerRef}
              sx={{
                marginTop: '5%',
                height: '90%',
                overflowY: 'auto',
                '&::-webkit-scrollbar': {
                  width: '8px',
                },
                '&::-webkit-scrollbar-track': {
                  background: '#f1f1f1',
                },
                '&::-webkit-scrollbar-thumb': {
                  background: '#888',
                  borderRadius: '4px',
                },
                '&::-webkit-scrollbar-thumb:hover': {
                  background: '#555',
                },
              }}
            >
              <Collapse in={!!showAlert}>
                <Alert
                  severity='error'
                  action={
                    <IconButton aria-label='close' color='inherit' size='small' onClick={handleCloseAlert}>
                      <CloseIcon fontSize='inherit' />
                    </IconButton>
                  }
                  sx={{
                    mb: 2,
                    mx: 2,
                    '@media (min-width:1024px) and (orientation: landscape)': {
                      mx: '15%',
                    },
                  }}
                >
                  {showAlert}
                </Alert>
              </Collapse>
              {renderContent()}
            </Box>
          </Grid>
        </Grid>
      ) : (
        <Box sx={{ position: 'relative', paddingTop: { xs: 0, sm: '60px' } }}>
          <Title>Upcoming Pre-Booked Sessions</Title>
          <Description>
            Ensure you join the call on time. You will only be paid after successfully completing each session. If you
            are unable to attend a session, please contact Gyfr Support immediately. Multiple missed sessions may result
            in permanent deactivation of your trainer account.
          </Description>
          <Collapse in={!!showAlert}>
            <Alert
              severity='error'
              action={
                <IconButton aria-label='close' color='inherit' size='small' onClick={handleCloseAlert}>
                  <CloseIcon fontSize='inherit' />
                </IconButton>
              }
              sx={{
                mb: 2,
                mx: 2,
                '@media (min-width:1024px) and (orientation: landscape)': {
                  mx: '15%',
                },
              }}
            >
              {showAlert}
            </Alert>
          </Collapse>
          {renderContent()}
        </Box>
      )}
    </>
  );
};

const Title = styled(Typography)(({ theme }) => ({
  fontSize: '22px',
  fontWeight: 'bold',
  textAlign: 'center',
  color: '#000000',
  fontFamily: 'Oswald, sans-serif',
  marginBottom: '20px',
  [theme.breakpoints.up('md')]: {
    fontSize: '3.6rem',
    marginTop: '17%',
  },
}));

const Description = styled(Typography)(({ theme }) => ({
  color: '#797A79',
  fontSize: '13px',
  lineHeight: '18px',
  textAlign: 'center',
  maxWidth: '90%',
  margin: '0 auto 30px',
  padding: '0 15px',
  fontFamily: 'Oswald, sans-serif',
  [theme.breakpoints.up('md')]: {
    fontSize: '1rem',
    lineHeight: '22px',
  },
}));

const StyledBackButton = styled(BackButton)(({ theme }) => ({
  position: 'absolute',
  top: 80,
  left: 5,
  zIndex: 1000,
  [theme.breakpoints.down('md')]: {
    position: 'absolute',
    marginTop: '4%',
    top: 50,
    left: 5,
    zIndex: 1000,
  },
  [theme.breakpoints.down('sm')]: {
    position: 'static',
    marginTop: '4%',
  },
}));
