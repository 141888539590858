import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../backend/redux/store';
import { setUserCurrency, setRatesLoaded } from '../../backend/redux/currencySlice';
import CurrencyConverter from '../../backend/utils/CurrencyConverter';

export const usePriceFormatter = () => {
  const dispatch = useDispatch();
  const userCurrency = useSelector((state: RootState) => state.currency.userCurrency);
  const isRatesLoaded = useSelector((state: RootState) => state.currency.isRatesLoaded);

  useEffect(() => {
    const initializeCurrency = async () => {
      // Only initialize if not already loaded
      if (!isRatesLoaded) {
        try {
          await CurrencyConverter.initializeRates();
          dispatch(setRatesLoaded(true));
        } catch (error) {
          console.error('Error initializing rates:', error);
        }
      }

      // Check if we need to set user currency
      if (userCurrency === 'USD') {
        // Default value, means not yet set
        try {
          const currency = await CurrencyConverter.getUserCurrency();
          dispatch(setUserCurrency(currency));
        } catch (error) {
          console.error('Error getting user currency:', error);
        }
      }
    };

    initializeCurrency();
  }, []); // Empty dependency array as we only want to run this once

  const formatPrice = (price: number, fromCurrency: string = 'USD'): string => {
    try {
      const convertedPrice = isRatesLoaded ? CurrencyConverter.convert(price, fromCurrency, userCurrency) : price;
      return CurrencyConverter.formatPrice(convertedPrice, isRatesLoaded ? userCurrency : fromCurrency);
    } catch (error) {
      console.error('Price formatting error:', error);
      return `$${price.toFixed(2)}`;
    }
  };

  return { formatPrice, userCurrency, isRatesLoaded };
};

export const formatPriceWithCurrency = (
  price: number,
  userCurrency: string,
  isRatesLoaded: boolean,
  fromCurrency: string = 'USD'
): string => {
  try {
    const convertedPrice = isRatesLoaded ? CurrencyConverter.convert(price, fromCurrency, userCurrency) : price;
    return CurrencyConverter.formatPrice(convertedPrice, isRatesLoaded ? userCurrency : fromCurrency);
  } catch (error) {
    console.error('Price formatting error:', error);
    return `$${price.toFixed(2)}`;
  }
};
