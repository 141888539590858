import moment from 'moment-timezone';
import { TimeRange } from '../models/Data';
import myApi from '../firebase/FirebaseApi';
import { base } from '../utils/BaseUrl';

interface FirebaseTimestamp {
  toDate(): Date;
}

interface UpcomingSession {
  dateTime: string | FirebaseTimestamp;
  sessionType: string;
  displayTime?: string;
  displayDate?: string;
}

export class TimeConflictUtils {
  // Convert time string (HH:mm A) to minutes since midnight
  private static timeToMinutes(time: string): number {
    return moment(time, 'hh:mm A').diff(moment().startOf('day'), 'minutes');
  }

  // Check if two time ranges overlap
  private static doRangesOverlap(
    range1Start: number,
    range1End: number,
    range2Start: number,
    range2End: number
  ): boolean {
    return range1Start < range2End && range2Start < range1End;
  }

  // Helper to convert Firebase timestamp or string to ISO string
  private static normalizeDateTime(dateTime: string | FirebaseTimestamp): string {
    if (typeof dateTime === 'object' && 'toDate' in dateTime) {
      return dateTime.toDate().toISOString();
    }
    return dateTime;
  }

  // Fetch upcoming sessions for a trainer
  private static async getUpcomingSessions(trainerID: string): Promise<UpcomingSession[]> {
    try {
      const response = await myApi.getUpcomingSessionsByTrainer(base, trainerID);
      if (!Array.isArray(response)) return [];

      return (response as UpcomingSession[])
        .filter((session) => session && session.dateTime && session.sessionType) // Filter out invalid sessions
        .map((session) => ({
          dateTime: session.dateTime,
          sessionType: session.sessionType || '',
          displayTime: session.displayTime,
          displayDate: session.displayDate,
        }));
    } catch (error) {
      console.error('Error fetching upcoming sessions:', error);
      return [];
    }
  }

  // Convert session time to local timezone
  private static convertToLocalTime(dateTime: string, timezone: string): moment.Moment {
    return moment.utc(dateTime).tz(timezone);
  }

  // Check if a proposed time slot conflicts with existing sessions
  public static async hasTimeConflict(
    trainerID: string,
    proposedDate: string,
    proposedTimeRange: TimeRange,
    sessionDuration: number,
    timezone: string
  ): Promise<boolean> {
    const upcomingSessions = await this.getUpcomingSessions(trainerID);
    const proposedStartMinutes = this.timeToMinutes(proposedTimeRange.startTime);
    const proposedEndMinutes = proposedStartMinutes + sessionDuration;
    const proposedMoment = moment(proposedDate);

    for (const session of upcomingSessions) {
      const normalizedDateTime = this.normalizeDateTime(session.dateTime);
      const sessionMoment = this.convertToLocalTime(normalizedDateTime, timezone);

      // Only check sessions on the same day
      if (!sessionMoment.isSame(proposedMoment, 'day')) continue;

      const sessionStartMinutes = sessionMoment.minutes() + sessionMoment.hours() * 60;
      const sessionDurationMinutes = parseInt(session.sessionType?.split(' ')[0] || '0');
      const sessionEndMinutes = sessionStartMinutes + sessionDurationMinutes;

      if (this.doRangesOverlap(proposedStartMinutes, proposedEndMinutes, sessionStartMinutes, sessionEndMinutes)) {
        return true;
      }
    }

    return false;
  }

  // Validate multiple time slots
  public static async validateTimeSlots(
    trainerID: string,
    sessions: Array<{ date: string; timeRange: TimeRange; sessionType: string }>,
    timezone: string
  ): Promise<{ valid: boolean; conflictingSession?: { date: string; timeRange: TimeRange } }> {
    for (const session of sessions) {
      const duration = parseInt(session.sessionType.split(' ')[0]);
      const hasConflict = await this.hasTimeConflict(trainerID, session.date, session.timeRange, duration, timezone);

      if (hasConflict) {
        return {
          valid: false,
          conflictingSession: {
            date: session.date,
            timeRange: session.timeRange,
          },
        };
      }
    }

    return { valid: true };
  }

  // Validate a session request before accepting
  public static async validateSessionRequest(
    trainerID: string,
    requestedSessions: Array<{ date: string; timeRange: TimeRange; sessionType: string }>,
    timezone: string
  ): Promise<{ valid: boolean; error?: string }> {
    try {
      const validationResult = await this.validateTimeSlots(trainerID, requestedSessions, timezone);

      if (!validationResult.valid && validationResult.conflictingSession) {
        const { date, timeRange } = validationResult.conflictingSession;
        return {
          valid: false,
          error: `Time conflict found for ${date} at ${timeRange.startTime}. This slot is no longer available.`,
        };
      }

      return { valid: true };
    } catch (error) {
      return {
        valid: false,
        error: 'An error occurred while validating the session request.',
      };
    }
  }

  // Get blocked time slots for a specific date
  public static async getBlockedTimeSlots(trainerID: string, date: string, timezone: string): Promise<TimeRange[]> {
    const upcomingSessions = await this.getUpcomingSessions(trainerID);
    const blockedSlots: TimeRange[] = [];
    const targetDate = moment(date);

    upcomingSessions.forEach((session) => {
      const normalizedDateTime = this.normalizeDateTime(session.dateTime);
      const sessionMoment = this.convertToLocalTime(normalizedDateTime, timezone);

      if (sessionMoment.isSame(targetDate, 'day')) {
        const startTime = sessionMoment.format('hh:mm A');
        const endTime = sessionMoment
          .clone() // Clone to prevent modifying the original moment object
          .add(parseInt(session.sessionType?.split(' ')[0] || '0'), 'minutes')
          .format('hh:mm A');

        blockedSlots.push({
          startTime,
          endTime,
        });
      }
    });

    return blockedSlots;
  }
}
