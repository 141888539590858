import React, { useEffect, useRef, useState } from 'react';
import { Box, Typography, CircularProgress, styled, IconButton, Avatar, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Appbar, BackButton } from '../../components';
import { ArrowBack as ArrowBackIcon, ArrowForwardIos } from '@mui/icons-material';
import firebaseApi from '../../../backend/firebase/FirebaseApi';
import { useSelector } from 'react-redux';
import { RootState } from '../../../backend/redux/store';
import { base } from '../../../backend/utils/BaseUrl';
import { usePriceFormatter } from '../../hooks/usePriceFormatter';
import { getDownloadURL, ref } from 'firebase/storage';
import { storage } from '../../../backend/firebase/FirebaseConfig';

export const ClientProfileSessions: React.FC = () => {
  const navigate = useNavigate();
  const [sessions, setSessions] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const missingTrainerIdsRef = useRef<Set<string>>(new Set());

  const handleBackClick = () => {
    navigate(-1);
  };

  const { formatPrice } = usePriceFormatter();

  const uid = useSelector((state: RootState) => state.user.uid);

  const costMapping: { [key: number]: number } = {
    3.49: 6.99,
    3.99: 6.99,
    6.49: 11.99,
    6.99: 11.99,
    9.49: 15.99,
    9.99: 15.99,
    11.49: 19.99,
    12.99: 19.99,
  };

  const getMappedCost = (input: number): number => {
    return costMapping[input] || input;
  };

  useEffect(() => {
    const fetchSessions = async () => {
      if (!uid) {
        setError('User ID not found');
        setLoading(false);
        return;
      }

      try {
        const response = await firebaseApi.getSessionsByClient(base, uid);
        const sessionsData = response as any[];

        if (!sessionsData || sessionsData.length === 0) {
          setError('You do not have any previous sessions.');
          setLoading(false);
          return;
        }

        const parseDate = (dateString: string): Date => {
          return new Date(dateString);
        };

        const formattedSessions = await Promise.all(
          sessionsData.map(async (session) => {
            let formattedDate;
            if (typeof session.dateTime === 'string') {
              formattedDate = parseDate(session.dateTime).toLocaleDateString('en-US', {
                month: '2-digit',
                day: '2-digit',
                year: 'numeric',
              });
            } else {
              formattedDate = 'Invalid date';
            }

            let trainerName;
            let trainerPhoto = '/default-avatar.png';
            if (session.trainerId && !missingTrainerIdsRef.current.has(session.trainerId)) {
              try {
                const trainerDetails = (await firebaseApi.readSingleTrainer(base, session.trainerId)) as any;
                if (trainerDetails) {
                  const firstName = trainerDetails.firstName || '';
                  const lastNameInitial = trainerDetails.lastName
                    ? trainerDetails.lastName.charAt(0).toUpperCase() + '.'
                    : '';
                  trainerName = `${firstName} ${lastNameInitial}`.trim();

                  const profileImageRef = ref(storage, `trainers/${session.trainerId}/identificationImage/face`);
                  try {
                    trainerPhoto = await getDownloadURL(profileImageRef);
                  } catch (error: any) {
                    if (error.code !== 'storage/object-not-found') {
                      console.error('Error fetching trainer photo:', error);
                    }
                  }
                }
              } catch (error: any) {
                if (error.response?.status === 404) {
                  missingTrainerIdsRef.current.add(session.trainerId);
                } else {
                  console.error('Error fetching trainer details:', error);
                }
                // trainerName remains 'Unknown Trainer'
              }
            }

            return {
              ...session,
              date: new Date(session.dateTime),
              dateTime: formattedDate,
              trainerName: trainerName,
              trainerPhoto: trainerPhoto,
              price: session.cost,
            };
          })
        );

        const sortedSessions = formattedSessions.sort((a, b) => b.date.getTime() - a.date.getTime());
        setSessions(sortedSessions);
      } catch (err) {
        console.error('Error fetching sessions:', err);
        setError('You do not have any previous sessions.');
      } finally {
        setLoading(false);
      }
    };

    fetchSessions();
  }, [uid]);

  if (loading) {
    return (
      <ProfileContainer>
        <CircularProgress />
      </ProfileContainer>
    );
  }

  return (
    <>
      <Appbar showMenu={true} showCloseIcon={true} />
      <StyledBackButton onClick={handleBackClick} />
      <ProfileContainer>
        <Heading sx={{ fontFamily: 'Oswald, sans-serif' }} variant='h4'>
          Previous Sessions
        </Heading>
        {error ? (
          <Typography color='error'>{error}</Typography>
        ) : (
          sessions.length > 0 &&
          sessions.map((session) => (
            <SessionCard
              key={session.sessionID}
              sx={{
                borderRadius: '15px',
                flexDirection: 'column',
                width: '95vw',
                padding: 0,
              }}
            >
              <Box
                sx={{
                  paddingTop: 2,
                  display: 'flex',
                  gap: '5px',
                  marginBottom: 2,
                  marginLeft: 4,
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <Avatar
                  alt={`Session with ${session.trainerName}`}
                  src={session.trainerPhoto}
                  sx={{ width: 95, height: 90, marginRight: 2 }}
                />
                <Box sx={{ textAlign: 'left', width: '100%' }}>
                  <Typography variant='body2' fontWeight='bold' noWrap>
                    Session with {session.trainerName || 'Unknown Trainer'}
                  </Typography>
                  <Typography
                    variant='body2'
                    fontWeight='bold'
                    sx={{
                      // whiteSpace: 'nowrap',
                      wordWrap: 'break-word',
                      overflowWrap: 'break-word',
                      maxWidth: 'calc(100% - 30px)',
                    }}
                  >
                    Session ID: {session.sessionID}
                  </Typography>
                  <Typography fontSize={'15px'} fontWeight='bold'>
                    Date: {session.dateTime}
                  </Typography>
                  <Typography fontSize={'15px'} fontWeight='bold'>
                    Price: {formatPrice(getMappedCost(session.price))}
                  </Typography>
                </Box>
              </Box>
              <Button
                variant='contained'
                endIcon={<ArrowForwardIos />}
                sx={{
                  textAlign: 'left',
                  justifyContent: 'space-between',
                  width: '100%',
                  borderRadius: '15px',
                  backgroundColor: '#F27C22',
                  '&:hover': {
                    backgroundColor: '#FF7000',
                  },
                }}
                onClick={() => navigate('/')}
              >
                Schedule Another Session
              </Button>
            </SessionCard>
          ))
        )}
      </ProfileContainer>
    </>
  );
};

const ProfileContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '20px',
  paddingTop: '0px',
  width: '90%',
  margin: '0 auto',
}));

const Heading = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  fontSize: '1.5rem',
  marginBottom: '30px',
  textAlign: 'center',
  [theme.breakpoints.down('sm')]: {
    marginLeft: '3.5%',
  },
  [theme.breakpoints.between('sm', 'md')]: {
    marginLeft: '4.5%',
  },
  [theme.breakpoints.up('md')]: {
    marginLeft: '2%',
  },
}));

const SessionCard = styled(Box)(({ theme }) => ({
  background: '#DEDEDE',
  borderRadius: '15px',
  padding: '10px',
  marginBottom: '1.5rem',
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
}));

const StyledBackButton = styled(BackButton)(({ theme }) => ({
  marginTop: '2%',
  marginLeft: '4%',
  [theme.breakpoints.down('sm')]: {
    marginTop: '5%',
  },
  [theme.breakpoints.between('sm', 'md')]: {
    marginTop: '4%',
  },
  [theme.breakpoints.between('md', 'lg')]: {
    marginTop: '3%',
  },
}));

const SessionPrice = styled(Typography)(({ theme }) => ({
  fontSize: '1.2rem',
}));
